import API_URLS from 'apiUrls';
import axios from 'axios';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux'
import { GET_USER_INFO } from 'state/slices/user-auth-info';
import { addStadium } from './functions';

const useAddGroundPopUpForm = (handleRefresh, handleClose) => {
  const { TOKEN } = useSelector(GET_USER_INFO)
  const [region, setRegion] = useState('');
  const handleRegionChange = (e) => setRegion(e.target.value);

  const [stadiumName, setStadiumName] = useState('');
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedTeams, setSelectedTeams] = useState([]);

  const apiCall = async () => {
    setLoading(true);
    try {
      const response = await axios.get(API_URLS.teams);
      setTeams((prev) => {
        prev = [...response.data]
          .map((team) => ({
            label: team.name,
            value: team._id,
          }))
          .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
        return prev;
      });
      setLoading(false);
    } catch (error) {
      console.log('error getting teams', error);
      setLoading(false);
      toast.error('could not get the teams');
    }
  };

  useEffect(() => {
    apiCall();
  }, []);

  const [addStadiumLoading, setAddStadiumLoading] = useState(false);

  const handleConfirmAddStadium = async () => {
    if (stadiumName === '') {
      toast.error('stadium name is required');
      return;
    }

    if (region === '') {
      toast.error('region name is required');
      return;
    }

    setAddStadiumLoading(true);
    try {
      await addStadium({
        token: TOKEN,
        body: {
          name: stadiumName,
          region: region,
          home_teams: selectedTeams,
        },
      });
      setAddStadiumLoading(false);
      handleClose();
      handleRefresh();
    } catch (error) {
      console.log(error);
      const {
        response: {
          data: { message },
        },
      } = error;
      if (message.includes('Stadium name avalibale')) toast.error('Stadium name already used, pick another one');
      console.log(message);
      setAddStadiumLoading(false);
    }
  };

  return {
    stadiumName,
    setStadiumName,
    region,
    handleRegionChange,
    teams,
    loading,
    selectedTeams,
    setSelectedTeams,
    handleConfirmAddStadium,
    addStadiumLoading,
  };
};

export default useAddGroundPopUpForm;
