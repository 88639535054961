import { Box, Tab, Tabs } from '@mui/material';

const CustomMUITabs = ({ value, handleChange, list }) => {
  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
        variant="scrollable"
        scrollButtons="auto"
      >
        {list.map((tab, index) => (
          <Tab key={index} value={tab.value} label={tab.label} />
        ))}
      </Tabs>
    </Box>
  );
};

export default CustomMUITabs;
