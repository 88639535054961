import TextareaAutosize from '@mui/material/TextareaAutosize';
import API_URLS from 'apiUrls';
import axios from 'axios';
import CustomButtonWithIcon from 'components/CustomButtonWithIcon';
import CustomCircularLoader from 'components/CustomCircularLoader';
import ModalPopUp from 'components/ModalPopUp';
import { CustomNewsInput } from 'features/news-add/AddNews';
import { CLEAR_DATA, GET_NEWS_HEAD_INFO } from 'pages/News/newsSlice';
import { GET_USER_INFO } from './../../state/slices/user-auth-info';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { AiFillEdit, AiOutlineLeft, AiOutlineUpload } from 'react-icons/ai';
import { BsChevronBarDown, BsChevronBarUp, BsCollectionPlayFill, BsImage, BsPenFill } from 'react-icons/bs';
import { FaBold, FaRegEdit, FaTrash } from 'react-icons/fa';
import { GrUpdate } from 'react-icons/gr';
import { MdAddTask, MdOutlineClear, MdTextFields } from 'react-icons/md';
import { TbPencilOff } from 'react-icons/tb';
import ReactPlayer from 'react-player/lazy';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import routeStrings from 'routeStrings';
import UpdateHeaderInfoPopUp from './components/UpdateHeaderInfoPopUp';
import ChooseImage from './components/ChooseImage';
import { updateArticle } from './functions';
import { NEW_BASE_URL } from 'apiUrls';
import moment from 'moment/moment';

function NewsContentCreator() {
  const divRef = useRef(null);

  const { newsId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const newsHeadInfo = useSelector(GET_NEWS_HEAD_INFO);
  const userInfo = useSelector(GET_USER_INFO);
  const [head, setHead] = useState('');
  const [imageSource, setImageSource] = useState('');
  const [source, setSource] = useState('');

  useEffect(() => {
    return () => {
      dispatch(CLEAR_DATA);
    };
  });

  // modal pop up for image selection
  const [open2, setOpen2] = useState(false);
  const [selectedImage, setselectedImage] = useState();
  const handleOpenPopUp2 = () => setOpen2(true);
  const handleClosePopUp2 = () => setOpen2(false);

  // modal pop up for the header info update
  const [open, setOpen] = useState(false);
  const handleOpenPopUp = () => setOpen(true);
  const handleClosePopUp = () => setOpen(false);

  const [data, setData] = useState();

  const [other, setOther] = useState();

  // article contents
  const [contents, setContents] = useState([]);
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState();
  const [selectedContent, setSelectedContent] = useState(undefined);

  /**
   * TODO: if this component is rendered with edit in mind TODO: if this component is rendered with edit in mind
   */
  const apiCall = async () => {
    try {
      const response = await axios.get(`${API_URLS.news}/${newsId}`);
      setContents((prev) => {
        prev = [];
        return prev;
      });
      setDescription(response.data.description);
      setCategory(response.data.category);
      setData(response.data);
      setHead(response.data.title ?? '');
      setImageSource(`${NEW_BASE_URL}/${response.data.image_url}`);
      setSource((prev) => {
        prev = response.data.source ?? {};
        return prev;
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (newsId) {
      apiCall();
    } else {
      setHead(newsHeadInfo.head);

      setCategory(newsHeadInfo.category);

      setData(newsHeadInfo);
    }
  }, []);

  //  paragraph
  const [paragraphText, setParagraphText] = useState('');
  const [paragraphUpdateText, setParagraphUpdateText] = useState('');
  const [addText, setAddText] = useState(false);
  const [modifyText, setModifyText] = useState(false);

  // image url
  const [imageUrl, setImageUrl] = useState('');
  const [addImageUrl, setAddImageUrl] = useState(false);
  const [imageUrlUpdate, setImageUrlUpdate] = useState('');
  const [modifyImageUrl, setModifyImageUrl] = useState(false);

  // video url
  const [videoUrl, setVideoUrl] = useState('');
  const [addVideoUrl, setAddVideoUrl] = useState(false);
  const [videoUrlUpdate, setVideoUrlUpdate] = useState('');
  const [modifyVideoUrl, setModifyVideoUrl] = useState(false);

  const toggleAddText = () => {
    setAddText(!addText);
    // also set false all other control buttons setters
    setAddImageUrl(false);
    setAddVideoUrl(false);
  };

  const toggleAddImageUrl = () => {
    setAddImageUrl(!addImageUrl);
    // also set false all other control buttons setters
    setAddText(false);
    setAddVideoUrl(false);
  };

  const toggleAddVideoUrl = () => {
    setAddVideoUrl(!addVideoUrl);
    // also set false all other control buttons setters
    setAddText(false);
    setAddImageUrl(false);
  };

  const handleAddParagraph = () => {
    if (paragraphText.trim() === '') {
      toast.error('Make sure the field is not empty');
      setParagraphText('');
      return;
    }
    setContents((prev) => {
      prev = [...prev, { contentType: 'paragraph', value: paragraphText, bold: false, title: false }];
      return prev;
    });
    setParagraphText('');
  };

  const handleAddImagery = () => {
    if (imageUrl.trim() === '') {
      toast.error('Make sure the field is not empty');
      setImageUrl('');
      return;
    }
    setContents((prev) => {
      prev = [...prev, { contentType: 'imagery', value: imageUrl }];
      return prev;
    });
    setImageUrl('');
  };

  const handleAddVideo = () => {
    if (videoUrl.trim() === '') {
      toast.error('Make sure the field is not empty');
      setVideoUrl('');
      return;
    }
    setContents((prev) => {
      prev = [...prev, { contentType: 'video', value: videoUrl }];
      return prev;
    });
    setVideoUrl('');
  };

  const handleUpdateParagraph = () => {
    if (selectedContent >= 0) {
      const newContent = paragraphUpdateText;
      setContents((prev) => {
        prev = [...prev].map((content, index) => {
          if (index === selectedContent) return { ...content, value: newContent };
          return content;
        });
        return prev;
      });
    }
  };

  const handleUpdateImagery = () => {
    if (selectedContent >= 0) {
      const newContent = imageUrlUpdate;
      setContents((prev) => {
        prev = [...prev].map((content, index) => {
          if (index === selectedContent) return { ...content, value: newContent };
          return content;
        });
        return prev;
      });
    }
  };

  const handleUpdateVideo = () => {
    if (selectedContent >= 0) {
      const newContent = videoUrlUpdate;
      setContents((prev) => {
        prev = [...prev].map((content, index) => {
          if (index === selectedContent) return { ...content, value: newContent };
          return content;
        });
        return prev;
      });
    }
  };

  const handleMakeBold = () => {
    setContents((prev) => {
      prev = [...prev].map((content, index) => {
        if (index === selectedContent) return { ...content, bold: !content.bold };
        return content;
      });
      return prev;
    });
  };

  const handleMakeTitle = () => {
    setContents((prev) => {
      prev = [...prev].map((content, index) => {
        if (index === selectedContent) return { ...content, title: !content.title };
        return content;
      });
      return prev;
    });
  };

  const handleSelectedContent = (index) => {
    setSelectedContent(index);
    if (contents[index].type === 'paragraph') {
      setModifyText(true);
      setParagraphUpdateText((prev) => {
        prev = contents[index].value;
        return prev;
      });
    }

    if (contents[index].type === 'imagery') {
      setModifyImageUrl(true);
      setImageUrlUpdate((prev) => {
        prev = contents[index].value;
        return prev;
      });
    }

    if (contents[index].type === 'video') {
      setModifyVideoUrl(true);
      setVideoUrlUpdate((prev) => {
        prev = contents[index].value;
        return prev;
      });
    }
  };
  const handleClearSelectedContent = () => {
    setSelectedContent(undefined);
    setParagraphUpdateText('');
    setModifyText(false);
  };

  const handleMoveUp = (index) => {
    // check if previous item exists
    if (!contents[index - 1]) return;

    const currentContent = contents[index];
    const previousContent = contents[index - 1];
    setContents((prev) => {
      prev = [...prev].map((content, indexx) => {
        if (indexx === index - 1) return currentContent;
        if (indexx === index) return previousContent;
        return content;
      });
      return prev;
    });
    setSelectedContent((prev) => prev - 1);
  };

  const handleMoveDown = (index) => {
    // check if next item exists
    if (!contents[index + 1]) return;

    const currentContent = contents[index];
    const nextContent = contents[index + 1];
    setContents((prev) => {
      prev = [...prev].map((content, indexx) => {
        if (indexx === index) return nextContent;
        if (indexx === index + 1) return currentContent;
        return content;
      });
      return prev;
    });
    setSelectedContent((prev) => prev + 1);
  };

  const handleDelete = (index) => {
    setContents((prev) => {
      prev = [...prev].filter((_, indexx) => indexx !== index);
      return prev;
    });
    setSelectedContent(undefined);
    setParagraphUpdateText('');
    setModifyText(false);
  };

  // tooltip
  const [showToolTip, setShowToolTip] = useState(false);
  const [toolTipMessage, setToolTipMessage] = useState('');
  const handleControlButtonMouseEnter = (tooltip) => {
    setShowToolTip(true);
    setToolTipMessage(tooltip);
  };
  const handleControlButtonMouseLeave = () => {
    setShowToolTip(false);
    setToolTipMessage('');
  };

  // upload article
  const [createLoading, setCreateLoading] = useState(false);

  const handleUploadArticle = async () => {
    setCreateLoading(true);
    const date = moment(new Date()).format('YYYY/MM/DD');
    const data = new FormData();
    data.append('category', category);
    data.append('title', head);
    data.append('description', description);
    data.append('published_date', date);
    data.append('image', selectedImage);
    try {
      const url = API_URLS.uploadNews;
      await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${userInfo.TOKEN}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      navigate(routeStrings.dashboard.news);
      setCreateLoading(false);
    } catch (error) {
      console.log('error creating article', error);
      setCreateLoading(false);
    }
  };

  const handleUpdateArticle = async (newsId) => {
    const body = {
      category,
      description,
      title: head,
    };
    setCreateLoading(true);
    if (selectedImage) {
      const formData = new FormData();
      formData.append('image', selectedImage);
      try {
        const url = API_URLS.editNewsImage + '/' + newsId;
        const response = await axios.post(url, formData, {
          headers: {
            Authorization: `Bearer ${userInfo.TOKEN}`,
            'Content-Type': 'multipart/form-data',
          },
        });

        console.log('response header', response);
      } catch (error) {
        console.log('error updating image', error);
      }
    }
    try {
      const url = API_URLS.editNews + '/' + newsId;
      const response = await axios.put(url, body, {
        headers: {
          Authorization: `Bearer ${userInfo.TOKEN}`,
        },
      });

      // await updateArticle(body, newsId);
      navigate(routeStrings.dashboard.news);
      setCreateLoading(false);
    } catch (error) {
      console.log('error updating article', error);
      setCreateLoading(false);
    }
  };

  if (createLoading) {
    return (
      <div className="items-center justify-center w-full p-10">
        <CustomCircularLoader message={newsId ? 'Updating article' : 'Creating article'} />
      </div>
    );
  }

  const handleGoBack = () => navigate(-1);

  return (
    <div className="relative flex flex-col w-full min-h-screen gap-10 py-20 bg-[#E0E3E8]">
      <div className="flex w-full max-w-[800px] self-center px-4">
        <button
          onClick={handleGoBack}
          className="w-[fit-content] flex items-center transition-all group gap-1 hover:gap-2 text-sm px-0 hover:px-4 py-2 rounded-lg hover:bg-white hover:font-bold"
        >
          <AiOutlineLeft className="transition-all group-hover:text-xs" />
          <p>Back</p>
        </button>
      </div>

      {data && (
        <div className="flex flex-col w-full h-full gap-4">
          <div className="w-full max-w-[800px] flex flex-col space-y-2 self-center p-4">
            <div className="w-full flex space-x-2 items-center justify-between">
              <div className="flex items-center gap-2">
                {/* <img src={data.source?.logo && data.source?.logo} alt="" className="h-[50px] aspect-square " />
                <div className="flex flex-col gap-1 text-xs md:text-sm whitespace-nowrap">
                  <p>{data.source.name}</p>
                  <p>{data.source.date && new Date(data.source.date).toDateString()}</p>
                </div> */}
              </div>
              <CustomButtonWithIcon onClick={handleOpenPopUp} text="EDIT HEADERS" Icon={<AiFillEdit />} />
            </div>
            <p className="text-5xl font-black text-[gray] leading-none ">{head}</p>
          </div>
          {selectedImage ? (
            <img src={URL.createObjectURL(selectedImage)} alt="" className="w-full max-w-[800px] self-center" />
          ) : (
            <img src={imageSource} alt="" className="w-full max-w-[800px] self-center" />
          )}
        </div>
      )}

      <ModalPopUp
        open={open}
        handleClose={handleClosePopUp}
        height="fit-content"
        width="95%"
        children={
          <UpdateHeaderInfoPopUp
            head={head}
            category={category}
            setHead={setHead}
            setCategory={setCategory}
            handleClosePopUp={handleClosePopUp}
          />
        }
      />
      <ModalPopUp
        open={open2}
        handleClose={handleClosePopUp2}
        height="fit-content"
        width="95%"
        children={
          <ChooseImage image={selectedImage} setImage={setselectedImage} handleClosePopUp={handleClosePopUp2} />
        }
      />
      {!addText && (
        <div className="w-full max-w-[700px] flex gap-4 px-4 self-center flex-col">
          <p>{description}</p>
        </div>
      )}
      {/* loop over the contents of the article */}
      <div className="w-full max-w-[700px] flex gap-4 px-4 self-center flex-col">
        {contents?.map((content, index) => {
          if (content.contentType === 'video') {
            return (
              <React.Fragment key={index}>
                {index === selectedContent && (
                  <div className="w-full rounded-full max-w-[900px] flex gap-5 items-center justify-between self-end bg-border p-2">
                    <div className="w-[fit-content] flex items-center justify-center gap-2">
                      {/* move up */}
                      <ControlButton
                        onClick={() => handleMoveUp(index)}
                        small
                        JSX={
                          <BsChevronBarUp className="text-2xl transition-all group-hover:text-white group-hover:text-3xl" />
                        }
                      />

                      {/* move down */}
                      <ControlButton
                        onClick={() => handleMoveDown(index)}
                        small
                        JSX={
                          <BsChevronBarDown className="text-2xl transition-all group-hover:text-white group-hover:text-3xl" />
                        }
                      />
                      {/* clear selection */}
                      <ControlButton
                        onClick={handleClearSelectedContent}
                        small
                        JSX={
                          <MdOutlineClear className="text-2xl transition-all group-hover:text-white group-hover:text-3xl" />
                        }
                      />
                    </div>

                    {/* delete */}
                    <ControlButton
                      onClick={() => handleDelete(index)}
                      small
                      JSX={<FaTrash className="text-lg transition-all group-hover:text-white group-hover:text-xl" />}
                    />
                  </div>
                )}
                <div className="relative w-full h-auto rounded-lg">
                  <ReactPlayer url={content.value} width="100%" controls />
                  {index !== selectedContent && (
                    <button
                      onClick={() => handleSelectedContent(index)}
                      className="absolute top-[-10px] right-5 bg-border border border-[gray] px-4 py-1 rounded-full flex items-center justify-center gap-2"
                    >
                      <FaRegEdit />
                      <p className="text-sm">Edit video</p>
                    </button>
                  )}
                </div>
                {Boolean(modifyVideoUrl && index === selectedContent) && (
                  <siv className="w-full max-w-[700px] self-center p-5 rounded-lg bg-border flex flex-col gap-2">
                    <CustomNewsInput
                      value={videoUrlUpdate}
                      setter={setVideoUrlUpdate}
                      placeholder="Paste in a valid Video url"
                      label="Video Url"
                    />
                    <CustomButtonWithIcon
                      onClick={handleUpdateVideo}
                      text="Update video"
                      Icon={<MdAddTask className="text-xl" />}
                    />
                  </siv>
                )}
              </React.Fragment>
            );
          }
          if (content.contentType === 'imagery') {
            return (
              <React.Fragment key={index}>
                {index === selectedContent && (
                  <div className="w-full rounded-full max-w-[900px] flex gap-5 items-center justify-between self-end bg-border p-2">
                    <div className="w-[fit-content] flex items-center justify-center gap-2">
                      {/* move up */}
                      <ControlButton
                        onClick={() => handleMoveUp(index)}
                        small
                        JSX={
                          <BsChevronBarUp className="text-2xl transition-all group-hover:text-white group-hover:text-3xl" />
                        }
                      />

                      {/* move down */}
                      <ControlButton
                        onClick={() => handleMoveDown(index)}
                        small
                        JSX={
                          <BsChevronBarDown className="text-2xl transition-all group-hover:text-white group-hover:text-3xl" />
                        }
                      />
                      {/* clear selection */}
                      <ControlButton
                        onClick={handleClearSelectedContent}
                        small
                        JSX={
                          <MdOutlineClear className="text-2xl transition-all group-hover:text-white group-hover:text-3xl" />
                        }
                      />
                    </div>

                    {/* delete */}
                    <ControlButton
                      onClick={() => handleDelete(index)}
                      small
                      JSX={<FaTrash className="text-lg transition-all group-hover:text-white group-hover:text-xl" />}
                    />
                  </div>
                )}
                <img
                  src={content.value}
                  alt=""
                  onClick={() => handleSelectedContent(index)}
                  className={`
                  ${index === selectedContent && 'border-2'}
                  hover:border-2 transition-all border-[gray] p-2 rounded-xl cursor-pointer`}
                />
                {Boolean(modifyImageUrl && index === selectedContent) && (
                  <siv className="w-full max-w-[700px] self-center p-5 rounded-lg bg-border flex flex-col gap-2">
                    <CustomNewsInput
                      value={imageUrlUpdate}
                      setter={setImageUrlUpdate}
                      placeholder="Paste in an image or gif url"
                      label="Image/Gif url"
                    />
                    <CustomButtonWithIcon
                      onClick={handleUpdateImagery}
                      text="Update imagery"
                      Icon={<MdAddTask className="text-xl" />}
                    />
                  </siv>
                )}
              </React.Fragment>
            );
          }
          if (content.contentType === 'paragraph') {
            return (
              <React.Fragment key={index}>
                {index === selectedContent && (
                  <div className="w-full rounded-full max-w-[900px] flex gap-5 items-center justify-between self-end bg-border p-2">
                    <div className="w-[fit-content] flex items-center justify-center gap-2">
                      {/*toggle title */}
                      <ControlButton
                        onClick={handleMakeTitle}
                        small
                        JSX={
                          <MdTextFields className="text-2xl transition-all group-hover:text-white group-hover:text-3xl" />
                        }
                        active={content.title}
                      />

                      {/*toggle bold */}
                      <ControlButton
                        onClick={handleMakeBold}
                        small
                        JSX={<FaBold className="text-2xl transition-all group-hover:text-white group-hover:text-3xl" />}
                        active={content.bold}
                      />

                      {/* move up */}
                      <ControlButton
                        onClick={() => handleMoveUp(index)}
                        small
                        JSX={
                          <BsChevronBarUp className="text-2xl transition-all group-hover:text-white group-hover:text-3xl" />
                        }
                      />

                      {/* move down */}
                      <ControlButton
                        onClick={() => handleMoveDown(index)}
                        small
                        JSX={
                          <BsChevronBarDown className="text-2xl transition-all group-hover:text-white group-hover:text-3xl" />
                        }
                      />

                      {/* clear selection */}
                      <ControlButton
                        onClick={handleClearSelectedContent}
                        small
                        JSX={
                          <TbPencilOff className="text-2xl transition-all group-hover:text-white group-hover:text-3xl" />
                        }
                      />
                    </div>

                    {/* delete */}
                    <ControlButton
                      onClick={() => handleDelete(index)}
                      small
                      JSX={<FaTrash className="text-lg transition-all group-hover:text-white group-hover:text-xl" />}
                    />
                  </div>
                )}
                <div
                  onClick={() => handleSelectedContent(index)}
                  className={`
                  ${index === selectedContent && 'border-2'} 
                  ${content.bold && 'font-black'} 
                  ${content.title && 'text-2xl'} 
                  hover:border-2 transition-all border-[gray] p-2 rounded-xl cursor-pointer`}
                >
                  {content.value}
                </div>

                {Boolean(modifyText && index === selectedContent) && (
                  <div ref={divRef} className="w-full max-w-[700px] flex gap-4 px-4 self-center flex-col">
                    <CustomTextArea value={paragraphUpdateText} setter={setParagraphUpdateText} />
                    <CustomButtonWithIcon
                      onClick={handleUpdateParagraph}
                      text="Update paragraph"
                      Icon={<MdAddTask className="text-xl" />}
                    />
                  </div>
                )}
              </React.Fragment>
            );
          }
          return <React.Fragment key={index}></React.Fragment>;
        })}
      </div>

      {/* ui action handlers based on the button pressed */}
      {addText && (
        <div ref={divRef} className="w-full max-w-[700px] flex gap-4 px-4 self-center flex-col">
          <CustomTextArea value={description} setter={setDescription} />
          {/* <CustomTextArea value={paragraphText} setter={setParagraphText} /> */}
          {/* <CustomButtonWithIcon
            onClick={handleAddParagraph}
            text="Insert paragraph"
            Icon={<MdAddTask className="text-xl" />}
          /> */}
        </div>
      )}

      {addImageUrl && (
        <siv className="w-full max-w-[700px] self-center p-5 rounded-lg bg-border flex flex-col gap-2">
          <CustomNewsInput
            value={imageUrl}
            setter={setImageUrl}
            placeholder="Paste in a valid image or gif url"
            label="Image/Gif url"
          />
          <CustomButtonWithIcon
            onClick={handleAddImagery}
            text="Insert imagery"
            Icon={<MdAddTask className="text-xl" />}
          />
        </siv>
      )}

      {addVideoUrl && (
        <siv className="w-full max-w-[700px] self-center p-5 rounded-lg bg-border flex flex-col gap-2">
          <p className="p-2 mb-2 text-sm bg-white rounded-lg">
            <span className="font-bold">NOTE: </span> Supported video urls are YouTube, Facebook, Twitch, SoundCloud,
            Streamable, Vimeo, Wistia, Mixcloud, DailyMotion and Kaltura
          </p>
          <CustomNewsInput
            value={videoUrl}
            setter={setVideoUrl}
            placeholder="Paste in a valid Video url"
            label="Video url"
          />
          <CustomButtonWithIcon onClick={handleAddVideo} text="Insert Video" Icon={<MdAddTask className="text-xl" />} />
        </siv>
      )}

      <div className="h-[120px]" />

      <div className="w-[fit-content] rounded-full max-w-[700px] flex flex-col gap-2 items-center justify-between self-center fixed bottom-5">
        {showToolTip && (
          <p className="border border-[gray] bg-[#212121] text-sm text-white bg-opacity-80 backdrop-blur-md px-2 py-1 rounded-full">
            {toolTipMessage}
          </p>
        )}
        <div className="flex items-center self-center justify-between w-full gap-4 p-2 border rounded-full border-[#212121] bg-border bg-opacity-20 backdrop-blur-md">
          <div className="flex items-center self-center justify-center w-[fit-content] gap-4 p-2 rounded-full border border-border bg-border bg-opacity-40 backdrop-blur-md">
            {/* add paragraph */}
            <ControlButton
              JSX={<BsPenFill className="text-2xl transition-all group-hover:text-white group-hover:text-3xl" />}
              onClick={toggleAddText}
              active={addText}
              onMouseEnter={() => handleControlButtonMouseEnter(newsId ? 'Edit description ' : 'Add description')}
              onMouseLeave={handleControlButtonMouseLeave}
            />

            {/* add image */}
            <ControlButton
              JSX={<BsImage className="text-2xl transition-all group-hover:text-white group-hover:text-3xl" />}
              onClick={handleOpenPopUp2}
              active={addImageUrl}
              onMouseLeave={handleControlButtonMouseLeave}
              onMouseEnter={() => handleControlButtonMouseEnter(newsId ? 'Edit image' : 'Add image')}
            />

            {/* add video */}
            {/* <ControlButton
              JSX={
                <BsCollectionPlayFill className="text-2xl transition-all group-hover:text-white group-hover:text-3xl" />
              }
              onClick={toggleAddVideoUrl}
              active={addVideoUrl}
              onMouseLeave={handleControlButtonMouseLeave}
              onMouseEnter={() => handleControlButtonMouseEnter('Add video')}
            /> */}
          </div>

          {/* Upload or update article */}
          <ControlButton
            JSX={
              newsId ? (
                <GrUpdate className="text-2xl transition-all group-hover:text-white group-hover:text-3xl" />
              ) : (
                <AiOutlineUpload className="text-2xl transition-all group-hover:text-white group-hover:text-3xl" />
              )
            }
            onClick={() => (newsId ? handleUpdateArticle(newsId) : handleUploadArticle())}
            // active={addVideoUrl}
            onMouseLeave={handleControlButtonMouseLeave}
            onMouseEnter={() => handleControlButtonMouseEnter(newsId ? 'Update article' : 'Upload article')}
          />
        </div>
      </div>
    </div>
  );
}

export default NewsContentCreator;

const ControlButton = ({ JSX, onClick, active = false, small, onMouseEnter, onMouseLeave }) => {
  return (
    <button
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`${
        small ? 'w-[45px]' : 'w-[70px]'
      } group aspect-square rounded-full bg-white border border-[gray] flex items-center justify-center ${
        active && 'bg-[#212121] text-white border-2 border-[#212121] '
      } hover:bg-[gray] transition-all`}
    >
      {JSX}
    </button>
  );
};

const CustomTextArea = ({ value, setter }) => {
  const onChange = (e) => setter(e.target.value);
  return (
    <TextareaAutosize
      value={value}
      onChange={onChange}
      aria-label="minimum height"
      minRows={8}
      placeholder="Minimum 3 rows"
      className="w-full p-4 font-semibold outline-1 rounded-xl bg-border"
    />
  );
};
