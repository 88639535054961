import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import axios from 'axios';
import API_URLS from '../../../apiUrls';
import getApiConfig from "helpers/getApiConfig"
import { GET_USER_INFO } from 'state/slices/user-auth-info';
import { useSelector } from 'react-redux';

function useKingOfMatch( match, handleClose) {

  const [king, setKing] = useState({});
  const [selectKing, setSelectKing] = useState();
  const [player, setPlayer] = useState({})
  const [homePlayers, setHomePlayers] = useState([]);
  const [awayPlayers, setAwayPlayers] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  const { TOKEN } = useSelector(GET_USER_INFO);

  const handleChange = (event) => {
    setSelectKing(event.target.value);
    const player = [
      ...homePlayers,
      ...awayPlayers
    ].find(player => player.name === event.target.value)
    setPlayer(player)
  };

  const getPlayers = async () => {
    setIsLoading(true)
    const api_call = async (teamId) => {
      const response = await axios.get(`${API_URLS.players}/team/${teamId}`);
      return response;
    };


    const promises = [api_call(match.home_team._id), api_call(match.away_team._id)];

    try {
      const results = await Promise.allSettled(promises);
      setHomePlayers(results[0].value.data)
      setAwayPlayers(results[1].value.data)
      setIsLoading(false)

    } catch (error) {
      setIsLoading(false)
      console.log('error fatching players', error);
      toast.error('something went wrong try again later')
    }
  };

  const getKing = async () => {
    setIsLoading(true)
    try {
    const response =  await axios.get(`${API_URLS.kings}/match/${match._id}`);

    if( response.status === 200 ){
      console.log("response in get king in pass if ",response)
      setKing(response.data)
      toast.success(`King of this match is ${response.data.player.name}`)
      setIsLoading(false)
    } else{
      toast('Set King of the Match')
    }

    } catch (error) {
      console.log("error in get team", error)
      toast('Set King of the Match')
      setIsLoading(false)
    }
  };

  useEffect(() => {
    getPlayers()
  },[])

  useEffect(() => {
    getKing()
  },[])

  const handleClick = () => {
    setIsLoading(true)

    if(player){
      const api_call = async () => {
        const config = getApiConfig(TOKEN)
        const body = {
          player: player._id,
          club: player.current_club._id,
          match: match._id,
          play_date:match.playing_date
        }
       try {
        const response = await axios.post(`${API_URLS.kings}/add`, body, config);

        if (response.status === 201){
          handleClose()
          toast.success(`king of the Match has been set as succesfully, ${player.name}`)
          setIsLoading(false)
        }
        else{
          handleClose()
          toast.error('an error occured')
          setIsLoading(false)
        }
      } catch (err){
        console.log(err.response.data.message)
        toast.error(err.response.data.message)
      }
    }
    api_call()
 
    }else{
      toast.error('select king of the match before proceed')
    }

  };

  return {
    isLoading,
    king,
    selectKing,
    homePlayers,
    awayPlayers,
    handleChange,
    handleClick
  };
}

export default useKingOfMatch;
