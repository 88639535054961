import CustomButtonWithIcon from 'components/CustomButtonWithIcon';
import ModalPopUp from 'components/ModalPopUp';
import AddNews from 'features/news-add/AddNews';
import NewsViewing from 'features/news-viewing/NewsViewing';
import { IoIosAddCircleOutline } from 'react-icons/io';
import useNews from './use-news';

function News() {
  const newsHook = useNews();
  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex flex-col w-full gap-2 px-5 max-w-[1200px]">
        <div className="flex flex-col items-center justify-between w-full gap-2 py-5 md:flex-row">
          <div className="flex flex-col gap-1">
            <p className="text-2xl font-bold text-[gray]">News</p>
            <p className="text-sm text-[gray]">
              This is where you can view all news within the scope of NBC Premier League. To add a news entry, click on
              add news and follow the instructions provided.
            </p>
          </div>
          <div className="w-full md:w-[200px] flex justify-end">
            <CustomButtonWithIcon
              fullWidth
              Icon={<IoIosAddCircleOutline />}
              text="Add news"
              onClick={newsHook.handleOpen}
            />
          </div>
        </div>
      </div>
      <NewsViewing />
      <ModalPopUp
        open={newsHook.openModal}
        handleClose={newsHook.handleClose}
        height="fit-content"
        width="90%"
        children={<AddNews />}
      />
    </div>
  );
}

export default News;
