import Modal from "@mui/material/Modal";

function ModalPopUp({
  open,
  handleClose,
  children,
  width,
  maxWidth,
  height,
  data,
}) {
  return (
    <Modal
      open={ open }
      onClose={ handleClose }
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className="rounded-xl bg-[#F9FAFB] overflow-scroll scrollbar-hide absolute flex flex-col gap-3"
        style={ {
          width: width ?? 700,
          maxWidth: maxWidth ?? 700,
          maxHeight: "90vh",
          height: height ?? "95%",
          margin: "auto",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: 50,
        } }
      >
        { children ?? "" }
      </div>
    </Modal>
  );
}

export default ModalPopUp;
