import React from 'react'

function CustomGrid({ children }) {
    return (
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-3'>
            { children }
        </div>
    )
}

export default CustomGrid