import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { Box, Drawer, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// mock
import account from '../../_mock/account';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import NavSection from '../../components/NavSection';
import Scrollbar from '../../components/Scrollbar';
//
import UserProfileAvatar from '../../components/UserProfileAavatar';
import { GET_USER_INFO } from '../../state/slices/user-auth-info';
import navConfig from './NavConfig';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { NAME } = useSelector(GET_USER_INFO)
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={ {
        height: 1,
        backgroundColor: '#21409A',
        color: '#fff',
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      } }
    >
      <Box sx={ { px: 2.5, py: 3, display: 'inline-flex', alignItems: 'center', justifyContent: 'center' } }>
        <Logo />
      </Box>

      <Box sx={ { mb: 5, mx: 2.5 } }>
        <Link underline="none" component={ RouterLink } to="#">
          <AccountStyle>
            <UserProfileAvatar />
            <Box sx={ { ml: 2 } }>
              <Typography variant="subtitle2" sx={ { color: '#fff' } } className="capitalize">
                { NAME }
              </Typography>
              <Typography variant="body2" sx={ { color: '#fff' } }>
                { account.role }
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={ navConfig } />

      <Box sx={ { flexGrow: 1 } } />
    </Scrollbar>
  );

  return (
    <RootStyle>
      { !isDesktop && (
        <Drawer
          open={ isOpenSidebar }
          onClose={ onCloseSidebar }
          PaperProps={ {
            sx: { width: DRAWER_WIDTH },
          } }
        >
          { renderContent }
        </Drawer>
      ) }

      { isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={ {
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          } }
        >
          { renderContent }
        </Drawer>
      ) }
    </RootStyle>
  );
}
