import CustomButtonWithIcon from 'components/CustomButtonWithIcon';
import CustomCircularLoader from 'components/CustomCircularLoader';
import CustomInput from 'components/CustomInput';
import CustomMultipleSelect from 'components/CustomMultipleSelect';
import CustomSelect from 'components/CustomSelect';
import SectionLabel from 'components/SectionLabel';
import { RiSendPlane2Fill } from 'react-icons/ri';
import useAddGroundPopUpForm from '../use-add-ground-pop-up';

function AddGroundPopUpForm({ handleClose, handleRefresh }) {
  const hook = useAddGroundPopUpForm(handleRefresh, handleClose);

  if (hook.loading) {
    return (
      <div className="flex items-center justify-center w-full">
        <CustomCircularLoader />
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full gap-2">
    <p className="font-extrabold text-2xl capitalize text-[gray]">Add new ticket provider</p>
      <p className="text-[gray] text-sm">
        Here you add a stadium. This information will be used when creating a match schedule denoting where the match
        will actually take place.
      </p>

      <CustomInput value={hook.stadiumName} setter={hook.setStadiumName} placeholder="Name of stadium" />
      <CustomSelect
        value={hook.region}
        onChange={hook.handleRegionChange}
        label="Region"
        data={['Dar es Salaam', 'Morogoro', 'Tanga', 'Mtwara', 'Mbeya', 'Dodoma']}
      />
      <CustomMultipleSelect data={hook.teams} value={hook.selectedTeams} setter={hook.setSelectedTeams} label="Teams" />
      <CustomButtonWithIcon
        Icon={<RiSendPlane2Fill />}
        text={hook.addStadiumLoading ? 'loading ...' : 'Confirm Add Stadium'}
        onClick={hook.handleConfirmAddStadium}
      />
    </div>
  );
}

export default AddGroundPopUpForm;
