import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import API_URLS from 'apiUrls';
import axios from 'axios';
import { getNewsById } from './functions';

const useNewsPreview = () => {
  const { newsId } = useParams();
  const [loading, setLoading] = useState(false);
  const [article, setArticle] = useState({});
  const apiCall = async () => {
    try {
      setLoading(true);
      const response = await axios.get(API_URLS.news + '/' + newsId).then((res) => {
        const result = res.data;
        setArticle(result);
        setLoading(false);
      });
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    apiCall();
  }, []);

  return { loading, article };
};

export default useNewsPreview;
