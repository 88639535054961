const StatRow = ({ homeStat, awayStat, label, colors }) => {
  if (label.toLowerCase() === 'possession') {
    const totalPasses = parseInt(homeStat) + parseInt(awayStat);
    const homePossession = Math.round((parseInt(homeStat) / totalPasses) * 100);
    const awayPossession = 100 - homePossession;

    return (
      <div className="w-full flex flex-col items-center gap-2 p-2">
        <p className="font-bold text-xs text-[gray] uppercase">{label}</p>
        <div className="w-full h-[30px] flex gap-1">
          <PossessionBar possessionStat={homePossession} color={colors.home} isHome />
          <PossessionBar possessionStat={awayPossession} color={colors.away} />
        </div>
      </div>
    );
  }
  return (
    <div className="w-full flex items-center justify-between gap-3 px-2">
      <p className="text-sm font-bold w-[30px]">{homeStat}</p>
      <p className="font-bold text-xs text-[gray] uppercase">{label}</p>
      <p className="text-sm font-bold w-[30px] text-right">{awayStat}</p>
    </div>
  );
};

export default StatRow;

const PossessionBar = ({ possessionStat, color, isHome }) => {
  return (
    <div
      style={{
        width: possessionStat && possessionStat !== null ? `${possessionStat}%` : '50%',
        backgroundColor: color ?? 'tomato',
        justifyContent: isHome ? 'flex-start' : 'flex-end',
      }}
      className="h-full rounded-md flex items-center px-2"
    >
      <p className="text-white font-bold text-xs">{possessionStat ? possessionStat : 50}%</p>
    </div>
  );
};
