import CustomInput from "components/CustomInput"
import { updateMatchHighlightUrl } from "features/view-all-matches/functions"
import { useState } from "react"
import toast from "react-hot-toast"
import { FiArrowRight } from "react-icons/fi"
import { ImYoutube2 } from 'react-icons/im'
import { useSelector } from "react-redux"
import { GET_USER_INFO } from "state/slices/user-auth-info"
import CustomCircularLoader from 'components/CustomCircularLoader'
import CustomButtonWithIcon from "components/CustomButtonWithIcon"

function AddMatchHighlight({ homeTeamName = "home_team", awayTeamName = "away_team", matchId, handleRefresh }) {
    const [url, setUrl] = useState("")

    const [loading, setLoading] = useState(false)

    const { TOKEN } = useSelector(GET_USER_INFO)

    const Span = ({ text }) => <span className="px-2 py-1 font-bold underline rounded-lg decoration-dotted bg-border">{text}</span>

    const handleConfirm = async () => {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|v=|\?v=)([^#]*).*/;
        var match = url.match(regExp)

        if (!match) {
            toast.error("Please enter a valid youtube url")
            return
        }

        setLoading(true)
        const body = { highlights_url: url }
        try {
            await updateMatchHighlightUrl(TOKEN, body, matchId)
            setLoading(false)
            handleRefresh()
        } catch (error) {
            console.log("something went wrong uploading highlight url", error)
            toast.error("something went wrong")
            setLoading(false)
        }
    }

    if (loading) {
        return (
            <div className="flex items-center justify-center w-full">
                <CustomCircularLoader message="uploading highlight url" />
            </div>
        )
    }

    return (
        <div className="flex flex-col w-full">
            <p className="font-bold text-[gray] text-2xl">Add match highlight</p>
            <p>You are about to add match highlight link for the match between <Span text={homeTeamName} /> and <Span text={awayTeamName} />.</p>
            <div className="w-full my-3">
                <CustomInput
                    value={url}
                    setter={setUrl}
                    placeholder="https://www.youtube.com/watch?v=0Av9fYdTXqA"
                    PreffixIcon={<ImYoutube2 />} />
            </div>
            <CustomButtonWithIcon
                Icon={<FiArrowRight className="transition-all group-hover:mx-2" />}
                disabled={url.length === 0}
                onClick={handleConfirm}
                text="Add match highlight"
            />
        </div>
    )
}

export default AddMatchHighlight