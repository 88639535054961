import CustomButtonWithIcon from 'components/CustomButtonWithIcon';
import CustomCircularLoader from 'components/CustomCircularLoader';
import ModalPopUp from 'components/ModalPopUp';
import { useState, useEffect } from 'react';
import { AiFillEdit, AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import { MdClear } from 'react-icons/md';
import ReactPlayer from 'react-player/lazy';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GET_USER_INFO } from './../../state/slices/user-auth-info';
import routeStrings from 'routeStrings';
import useNewsPreview from './use-news-preview';
import API_URLS from 'apiUrls';
import { NEW_BASE_URL } from 'apiUrls';
import axios from 'axios';

function NewsArticlePreview() {
  const navigate = useNavigate();
  const { newsId } = useParams();
  const [loading, setLoading] = useState(false);
  const [article, setArticles] = useState([]);
  const handleGoBack = () => navigate(-1);
  const newsPreviewHook = useNewsPreview();

  const apiCall = async () => {
    try {
      const response = await axios.get(API_URLS.news + '/' + newsId).then((res) => {
        const result = res.data;
        setLoading(true);
        setArticles(result);
        setLoading(false);
      });
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    apiCall();
  }, []);

  // console.log(article,'article')
  const handleEdit = (newsId) => {
    navigate(`${routeStrings.newsArticleContentUpdateBase}/${newsId}`);
  };

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  if (newsPreviewHook.loading) return <p>Loading ...</p>;

  return (
    <div className="flex flex-col items-center w-full  h-full py-10 bg-[#E0E3E8]">
      <div className="flex w-full max-w-[800px] px-4">
        <button
          onClick={handleGoBack}
          className="w-[fit-content] flex items-center transition-all group gap-1 hover:gap-2 text-sm px-0 hover:px-4 py-2 rounded-lg hover:bg-white hover:font-bold"
        >
          <AiOutlineLeft className="transition-all group-hover:text-xs" />
          <p>Back</p>
        </button>
      </div>

      <ModalPopUp
        open={openModal}
        handleClose={handleCloseModal}
        height="fit-content"
        width="95%"
        children={<AreYouSure articleId={newsPreviewHook.article._id} handleClose={handleCloseModal} />}
      />

      {((article) => {
        if (article === {}) return <></>;
        const { source, title: head, image_url: imageSource, description: contents, _id } = article;
        return (
          <div className="flex flex-col items-center w-full max-w-[800px]">
            <div className="flex flex-col w-full max-w-[800px] space-y-3 p-4">
              <div className="flex flex-row space-x-2 w-full items-center justify-between">
                <div className="flex items-center gap-2">
                  {/* <img src={source.logo} alt="" className="h-[50px] aspect-square" />
                    <div className="flex flex-col gap-1">
                      <p className="text-sm font-bold">{source.name}</p>
                      <p className="text-xs">{source.date}</p>
                    </div> */}
                </div>
                <div className="flex items-center space-x-2">
                  <CustomButtonWithIcon onClick={() => handleEdit(_id)} text="Edit" Icon={<AiFillEdit />} />
                  <CustomButtonWithIcon plain onClick={handleOpenModal} text="Delete" Icon={<FaTrash />} />
                </div>
              </div>

              <p className="text-4xl font-bold text-[gray]">{head}</p>
            </div>
            <img src={`${NEW_BASE_URL}/${imageSource}`} alt="" className="w-full" />
            <div className="flex flex-col w-full max-w-[700px] gap-4 p-4">
              <p className="text-2xl">{contents}</p>
              {/*contents &&
                contents.map((content, index) => {
                  if (content.contentType === 'paragraph')
                    return (
                      <p
                        key={index}
                        className={` 
                        ${content.title && 'text-2xl'}
                        ${content.bold && 'font-bold'}
                        `}
                      >
                        {content.value}
                      </p>
                    );
                  if (content.contentType === 'imagery') {
                    return (
                      <img
                        key={index}
                        src={content.value}
                        alt=""
                        className="w-full h-[50vh] shadow-xl object-cover rounded-md"
                      />
                    );
                  }
                  if (content.contentType === 'video') {
                    return (
                      <ReactPlayer
                        key={index}
                        style={{ border: '0.5px solid gray', borderRadius: 10, overflow: 'clip' }}
                        url={content.value}
                        width="100%"
                        controls
                      />
                    );
                  }
                  return <></>;
                }) */}
            </div>
          </div>
        );
      })(newsPreviewHook.article)}
    </div>
  );
}

export default NewsArticlePreview;

const AreYouSure = ({ articleId, handleClose }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const userInfo = useSelector(GET_USER_INFO);

  const handleDelete = async () => {
    setLoading(true);
    try {
      const url = API_URLS.deleteNews + '/' + articleId;
      await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${userInfo.TOKEN}`,
        },
      });

      setLoading(false);
      handleClose();
      navigate(-1);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="w-full p-5 flex items-center justify-center">
        <CustomCircularLoader message="Deleting news article" />
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-2">
      <p className="text-2xl font-bold">Are you sure you want to delete this article?</p>
      <p className="text-sm text-[gray]">
        Note that deleting this article means that, you will not be able to recover it again.
      </p>
      <div className="flex items-center justify-end space-x-2 mt-2">
        <CustomButtonWithIcon onClick={handleClose} text="Cancel deletion" Icon={<MdClear className="text-white" />} />
        <CustomButtonWithIcon onClick={handleDelete} plain text="Proceed to delete" Icon={<AiOutlineRight />} />
      </div>
    </div>
  );
};
