import { useState } from 'react';
import { BiHide, BiShow } from 'react-icons/bi';
import { FiDelete } from 'react-icons/fi';
import Label from './Label';

function CustomInput({
  suffix = false,
  placeholder = '',
  type = 'text',
  value,
  setter,
  onChange,
  label = '',
  minimum,
  PreffixIcon = null,
}) {
  const [showSuffix, setShowSuffix] = useState(false);
  const toggleShowSuffix = () => setShowSuffix(!showSuffix);
  const clearInput = () => setter('');
  return (
    <div className="w-full h-[60px] bg-[#ffffff] border border-[#CCD6E3] flex items-center justify-between gap-2 px-2 rounded-lg overflow-clip">
      {PreffixIcon && (
        <div className="h-[40px] w-[fit-content] text-4xl border border-[#CCD6E3] flex items-center justify-center rounded-lg cursor-pointer hover:bg-[#CCD6E3]">
          {PreffixIcon}
        </div>
      )}

      <Label
      children={label}
      />
      <input
        value={value}
        min={type === 'number' ? minimum ?? 0 : undefined}
        onChange={onChange ? onChange : (e) => setter(e.target.value)}
        type={showSuffix ? 'text' : type}
        placeholder={placeholder}
        className="w-full h-full font-bold bg-[transparent] outline-none placeholder:text-sm placeholder:font-normal"
      />
      {suffix && <Icon showSuffix={showSuffix} toggle={toggleShowSuffix} />}
      {value?.toString().length > 0 ? (
        <button
          onClick={clearInput}
          className="h-[40px] aspect-square border border-[#CCD6E3] flex items-center justify-center rounded-lg cursor-pointer hover:bg-[#CCD6E3]"
        >
          <FiDelete className="" />
        </button>
      ) : (
        <p className="hidden">ppp</p>
      )}
    </div>
  );
}

export default CustomInput;

// --------------- icon --------------------
const Icon = ({ showSuffix, toggle }) => (
  <button
    type="button"
    onClick={toggle}
    className="h-[40px] aspect-square border border-[#CCD6E3] flex items-center justify-center rounded-lg cursor-pointer hover:bg-[#CCD6E3] transition-all"
  >
    {showSuffix ? <BiShow /> : <BiHide />}
  </button>
);
