import CustomMUITabs from 'components/CustomMuiTabs';
import { useState } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import NextSeasonRoundSetting from './components/NextSeasonRoundSetting';

export default function Settings() {
  const navigate = useNavigate();
  const handleGoBack = () => navigate(-1);

  const settingsOptions = [{ label: 'next season rounds', component: <NextSeasonRoundSetting /> }];

  const [selectedSettingIndex, setSelectedSettingIndex] = useState(0);
  const handleChange = (_, newIndex) => setSelectedSettingIndex(newIndex);
  return (
    <div className="w-full flex flex-col space-y-2 px-2">
      <button
        onClick={handleGoBack}
        className="w-[fit-content] flex items-center transition-all group gap-1 hover:gap-2 text-sm px-0 hover:px-4 py-2 rounded-lg hover:bg-[whitesmoke] hover:font-bold"
      >
        <AiOutlineLeft className="transition-all group-hover:text-xs" />
        <p>Back</p>
      </button>

      <CustomMUITabs
        value={selectedSettingIndex}
        handleChange={handleChange}
        list={settingsOptions.map((option, index) => ({ value: index, label: option.label }))}
      />

      {settingsOptions[selectedSettingIndex].component}
    </div>
  );
}
