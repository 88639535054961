import React, { useState, useEffect } from 'react'
import SectionLabel from 'components/SectionLabel'
import { useLocation } from 'react-router-dom'
import toast from 'react-hot-toast'
import axios from "axios"
import { HiOutlineSwitchVertical,  } from 'react-icons/hi'
import ModalPopUp from 'components/ModalPopUp'
import { BiUserCircle,BiCheck } from 'react-icons/bi'
import { AiOutlineUserSwitch } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { GET_USER_INFO } from 'state/slices/user-auth-info'
const positions = [
    'GK',
    'LB',
    'LCB',
    'RCB',
    'RB',
    'CB',
    'LM',
    'RM',
    'CAM',
    'LCM',
    'RCM',
    'CDM',
    'CM',
    'LDM',
    'RDM',
    'LW',
    'RW',
    'LCF',
    'RCF',
    'CF',
  ]

function LineUp() {
    const { state } = useLocation()
    const [ lineUps, setLineUps ] = useState([])
    const [ homeLineUp, setHomeLineUp ] = useState(null)
    const [ awayLineUp, setAwayLineUp ] = useState(null)
    const [ homeTeamPlayers, setHomeTeamPlayers] = useState([])
    const [ awayTeamPlayers, setAwayTeamPlayers] = useState([])
    const [ isPlayerListOpen, setIsPlayerListOpen] = useState(false)
    const [ isAwayPlayerListOpen, setIsAwayPlayerListOpen] = useState(false)
    const [ activePlayer, setActivePlayer] = useState(null)
    const [ isPopUpPositionOpened, setIsPopUpPositionOpened ] = useState(false)
    const [ isPopUpAwayPositionOpened, setIsPopUpAwayPositionOpened ] = useState(false)

    const { TOKEN } = useSelector(GET_USER_INFO)
    const config = {
      headers: {
        Authorization: `Bearer ${ TOKEN }`,
        }
    }

    const updateHomeLineUp = async(newHomeLineUp) =>{
        try { 
          const body = newHomeLineUp
          const { _id: lineUpId } = homeLineUp
          const response = await axios.put(`https://api-v2.ligi.co.tz/lineup/edit/${lineUpId}`, body, config)
          if(response.statusText === "Accepted") {
            toast.success("Home team Line-up updated successfully")
          }
    
         if(response.statusText !== "Accepted") {
           toast.error("Failed to update home team lineup")
         }
            
        } catch(err) {
            toast.error('Error while updating home line-up')
        }
     }
    
     const updateAwayLineUp = async(newTeamLineUp) =>{
      try { 

        const body = newTeamLineUp
        const { _id: lineUpId } = awayLineUp
        const response = await axios.put(`https://api-v2.ligi.co.tz/lineup/edit/${lineUpId}`, body, config)
        if(response.statusText === "Accepted") {
            toast.success(" Away team Line-up updated successfully")
        }
    
        if(response.statusText !== "Accepted") {
           toast.error("Failed to updated lineup")
        }
          
       } catch(err) {
        //console.log(err)
          toast.error('Error while updating away line-up')
      }
    }
    
    const listLineUps = async() => {
        try { 
            const { _id: matchId } = state.match
            const { _id:homeTeamId } = state.homeTeam
            const response = await axios.get(`https://api-v2.ligi.co.tz/lineup/match/${matchId}`)
            if(response.statusText === "OK") {
               setLineUps(response.data)
               if(response.data.length > 0) {
                splitTeamId(response.data)
               }
            }
      
            if(!response.statusText) {
               
            }
        } catch(err) {
            toast.error('Error while listing line ups')
        }
    }

    const splitTeamId = (responseData) => {
        const { _id:homeTeamId } = state.homeTeam
        responseData.map( item => {
             if(item.team._id === homeTeamId) {
                return  setHomeLineUp(item)
             }

             return setAwayLineUp(item)
        })
    }

    const changePosition =(player) =>{
           setActivePlayer(player.player)
           setIsPopUpPositionOpened(true)
     }

    const changeAwayPosition =(player) =>{
        setActivePlayer(player.player)
        setIsPopUpAwayPositionOpened(true)
     }

     const getHomeTeamPlayers = async() => {
        try {
          const { _id } = state.homeTeam || ""
          const response = await axios.get(`https://api-v2.ligi.co.tz/player?team=${_id}`)
          if(response.statusText === "OK") {
             setHomeTeamPlayers(response.data)
          }
    
          if(!response.statusText) {
             
          }
         } catch(err) {
           console.log(err)
        }
      }
    
      const getAwayTeamPlayers = async() => {
        try {
          const { _id } = state.awayTeam || ""
          const response = await axios.get(`https://api-v2.ligi.co.tz/player?team=${_id}`)
          if(response.statusText === "OK") {
             setAwayTeamPlayers(response.data)
          }
    
          if(!response.statusText) {
             
          }
         } catch(err) {
           console.log(err)
        }
      }

      const changePlayer = (Player) => {
            setIsPlayerListOpen(true)
            setActivePlayer(Player)
      }

      const changeAwayPlayer = (Player) => {
        setIsAwayPlayerListOpen(true)
        setActivePlayer(Player)
     }

      const switchPlayer = (Player) => {
        const newHomeLineUp = homeLineUp.players.map(eachPlayer => {
           
             if(eachPlayer.player._id === activePlayer._id) {
                toast.success(`${eachPlayer.player.name } replaced by ${Player.name }`)
                return { 
                    ...eachPlayer,
                    player:Player
                }
             }
             return eachPlayer
        })
        setIsPlayerListOpen(false)
        setHomeLineUp({ ...homeLineUp, players: newHomeLineUp})
        updateHomeLineUp({ ...homeLineUp, players: newHomeLineUp})
     } 

     const switchAwayPlayer = (Player) => {
        const newAwayLineUp = awayLineUp.players.map(eachPlayer => {
           
             if(eachPlayer.player._id === activePlayer._id) {
                toast.success(`${eachPlayer.player.name } replaced by ${Player.name }`)
                return { 
                    ...eachPlayer,
                    player:Player
                }
             }
             return eachPlayer
        })
        setIsAwayPlayerListOpen(false)
        updateAwayLineUp({ ...awayLineUp, players: newAwayLineUp})
        setAwayLineUp({ ...awayLineUp, players: newAwayLineUp})
     } 


     const selectPlayerPosition =(playerPosition) => {
        const newHomePlayers = homeLineUp.players.map(Player => {
          if(Player.player._id === activePlayer._id) {
            setIsPopUpPositionOpened(false)
            toast.success(`${Player.player.name} switched position to ${playerPosition}`)
             return {
              ...Player, 
               position: playerPosition
             }
          }
          return Player
        })
        
        setHomeLineUp({ ...homeLineUp, players: newHomePlayers})
        updateHomeLineUp({ ...homeLineUp, players: newHomePlayers})
    }

    const selectAwayPlayerPosition =(playerPosition) => {
        const newAwayPlayers = awayLineUp.players.map(Player => {
          if(Player.player._id === activePlayer._id) {
            setIsPopUpAwayPositionOpened(false)
            toast.success(`${Player.player.name} switched position to ${playerPosition}`)
             return {
              ...Player, 
               position: playerPosition
             }
          }
          return Player
        })
        updateAwayLineUp({ ...awayLineUp, players: newAwayPlayers})
        setAwayLineUp({ ...awayLineUp, players: newAwayPlayers})
      }
    

    useEffect(()=> {
        if(state === null) { } else {
            listLineUps()
            getAwayTeamPlayers()
            getHomeTeamPlayers()
        }
    },[state])
  
   return (
    <div className="w-full px-[5%] flex flex-col gap-4 select-none">
    <div className="flex flex-col items-center w-full gap-2 rounded-lg md:flex-row md:justify-between">
      <div className="flex flex-col gap-1">
        <SectionLabel label="Line up" />
        <p className="text-sm">
          This is where you can view all line-ups for both home team and away team
          <br />
          You can also edit the line-ups for each team.
        </p>
      </div>
    </div>
    {state === null ? null :
        <div className="two-grid">
            <div className="left-grid">
            <h2>{ state.homeTeam.name }</h2>
     { homeLineUp !== null ? homeLineUp.players.map(player => (
        <div className="player-box" key={player._id}>
            <div className="fab" id="touchable" onClick={() => changePlayer(player.player)}>
                <AiOutlineUserSwitch /></div>
                <div className="player-title">
                    <h3>{ player.player.name }</h3>
                    <p>{ player.position }</p>
                </div>
                <div className="player-box-far">
                    <div className="fab" id="touchable"
                        onClick={() => changePosition(player)}>
                        <HiOutlineSwitchVertical /></div> 
                </div>
        </div>
     )): null}
            </div>

            <div className="right-grid" style={{ marginLeft: '2%'}}>
            <h2>{ state.awayTeam.name }</h2>
            { awayLineUp !== null ? awayLineUp.players.map(player => (
        <div className="player-box" key={player._id}>
            <div className="fab" id="touchable" onClick={() => changeAwayPlayer(player.player)}>
                <AiOutlineUserSwitch /></div>
                <div className="player-title">
                    <h3>{ player.player.name }</h3>
                    <p>{ player.position }</p>
                </div>
                <div className="player-box-far">
                    <div className="fab" id="touchable"  
                    onClick={() => changeAwayPosition(player)}><HiOutlineSwitchVertical /></div> 
                </div>
        </div>
     )): null}
   </div>

      <ModalPopUp
        open={isPlayerListOpen}
        handleClose={() => setIsPlayerListOpen(false)}
        height="fit-content"
        width="100%">

      <SectionLabel label={`Change Player`} />
       { homeTeamPlayers.length <= 0 ? null : homeTeamPlayers.map(player => (
            <div className="player-box" key={player._id}>
                <div className="fab"><BiUserCircle /></div>
                    <div className="player-title">
                        <h3>{ player.name }</h3>
                        <p>{ player.country }</p>
                    </div>
                    <div className="player-box-far">
                        <div className="fab" id="touchable" onClick={()=> switchPlayer(player)}><BiCheck /></div> 
                </div>
            </div>
       ))}
   
      </ModalPopUp>

      
      <ModalPopUp
        open={isAwayPlayerListOpen}
        handleClose={() => setIsAwayPlayerListOpen(false)}
        height="fit-content"
        width="100%">

      <SectionLabel label={`Change Player`} />
       { awayTeamPlayers.length <= 0 ? null : awayTeamPlayers.map(player => (
            <div className="player-box" key={player._id}>
                <div className="fab"><BiUserCircle /></div>
                    <div className="player-title">
                        <h3>{ player.name }</h3>
                        <p>{ player.country }</p>
                    </div>
                    <div className="player-box-far">
                        <div className="fab" id="touchable" onClick={()=> switchAwayPlayer(player)}><BiCheck /></div> 
                </div>
            </div>
       ))}
   
      </ModalPopUp>

      
      <ModalPopUp
        open={isPopUpPositionOpened}
        handleClose={() => setIsPopUpPositionOpened(false)}
        height="fit-content"
        width="100%">

      <SectionLabel label={`Select position`} />
       <select className="form-select-positions" onChange={(e) => selectPlayerPosition(e.target.value)}>
          <option value="Position">Position</option>
          { positions.map((position, index) => (
            <option value={position} key={index}>{position}</option>
          ))}
       </select>
      </ModalPopUp>

      <ModalPopUp
        open={isPopUpAwayPositionOpened}
        handleClose={() => setIsPopUpPositionOpened(false)}
        height="fit-content"
        width="100%">

      <SectionLabel label={`Select position`} />
       <select className="form-select-positions" onChange={(e) => selectAwayPlayerPosition(e.target.value)}>
          <option value="Position">Position</option>
          { positions.map((position, index) => (
            <option value={position} key={index}>{position}</option>
          ))}
       </select>
      </ModalPopUp>
        </div>}
  </div>
  )
}

export default LineUp
