import MatchFactItem from './components/FactItem';

const MatchFacts = ({ facts, homeTeam }) => {
  if (!facts) {
    return <p className="text-xs font-bold text-[gray] text-center">Facts not available</p>;
  }
  let sortedFacts;
  try {
    sortedFacts = [...facts]?.sort((a, b) => (a.time > b.time ? 1 : -1));
  } catch (error) {
    console.error('error', error);
    sortedFacts = [];
  }
 
  return (
    <div className="w-full flex flex-col items-center p-2 rounded-lg bg-[whitesmoke]">
      <p className="uppercase text-[gray] font-bold text-sm">Facts</p>
      <div className="flex flex-col w-full p-2 rounded-lg">
        {sortedFacts.map((fact, index) => (
          <MatchFactItem key={index} fact={fact} isHome={homeTeam._id === fact.team} />
        ))}
      </div>
    </div>
  );
};

export default MatchFacts;
