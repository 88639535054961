import axios from 'axios';
import getApiConfig from 'helpers/getApiConfig';
import API_URLS from '../../apiUrls';

export const getAllMatches = () => {
  try {
    return axios.get(API_URLS.matches);
  } catch (error) {
    return Promise.resolve();
  }
};

export const getMatchByStatus = (status) => {
  try {
    return axios.get(`${API_URLS.matches}/status/${status}`);
  } catch (error) {
    return Promise.resolve();
  }
};
export const getMatches = (data) => {
  try {
    return axios.get(`${API_URLS.matches}`, {
      params: data,
    });
  } catch (error) {
    return error;
  }
};
export const getCurrentSeason = () => {
  try {
    return axios.get(`${API_URLS.currentSeason}`);
  } catch (error) {
    return error;
  }
};
export const getRounds = () => {
  try {
    return axios.get(`${API_URLS.rounds}`);
  } catch (error) {
    return error;
  }
};
export const getSeasons = () => {
  try {
    return axios.get(`${API_URLS.seasons}`);
  } catch (error) {
    return error;
  }
};
export const getAllMatchesBySeason = (season, limitData) => {
  try {
    const url = `${API_URLS.matchesBySeason}/${season}?limitData=${limitData}`;
    return axios.get(url);
  } catch (error) {
    return Promise.resolve();
  }
};

export const getMatchStats = (matchId) => {
  try {
    return axios.get(`${API_URLS.matchStats}/${matchId}`);
  } catch (error) {
    return Promise.resolve();
  }
};

export const getMatchFacts = (matchId) => {
  try {
    return axios.get(`${API_URLS.matchFacts}/${matchId}`);
  } catch (error) {
    return Promise.resolve();
  }
};

export const makeMatchLive = (matchId, token) => {
  const config = getApiConfig(token);
  try {
    return axios.put(`${API_URLS.matchMakeLive}/${matchId}`, {}, config);
  } catch (error) {
    return Promise.resolve();
  }
};
export const getAllStats = () => {
  try {
    return axios.get(API_URLS.allStats);
  } catch (error) {
    return Promise.resolve();
  }
};

export const getPlayers = () => {
  try {
    return axios.get(API_URLS.players);
  } catch (error) {
    return Promise.resolve();
  }
};

export const updateMatchMoment = (token, body) => {
  const config = getApiConfig(token);
  try {
    return axios.post(API_URLS.addMatchMoment, body, config);
  } catch (error) {
    return Promise.resolve();
  }
};

export const updateMatchFact = (token, body) => {
  const config = getApiConfig(token);
  try {
    return axios.post(API_URLS.addMatchFact, body, config);
  } catch (error) {
    return Promise.resolve();
  }
};

export const updateMatchStat = (token, body, matchId) => {
  const config = getApiConfig(token);
  try {
    return axios.put(`${API_URLS.updateMatchStat}/${matchId}`, body, config);
  } catch (error) {
    return Promise.resolve();
  }
};

export const updateMatchTable = (token, body) => {
  const config = getApiConfig(token);

  try {
    return axios.put(API_URLS.updateMatchTable, body, config);
  } catch (error) {
    return Promise.resolve();
  }
};

export const updateMatchHighlightUrl = (token, body, matchId) => {
  const config = getApiConfig(token);
  try {
    return axios.put(`${API_URLS.editMatch}/${matchId}`, body, config);
  } catch (error) {
    return Promise.resolve();
  }
};
