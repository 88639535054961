import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import DashboardApp from './pages/DashboardApp';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Products from './pages/Products';
import Register from './pages/Register';
import User from './pages/User';
import Matches from './pages/Matches';
import routeStrings from './routeStrings';
import Seasons from 'pages/seasons/Seasons';
import Rounds from 'pages/rounds/Rounds';
import Schedules from 'pages/Schedules';
import Teams from 'pages/Teams';
import TeamProfile from 'features/team-profile/TeamProfile';
import ListGrounds from 'features/list-grounds/ListGrounds';
import News from 'pages/News';
import NewsPreview from 'pages/NewsPreview';
import NewsContentCreator from 'pages/NewsContentCreator';
import Settings from 'pages/Settings';
import Tickets from 'pages/tickets/Tickets';
import LineUp from 'pages/lineup/LineUp';

// ----------------------------------------------------------------------
export default function Router({ token: TOKEN, navigate }) {
  const routes = [
    {
      path: routeStrings.dashboard.index,
      element: <DashboardLayout />,
      children: [
        { path: routeStrings.dashboard.app, exact: true, element: <DashboardApp /> },
        { path: routeStrings.dashboard.settings, exact: true, element: <Settings /> },
        { path: routeStrings.dashboard.user, exact: true, element: <User /> },
        { path: routeStrings.dashboard.products, exact: true, element: <Products /> },
        { path: routeStrings.dashboard.blog, exact: true, element: <Blog /> },
        { path: routeStrings.dashboard.seasons, exact: true, element: <Seasons /> },
        { path: routeStrings.dashboard.rounds, exact: true, element: <Rounds /> },
        { path: routeStrings.dashboard.teams, exact: true, element: <Teams /> },
        { path: routeStrings.dashboard.stadiums, exact: true, element: <ListGrounds /> },
        { path: routeStrings.dashboard.tickets, exact: true, element: <Tickets /> },
        { path: routeStrings.dashboard.matches, exact: true, element: <Matches /> },
        { path: routeStrings.dashboard.schedules, exact: true, element: <Schedules /> },
        { path: routeStrings.dashboard.news, exact: true, element: <News /> },
        { path: routeStrings.dashboard.lineup, exact: true, element: <LineUp /> },
      ],
    },
    { path: routeStrings.teamProfileFull, exact: true, element: <TeamProfile /> },
    { path: routeStrings.newsArticleFull, exact: true, element: <NewsPreview /> },
    { path: routeStrings.newsArticleContentCreate, exact: true, element: <NewsContentCreator /> },
    { path: routeStrings.newsArticleContentUpdateBase, exact: true, element: <NewsContentCreator /> },
    { path: routeStrings.newsArticleContentUpdate, exact: true, element: <NewsContentCreator /> },
    {
      path: routeStrings.login,
      exact: true,
      element: <Login />,
    },
    {
      path: routeStrings.register,
      exact: true,
      element: <Register />,
    },
    {
      path: routeStrings.root.index,
      element: <LogoOnlyLayout />,
      children: [
        { path: routeStrings.root.index, exact: true, element: <Navigate to={routeStrings.dashboard.app} /> },
        { path: routeStrings.root.page404, element: <NotFound /> },
        { path: routeStrings.root.wildCard, element: <Navigate to={routeStrings.root.page404} /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to={routeStrings.root.page404} replace />,
    },
  ];

  return useRoutes(routes);
}
