import TeamList from "features/team-list/TeamList"

function Teams() {
  return (
    <div className="flex flex-col gap-2 items-center justify-center">
        <TeamList />
    </div>
  )
}

export default Teams