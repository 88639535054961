import { useState } from 'react';
import useEditStatsForm from './useEditStatsForm';
import CustomCircularLoader from 'components/CustomCircularLoader';
import { CustomSelectWithObjects } from 'components/CustomSelect';
import CustomInput from 'components/CustomInput';
import { RiRefreshLine } from 'react-icons/ri';

const EditStatsForm = ({ match, loading, players, handleRefresh }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const TABS = ['goals', 'shots', 'shots on target', 'passes', 'red card', 'yellow card', 'substitutions', 'facts'];
  const TabButton = ({ label, index, selected }) => {
    const onClick = () => setSelectedTab(index);
    return (
      <button
        onClick={onClick}
        className={`px-4 py-3 capitalize font-bold text-sm rounded-lg border border-border hover:bg-border transition-all ${
          selected ? 'bg-border' : 'bg-[transparent]'
        }`}
      >
        {label}
      </button>
    );
  };
  const TabButtons = () => {
    return (
      <>
        {TABS.map((tab, index) => (
          <TabButton key={index} label={tab} index={index} selected={selectedTab === index} />
        ))}
      </>
    );
  };

  const { home_team: homeTeam, away_team: awayTeam, stats } = match;

  let homeStats;
  let awayStats;

  try {
    homeStats = stats.find((statObj) => statObj.team === homeTeam._id);
    awayStats = stats.find((statObj) => statObj.team === awayTeam._id);
  } catch (error) {
    console.error('error ocurred while getting goals in upcoming matches', error);
  }

  const TeamTitle = ({ title }) => <p className="text-[gray] text-sm font-bold underline decoration-dotted">{title}</p>;

  const hook = useEditStatsForm(match, handleRefresh, homeStats, awayStats);

  const homeTeamPlayers = players.filter((player) => player.current_club._id === homeTeam._id);
  const awayTeamPlayers = players.filter((player) => player.current_club._id === awayTeam._id);

  if (loading || hook.loading) {
    return (
      <div className="w-full p-4 flex items-center justify-center">
        <CustomCircularLoader message="Please wait" />
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full gap-2 select-none">
      <div className="w-full font-bold text-lg text-[gray] pb-2 border border-[transparent] border-b-border">
        Match Stats and Facts
      </div>
      <div className="flex gap-2 w-full grow">
        <div className="rounded-lg p-2 flex flex-col gap-2 whitespace-nowrap">
          <TabButtons />
        </div>
        <div className="w-full rounded-lg flex">
          {((tabIndex) => {
            switch (tabIndex) {
              case 0:
                return (
                  <div className="w-full flex flex-col gap-2">
                    <div className="w-full flex-col gap-2 bg-[white] border border-border p-2 rounded-lg">
                      <TeamTitle title={homeTeam.name} />

                      <CustomSelectWithObjects
                        value={hook.goalScorer.home}
                        onChange={(e) => hook.handleGoalScorerChange(e, 'home')}
                        data={((_players) => {
                          return _players.map((_player) => ({ label: _player.name, value: _player._id }));
                        })(homeTeamPlayers)}
                        label="Goal scorer"
                      />

                      <CustomSelectWithObjects
                        value={hook.goalAssister.home}
                        onChange={(e) => hook.handleGoalAssisterChange(e, 'home')}
                        data={((_players) => {
                          return _players.map((_player) => ({ label: _player.name, value: _player._id }));
                        })(homeTeamPlayers)}
                        label="Goal Assister"
                      />

                      <CustomSelectWithObjects
                        value={hook.selectedGoalType.home}
                        onChange={(e) =>
                          hook.setSelectedGoalType((prev) => {
                            prev = { ...prev, home: e.target.value };
                            return prev;
                          })
                        }
                        data={((goalTypes) => {
                          return goalTypes.map((goalType) => ({ label: goalType, value: goalType }));
                        })(hook.goalTypes)}
                        label="Goal type"
                      />

                      <div className="flex flex-col gap-1  mt-2">
                        <p className="text-xs">Minute of Goal</p>
                        <CustomInput
                          value={hook.goalMinute.home}
                          onChange={(e) => hook.handleGoalMinuteChange(e, 'home')}
                        />
                      </div>
                    </div>

                    <hr className="text-border my-4 w-full self-center" />

                    <div className="w-full flex-col gap-2 bg-[white] border border-border p-2 rounded-lg">
                      <TeamTitle title={awayTeam.name} />

                      <CustomSelectWithObjects
                        value={hook.goalScorer.away}
                        onChange={(e) => hook.handleGoalScorerChange(e, 'away')}
                        data={((_players) => {
                          return _players.map((_player) => ({ label: _player.name, value: _player._id }));
                        })(awayTeamPlayers)}
                        label="Goal scorer"
                      />

                      <CustomSelectWithObjects
                        value={hook.goalAssister.away}
                        onChange={(e) => hook.handleGoalAssisterChange(e, 'away')}
                        data={((_players) => {
                          return _players.map((_player) => ({ label: _player.name, value: _player._id }));
                        })(awayTeamPlayers)}
                        label="Goal Assister"
                      />

                      <CustomSelectWithObjects
                        value={hook.selectedGoalType.away}
                        onChange={(e) =>
                          hook.setSelectedGoalType((prev) => {
                            prev = { ...prev, away: e.target.value };
                            return prev;
                          })
                        }
                        data={((goalTypes) => {
                          return goalTypes.map((goalType) => ({ label: goalType, value: goalType }));
                        })(hook.goalTypes)}
                        label="Goal type"
                      />

                      <div className="flex flex-col gap-1  mt-2">
                        <p className="text-xs">Minute of Goal</p>
                        <CustomInput
                          value={hook.goalMinute.away}
                          onChange={(e) => hook.handleGoalMinuteChange(e, 'away')}
                        />
                      </div>
                    </div>
                  </div>
                );

              case 1:
                return (
                  <div className="w-full flex flex-col gap-2">
                    <div className="w-full flex-col gap-2 bg-[white] border border-border p-2 rounded-lg">
                      <TeamTitle title={homeTeam.name} />
                      <div className="flex flex-col gap-1">
                        <p className="text-xs">Shots</p>
                        <CustomInput
                          type="number"
                          value={hook.shots.home}
                          placeholder={`shots by ${homeTeam.name}`}
                          onChange={(e) => {
                            hook.handleShotsChange(e, 'home');
                          }}
                        />
                      </div>
                    </div>
                    <hr className="text-[gray] my-4 w-full self-center" />
                    <div className="w-full flex-col gap-2 bg-[white] border border-border p-2 rounded-lg">
                      <TeamTitle title={awayTeam.name} />
                      <div className="flex flex-col gap-1">
                        <p className="text-xs">Shots</p>
                        <CustomInput
                          type="number"
                          value={hook.shots.away}
                          placeholder={`shots by ${awayTeam.name}`}
                          onChange={(e) => {
                            hook.handleShotsChange(e, 'away');
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );

              case 2:
                return (
                  <div className="w-full flex flex-col gap-2">
                    <div className="w-full flex-col gap-2 bg-[white] border border-border p-2 rounded-lg">
                      <TeamTitle title={homeTeam.name} />
                      <div className="flex flex-col gap-1">
                        <p className="text-xs">Shots on target</p>
                        <CustomInput
                          type="number"
                          value={hook.shotsOnTarget.home}
                          placeholder={`shots on target by ${homeTeam.name}`}
                          onChange={(e) => {
                            hook.handleShotsOnTargetChange(e, 'home');
                          }}
                        />
                      </div>
                    </div>
                    <hr className="text-[gray] my-4 w-full self-center" />
                    <div className="w-full flex-col gap-2 bg-[white] border border-border p-2 rounded-lg">
                      <TeamTitle title={awayTeam.name} />
                      <div className="flex flex-col gap-1">
                        <p className="text-xs">Shots on target</p>
                        <CustomInput
                          type="number"
                          value={hook.shotsOnTarget.away}
                          placeholder={`shots on target by ${awayTeam.name}`}
                          onChange={(e) => {
                            hook.handleShotsOnTargetChange(e, 'away');
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );

              case 3:
                return (
                  <div className="w-full flex flex-col gap-2">
                    <div className="w-full flex-col gap-2 bg-[white] border border-border p-2 rounded-lg">
                      <TeamTitle title={homeTeam.name} />
                      <div className="flex flex-col gap-1">
                        <p className="text-xs">Passes</p>
                        <CustomInput
                          type="number"
                          value={hook.passes.home}
                          placeholder={`passes by ${homeTeam.name}`}
                          onChange={(e) => {
                            hook.handlePassesChange(e, 'home');
                          }}
                        />
                      </div>
                    </div>
                    <hr className="text-[gray] my-4 w-full self-center" />
                    <div className="w-full flex-col gap-2 bg-[white] border border-border p-2 rounded-lg">
                      <TeamTitle title={awayTeam.name} />
                      <div className="flex flex-col gap-1">
                        <p className="text-xs">Passes</p>
                        <CustomInput
                          type="number"
                          value={hook.passes.away}
                          placeholder={`passes by ${awayTeam.name}`}
                          onChange={(e) => {
                            hook.handlePassesChange(e, 'away');
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );

              case 4:
                return (
                  <div className="w-full flex flex-col gap-2">
                    <div className="w-full flex-col gap-2 bg-[white] border border-border p-2 rounded-lg">
                      <TeamTitle title={homeTeam.name} />

                      <CustomSelectWithObjects
                        value={hook.playerRedCard.home}
                        onChange={(e) => hook.handlePlayerRedCardChange(e, 'home')}
                        data={((_players) => {
                          return _players.map((_player) => ({ label: _player.name, value: _player._id }));
                        })(homeTeamPlayers)}
                        label="Player awarded a Red Card"
                      />

                      <div className="flex flex-col gap-1">
                        <p className="text-xs">Minute of red card</p>
                        <CustomInput
                          type="number"
                          value={hook.timeRedCard.home}
                          placeholder={`passes by ${homeTeam.name}`}
                          onChange={(e) => {
                            hook.handleTimeRedCardChange(e, 'home');
                          }}
                        />
                      </div>
                    </div>

                    <hr className="text-[gray] my-4 w-full self-center" />

                    <div className="w-full flex-col gap-2 bg-[white] border border-border p-2 rounded-lg">
                      <TeamTitle title={awayTeam.name} />

                      <CustomSelectWithObjects
                        value={hook.playerRedCard.away}
                        onChange={(e) => hook.handlePlayerRedCardChange(e, 'away')}
                        data={((_players) => {
                          return _players.map((_player) => ({ label: _player.name, value: _player._id }));
                        })(awayTeamPlayers)}
                        label="Player awarded Red Card"
                      />

                      <div className="flex flex-col gap-1">
                        <p className="text-xs">Minute of red card</p>
                        <CustomInput
                          type="number"
                          value={hook.timeRedCard.away}
                          placeholder={`red cards by ${awayTeam.name}`}
                          onChange={(e) => {
                            hook.handleTimeRedCardChange(e, 'away');
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );

              case 5:
                return (
                  <div className="w-full flex flex-col gap-2">
                    <div className="w-full flex-col gap-2 bg-[white] border border-border p-2 rounded-lg">
                      <TeamTitle title={homeTeam.name} />

                      <CustomSelectWithObjects
                        value={hook.playerYellowCard.home}
                        onChange={(e) => hook.handlePlayerYellowCardChange(e, 'home')}
                        data={((_players) => {
                          return _players.map((_player) => ({ label: _player.name, value: _player._id }));
                        })(homeTeamPlayers)}
                        label="Player awarded a Yellow Card"
                      />

                      <div className="flex flex-col gap-1">
                        <p className="text-xs">Minute of Yellow card</p>
                        <CustomInput
                          type="number"
                          value={hook.timeYellowCard.home}
                          placeholder={`yellow cards by ${homeTeam.name}`}
                          onChange={(e) => {
                            hook.handleTimeYellowCardChange(e, 'home');
                          }}
                        />
                      </div>
                    </div>

                    <hr className="text-[gray] my-4 w-full self-center" />

                    <div className="w-full flex-col gap-2 bg-[white] border border-border p-2 rounded-lg">
                      <TeamTitle title={awayTeam.name} />

                      <CustomSelectWithObjects
                        value={hook.playerYellowCard.away}
                        onChange={(e) => hook.handlePlayerYellowCardChange(e, 'away')}
                        data={((_players) => {
                          return _players.map((_player) => ({ label: _player.name, value: _player._id }));
                        })(awayTeamPlayers)}
                        label="Player awarded a Yellow Card"
                      />

                      <div className="flex flex-col gap-1">
                        <p className="text-xs">Minute of Yellow card</p>
                        <CustomInput
                          type="number"
                          value={hook.timeYellowCard.away}
                          placeholder={`yellow cards by ${awayTeam.name}`}
                          onChange={(e) => {
                            hook.handleTimeYellowCardChange(e, 'away');
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );

              case 6:
                return (
                  <div className="w-full flex flex-col gap-2">
                    <div className="w-full flex-col gap-2 bg-[white] border border-border p-2 rounded-lg">
                      <TeamTitle title={homeTeam.name} />

                      <CustomSelectWithObjects
                        value={hook.substitutions.home.in}
                        onChange={(e) => hook.handleSubstitutionsChange(e, 'home', true)}
                        data={((_players) => {
                          return _players.map((_player) => ({ label: _player.name, value: _player._id }));
                        })(homeTeamPlayers)}
                        label="Player coming in"
                      />

                      <CustomSelectWithObjects
                        value={hook.substitutions.home.out}
                        onChange={(e) => hook.handleSubstitutionsChange(e, 'home', false)}
                        data={((_players) => {
                          return _players.map((_player) => ({ label: _player.name, value: _player._id }));
                        })(homeTeamPlayers)}
                        label="Player going out"
                      />
                      <div className="flex flex-col gap-1">
                        <p className="text-xs">Minute of Substitution</p>
                        <CustomInput
                          type="number"
                          value={hook.timeSubstitution.home}
                          placeholder={`Minute of Substitution`}
                          onChange={(e) => {
                            hook.handleTimeSubstitutionChange(e, 'home');
                          }}
                        />
                      </div>
                    </div>

                    <hr className="text-[gray] my-4 w-full self-center" />

                    <div className="w-full flex-col gap-2 bg-[white] border border-border p-2 rounded-lg">
                      <TeamTitle title={awayTeam.name} />

                      <CustomSelectWithObjects
                        value={hook.substitutions.away.in}
                        onChange={(e) => hook.handleSubstitutionsChange(e, 'away', true)}
                        data={((_players) => {
                          return _players.map((_player) => ({ label: _player.name, value: _player._id }));
                        })(awayTeamPlayers)}
                        label="Player coming in"
                      />

                      <CustomSelectWithObjects
                        value={hook.substitutions.away.out}
                        onChange={(e) => hook.handleSubstitutionsChange(e, 'away', false)}
                        data={((_players) => {
                          return _players.map((_player) => ({ label: _player.name, value: _player._id }));
                        })(awayTeamPlayers)}
                        label="Player going out"
                      />

                      <div className="flex flex-col gap-1">
                        <p className="text-xs">Minute of Substitution</p>
                        <CustomInput
                          type="number"
                          value={hook.timeSubstitution.away}
                          placeholder={`Minute of Substitution`}
                          onChange={(e) => {
                            hook.handleTimeSubstitutionChange(e, 'away');
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );

              case 7:
                return (
                  <div className="w-full flex flex-col gap-2">
                    <div className="w-full flex-col gap-2 bg-[white] border border-border p-2 rounded-lg">
                      <TeamTitle title={homeTeam.name} />

                      <div className="flex flex-col gap-1">
                        <p className="text-xs">Add fact</p>
                        <CustomInput
                          type="number"
                          value={hook.matchFactText.home}
                          placeholder={`Fact`}
                          onChange={(e) => {
                            hook.handleMatchFactText(e, 'home');
                          }}
                        />
                      </div>
                      <div className="flex flex-col gap-1">
                        <p className="text-xs">minute</p>
                        <CustomInput
                          type="number"
                          value={hook.matchFactTime.home}
                          placeholder={`minute`}
                          onChange={(e) => {
                            hook.handleMatchFactTime(e, 'home');
                          }}
                        />
                      </div>
                    </div>

                    <hr className="text-[gray] my-4 w-full self-center" />

                    <div className="w-full flex-col gap-2 bg-[white] border border-border p-2 rounded-lg">
                      <TeamTitle title={awayTeam.name} />
                      <div className="flex flex-col gap-1">
                        <p className="text-xs">Add fact</p>
                        <CustomInput
                          type="number"
                          value={hook.matchFactText.away}
                          placeholder={`Fact`}
                          onChange={(e) => {
                            hook.handleMatchFactText(e, 'away');
                          }}
                        />
                      </div>
                      <div className="flex flex-col gap-1">
                        <p className="text-xs">minute</p>
                        <CustomInput
                          type="number"
                          value={hook.matchFactTime.home}
                          placeholder={`minute`}
                          onChange={(e) => {
                            hook.handleMatchFactTime(e, 'away');
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );

              default:
                return <></>;
            }
          })(selectedTab)}
        </div>
      </div>
      <button
        onClick={hook.handleConfirmEdit}
        className="text-white group mt-2 w-[fit-content] px-10 self-end py-3 flex items-center justify-center gap-2 rounded-lg bg-button hover:bg-buttonHovered"
      >
        <RiRefreshLine className="group-hover:rotate-[360deg] transition-all" />
        <p className="text-sm font-bold">Confirm stats edit</p>
      </button>
    </div>
  );
};

export default EditStatsForm;
