import { useSelector } from 'react-redux';
import { GET_USER_INFO } from '../state/slices/user-auth-info';
import nameInitialsTransformer from '../helpers/nameInitialsTransformer';

function UserProfileAvatar() {
  const { NAME } = useSelector(GET_USER_INFO);
  return (
    <div className="border border-[#1890FF] h-[45px] aspect-square bg-[#1890FF] rounded-full uppercase font-[800] text-sm text-white flex items-center justify-center">
      <p>{nameInitialsTransformer(NAME)}</p>
    </div>
  );
}

export default UserProfileAvatar;
