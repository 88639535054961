import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    MATCHES: [],
    PREVIOUS_MATCHES: [],
    MAPPED_MATCHES: [
        {
            status : "nor",
            MATCHES : [],
            ROUNDS : []
        },
        {
            status : "fin",
            MATCHES : [],
            ROUNDS : []
        },
        {
            status : "live",
            MATCHES : [],
            ROUNDS : []
        }
    ],
   
    SEASONS: [],
    SELECTED_SEASON: '',
    UPCOMING_ROUNDS: [],
    ROUNDS: [],  
    PREVIOUS_ROUNDS: [],
    SELECTED_ROUND: '',
    MATCH_STATUS_FILTERS: ((statusFilters) => {
        return statusFilters.map((status, index) => ({ ...status, id: index }))
    })([
        { label: "live", value: "live" },
        { label: "fulltime", value: "fin" },
        { label: "upcoming", value: "nor" },
    ]),
    FACTS: [],
};

export const matchesSlice = createSlice({
    name: 'MATCHES',
    initialState,
    reducers: {
        SET_MATCHES: (state, action) => {
            state.MATCHES = [...action.payload.MATCHES];
        },
        SET_PREVIOUS_MATCHES: (state, action) => {
            state.PREVIOUS_MATCHES = [...action.payload.PREVIOUS_MATCHES];
        },
        SET_MAPPED_MATCHES: (state, action) => {
            if (action.payload.status === "nor"){
                state.MAPPED_MATCHES[0].MATCHES = action.payload.MATCHES;
                state.MAPPED_MATCHES[0].ROUNDS = action.payload.ROUNDS;
            } 
            if (action.payload.status === "fin"){
                state.MAPPED_MATCHES[1].MATCHES = action.payload.MATCHES;
                state.MAPPED_MATCHES[1].ROUNDS = action.payload.ROUNDS;
            } 
            if (action.payload.status === "live"){
                state.MAPPED_MATCHES[2].MATCHES = action.payload.MATCHES;
                state.MAPPED_MATCHES[2].ROUNDS = action.payload.ROUNDS;
            } 
        },
        SET_SEASONS: (state, action) => {
            state.SEASONS = [...action.payload.SEASONS];
        },
        SET_SELECTED_SEASON: (state, action) => {
            state.SELECTED_SEASON = action.payload.SELECTED_SEASON;
        },
        HANDLE_SEASON_SELECT_CHANGE: (state, action) => {
            state.SELECTED_SEASON = action.payload;
        },
        SET_ROUNDS: (state, action) => {
            state.ROUNDS = action.payload.ROUNDS;
        },
        SET_UPCOMING_ROUNDS: (state, action) => {
            state.ROUNDS = action.payload.UROUNDS;
        },
        SET_PREVIOUS_ROUNDS: (state, action) => {
            state.ROUNDS = action.payload.PROUNDS;
        },
        SET_SELECTED_ROUND: (state, action) => {
            state.SELECTED_ROUND = action.payload.SELECTED_ROUND;
        },
        SET_MATCH_FACTS: (state, action) => {
            state.FACTS = [...action.payload.FACTS]
        }
    },
});

// action creators
export const {
    SET_MATCHES,
    SET_MAPPED_MATCHES,
    SET_SEASONS,
    SET_SELECTED_SEASON,
    HANDLE_SEASON_SELECT_CHANGE,
    SET_ROUNDS,
    SET_SELECTED_ROUND,
    SET_PREVIOUS_MATCHES,
    SET_MATCH_FACTS
} = matchesSlice.actions;

// state getters
export const GET_MATCHES_INFO = (state) => state.matches;

export default matchesSlice.reducer;
