import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import listGroundsApi from 'features/list-grounds/list-grounds-api';
// import teamApi from 'features/team-list/team-api';
import teamProfileApi from 'features/team-profile/team-profile-api';
import newsReducer from 'pages/News/newsSlice';
import teamReducer from 'features/team-list/teamSlice';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import matchesReducer from './slices/matches';
import userInfoReducer from './slices/user-auth-info';

const rootReducer = combineReducers({
  userInfo: userInfoReducer,
  matches: matchesReducer,
  newsHead: newsReducer,
  teams: teamReducer,
  [teamProfileApi.reducerPath]: teamProfileApi.reducer,
  [listGroundsApi.reducerPath]: listGroundsApi.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['userInfo', 'newsHead'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  // middleware: [thunk, teamApi.middleware, teamProfileApi.middleware, listGroundsApi.middleware],
  middleware: [thunk, listGroundsApi.middleware, teamProfileApi.middleware,],
});

export const persistor = persistStore(store);

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
