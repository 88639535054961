// component
import { BsBarChartFill, BsCalendar2CheckFill, BsCalendar2Day, BsFillCalendar2WeekFill, BsArrowCounterclockwise } from 'react-icons/bs';
import { GiGoalKeeper } from 'react-icons/gi';
import { IoIosPeople } from 'react-icons/io';
import { HiTicket } from 'react-icons/hi'
import { HiNewspaper } from 'react-icons/hi'
import routeStrings from '../../routeStrings';

const navConfig = [
  {
    title: 'dashboard',
    path: routeStrings.dashboard.app,
    icon: <BsBarChartFill />,
  },
  {
    title: 'seasons',
    path: routeStrings.dashboard.seasons,
    icon: <BsCalendar2CheckFill />,
  },
  {
    title: 'rounds',
    path: routeStrings.dashboard.rounds,
    icon: <BsArrowCounterclockwise />,
  },
  {
    title: 'teams',
    path: routeStrings.dashboard.teams,
    icon: <IoIosPeople />,
  },
  {
    title: 'stadiums',
    path: routeStrings.dashboard.stadiums,
    icon: <GiGoalKeeper />,
  },
  {
    title: 'tickets',
    path: routeStrings.dashboard.tickets,
    icon: <HiTicket />,
  },
  {
    title: 'matches',
    path: routeStrings.dashboard.matches,
    icon: <BsFillCalendar2WeekFill />,
  },
  {
    title: 'schedule',
    path: routeStrings.dashboard.schedules,
    icon: <BsCalendar2Day />,
  },
  {
    title: 'news',
    path: routeStrings.dashboard.news,
    icon: <HiNewspaper />,
  },
  // {
  //   title: 'user',
  //   path: routeStrings.dashboard.user,
  //   icon: getIcon('eva:people-fill'),
  // },
  // {
  //   title: 'product',
  //   path: routeStrings.dashboard.products,
  //   icon: getIcon('eva:shopping-bag-fill'),
  // },
  // {
  //   title: 'blog',
  //   path: routeStrings.dashboard.blog,
  //   icon: getIcon('eva:file-text-fill'),
  // },
  // {
  //   title: 'login',
  //   path: routeStrings.login,
  //   icon: getIcon('eva:lock-fill'),
  // },
  // {
  //   title: 'register',
  //   path: routeStrings.register,
  //   icon: getIcon('eva:person-add-fill'),
  // },
  // {
  //   title: 'Not found',
  //   path: routeStrings.root.page404,
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
];

export default navConfig;
