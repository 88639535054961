const routeStrings = {
  dashboard: {
    index: '/dashboard',
    app: '/dashboard/app',
    settings: '/dashboard/app/settings',
    user: '/dashboard/user',
    products: '/dashboard/products',
    blog: '/dashboard/blog',
    matches: '/dashboard/matches', //routeStrings.matches
    schedules: '/dashboard/schedules',
    seasons: '/dashboard/seasons',
    rounds: '/dashboard/rounds',
    teams: '/dashboard/teams',
    stadiums: '/dashboard/stadiums',
    news: '/dashboard/news',
    tickets: '/dashboard/tickets',
    lineup: '/dashboard/lineup',
  },
  teamProfile: '/teams',
  newsArticle: '/news',
  newsArticleFull: '/news/:newsId',
  newsArticleContentCreate: '/news/content-create',
  newsArticleContentUpdateBase: '/news/content-update',
  newsArticleContentUpdate: '/news/content-update/:newsId',
  teamProfileFull: '/teams/:teamId',
  login: '/login',
  register: '/register',
  root: { index: '/', page404: '/404', wildCard: '/*' },
  wildCard: '/*',
};

export default routeStrings;
