import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_TEAMS, GET_TEAMS } from './teamSlice';
import API_URLS from 'apiUrls';
import axios from 'axios';

const useTeams = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState([]);
  const teamsInfo = useSelector(GET_TEAMS);

  const apiCall = async () => {
    try {
      setLoading(true);
      if(teamsInfo.TEAMS.length > 0){
        setTeams(teamsInfo.TEAMS);
        setLoading(false);
      }

      const response = await axios.get(API_URLS.teams).then((res) => {
        const result = res.data;
        setTeams(result);
        dispatch(SET_TEAMS({ TEAMS: result }));
        setLoading(false);
      });
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    apiCall();
  }, []);

  return { loading, teams };
};

export default useTeams;
