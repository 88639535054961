import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GET_MATCHES_INFO } from 'state/slices/matches';
import { getMatches, getMatchStats, getMatchFacts, getCurrentSeason, getRounds, getSeasons } from '../functions';

const useMuiTab = () => {
  const { MATCH_STATUS_FILTERS } = useSelector(GET_MATCHES_INFO);
  const [value, setValue] = useState(MATCH_STATUS_FILTERS[0].value);
  const [Season, setSeason] = useState([]);
  const [ActiveSeason, setActiveSeason] = useState('');
  const [matches, setMatches] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [Loading2, setLoading2] = useState(false);
  const [Rounds, setRounds] = useState([]);
  const [ActiveRounds, setActiveRounds] = useState('');
  const [page, setPage] = useState(1);

  useEffect(() => {
    getCurrentSeason().then((res) => {
      if (res.status === 200) {
        setActiveSeason(res.data);
      }
    });
  }, []);

  const apiCall = async () => {
    setLoading(true);
    let data = {
      pageSize: 6,
      page: 0,
    };
    {
      value && Object.assign(data, { status: value });
    }
    {
      ActiveRounds && Object.assign(data, { round: ActiveRounds._id });
    }
    {
      ActiveSeason && Object.assign(data, { season: ActiveSeason.year });
    }
    let tempMatch = [];
    getMatches(data).then(async (res) => {
      if (res.status === 200) {
        let match = res.data;
        for (let i = 0; i < res.data.length; i++) {
          const stats = await getMatchStats(match[i]._id);
          match[i].stats = stats.data;
          if (!(match[i].status === 'nor')) {
            const facts = await getMatchFacts(match[i]._id);
            match[i].facts = facts.data;
          }

          tempMatch.push(match[i]);
        }
        setLoading(false);
      }
    });

    setMatches(tempMatch);
    // console.log('matches', tempMatch);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setLoading(true);
  };
  const handleRefresh = () => {
    setLoading(true);
    setPage(1);
    setActiveRounds('');
    let data = {
      pageSize: 6,
      page: 0,
    };
    {
      value && Object.assign(data, { status: value });
    }
    {
      ActiveRounds && Object.assign(data, { round: ActiveRounds._id });
    }
    {
      ActiveSeason && Object.assign(data, { season: ActiveSeason.year });
    }
    let tempMatch = [];
    getMatches(data).then(async (res) => {
      if (res.status === 200) {
        let matches = res.data;
        for (let i = 0; i < res.data.length; i++) {
          const stats = await getMatchStats(matches[i]._id);
          matches[i].stats = stats.data;
          if (!(matches[i].status === 'nor')) {
            const facts = await getMatchFacts(matches[i]._id);
            matches[i].facts = facts.data;
          }

          tempMatch.push(matches[i]);
        }
        setLoading(false);
      }
    });

    setMatches(tempMatch);
    // console.log('matches', tempMatch);
  };

  const Loadmore = () => {
    setLoading2(true);
    let data = {
      pageSize: 6,
      page,
    };
    {
      value && Object.assign(data, { status: value });
    }
    {
      ActiveRounds && Object.assign(data, { round: ActiveRounds._id });
    }
    {
      ActiveSeason && Object.assign(data, { season: ActiveSeason.year });
    }
    let tempMatch = [];
    getMatches(data).then(async (res) => {
      if (res.status === 200) {
        let match = res.data;
        for (let i = 0; i < res.data.length; i++) {
          const stats = await getMatchStats(match[i]._id);
          match[i].stats = stats.data;
          if (!(match[i].status === 'nor')) {
            const facts = await getMatchFacts(match[i]._id);
            match[i].facts = facts.data;
          }

          tempMatch.push(match[i]);
        }
        let temp = matches.concat(tempMatch);
        console.log('more', tempMatch);
        setMatches(temp);
        setLoading2(false);
      }
    });

    setPage((page) => page + 1);
  };

  useEffect(() => {
    getRounds().then((res) => {
      if (res.status === 200) {
        setRounds(res.data);
      }
    });
    getSeasons().then((res) => {
      if (res.status === 200) {
        setSeason(res.data);
      }
    });

    return () => {};
  }, []);

  useEffect(() => {
    apiCall();

    return () => {};
  }, [value, ActiveRounds, ActiveSeason]);

  return {
    value,
    handleChange,
    Season,
    ActiveSeason,
    setActiveSeason,
    setSeason,
    matches,
    setMatches,
    Loading,
    setLoading,
    Rounds,
    setRounds,
    ActiveRounds,
    setActiveRounds,
    handleRefresh,
    Loadmore,
    Loading2,
  };
};

export default useMuiTab;
