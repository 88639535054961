export const BASEURL_HEROKU = 'https://nbc-api.herokuapp.com';

// export const NEW_BASE_URL = 'https://api-v2.ligi.co.tz';
export const NEW_BASE_URL = 'https://api-soka.nbc.co.tz/';

// export const NEW_BASE_URL = "http://localhost:4000";

const API_URLS = {
  seasons: `${NEW_BASE_URL}/season`,

  rounds: `${NEW_BASE_URL}/round`,

  login: `${NEW_BASE_URL}/admin/login`,

  forgotPassword: `${NEW_BASE_URL}/admin/reset`,

  recoverPassword: `${NEW_BASE_URL}/admin/recover`,

  addMatch: `${NEW_BASE_URL}/match/add`,

  addSeason: `${NEW_BASE_URL}/season/add`,

  addMatchMoment: `${NEW_BASE_URL}/moment/add`,

  addMatchFact: `${NEW_BASE_URL}/moment/add`,

  broadcasters: `${NEW_BASE_URL}/broadcasters`,

  editMatchFact: `${NEW_BASE_URL}/stat/edit`,

  updateMatchStat: `${NEW_BASE_URL}/stat/edit`,

  updateMatchTable: `${NEW_BASE_URL}/stat/edit-goals`,

  matches: `${NEW_BASE_URL}/match`,

  matchesBySeason: `${NEW_BASE_URL}/match/season`,

  matchFacts: `${NEW_BASE_URL}/moment/match`,

  matchMoments: `${NEW_BASE_URL}/fact/match`,

  matchMakeLive: `${NEW_BASE_URL}/match/makeItLive`,

  matchStats: `${NEW_BASE_URL}/stat/match`,

  matchCount: `${NEW_BASE_URL}/match/count`,

  allStats: `${NEW_BASE_URL}/stat`,

  endMatch: `${NEW_BASE_URL}/match/end_match`,

  editMatch: `${NEW_BASE_URL}/match/edit`,

  players: `${NEW_BASE_URL}/player`,

  stadiums: `${NEW_BASE_URL}/stadiums`,

  news: `${NEW_BASE_URL}/news`,

  uploadNews: `${NEW_BASE_URL}/news/create`,

  editNews: `${NEW_BASE_URL}/news/edit`,

  editNewsImage: `${NEW_BASE_URL}/news/edit-image`,

  deleteNews: `${NEW_BASE_URL}/news/delete`,

  teams: `${NEW_BASE_URL}/team`,

  addStadium: `${NEW_BASE_URL}/stadiums/add`,

  currentSeason: `${NEW_BASE_URL}/current_season`,

  addRound: `${NEW_BASE_URL}/round/add`,

  allRounds: `${NEW_BASE_URL}/round`,

  kings: `${NEW_BASE_URL}/kings`,
};

export default API_URLS;
