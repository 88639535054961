import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

function CustomSelect({ data, value, onChange, label = '' }) {
  return (
    <div className="flex gap-2 w-full">
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: '100%' }}>
        <InputLabel id="select-standard-label">{label}</InputLabel>
        <Select labelId="select-standard-label" id="select-standard" value={value} onChange={onChange} label={label}>
          <MenuItem value="">
            {' '}
            <em>None</em>{' '}
          </MenuItem>
          {data.map((datum, index) => (
            <MenuItem key={index} value={datum}>
              {datum}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default CustomSelect;

export function CustomSelectWithObjects({ data, value, onChange, label = '' }) {
  return (
    <div className="flex gap-2 w-full">
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: '100%' }}>
        <InputLabel id="select-standard-label">{label}</InputLabel>
        <Select labelId="select-standard-label" id="select-standard" value={value} onChange={onChange} label={label}>
          <MenuItem value="">
            {' '}
            <em>None</em>{' '}
          </MenuItem>
          {data.map((datum, index) => (
            <MenuItem key={index} value={datum.value}>
              {datum.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export function CustomSelect2({ data, value, onChange, Point, label = '' }) {
  return (
    <div className="flex gap-2 w-full">
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: '100%' }}>
        <InputLabel id="select-standard-label">{label}</InputLabel>
        <Select
          labelId="select-standard-label"
          id="select-standard"
          value={Point === 'num' ? value?.num : value?.year}
          onChange={onChange}
          label={label}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {data.map((datum, index) => (
            <MenuItem key={index} value={datum}>
              {Point === 'num' ? datum.num : datum.year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
