import CustomButtonWithIcon from 'components/CustomButtonWithIcon';
import ModalPopUp from 'components/ModalPopUp';
import SectionLabel from 'components/SectionLabel';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FaTrash } from 'react-icons/fa';
import { IoAddCircleOutline } from 'react-icons/io5';
import { MdOutlineClear } from 'react-icons/md';
import { RiSendPlane2Fill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { GET_USER_INFO } from 'state/slices/user-auth-info';
import AddGroundPopUpForm from './components/AddGroundPopUpForm';
import { deleteStadium, getStadiums } from './functions';

function ListGrounds() {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [grounds, setGrounds] = useState([]);
  const apiCall = async () => {
    setIsLoading(true);
    try {
      setIsError(false);
      const response = await getStadiums();
      setIsLoading(false);
      setGrounds((prev) => {
        prev = response.data;
        return prev;
      });
    } catch (error) {
      console.error('could not get the grounds', error);
      setIsError(true);
      setIsLoading(false);
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [refresher, setRefresher] = useState(0);
  const handleRefresh = () => setRefresher(refresher + 1);
  useEffect(() => {
    apiCall();
  }, [refresher]);

  const [groundToDelete, setGroundToDelete] = useState({ name: '', id: '' });

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const handleOpenDeleteDialog = (groundName, groundId) => {
    setOpenDeleteDialog(true);
    setGroundToDelete((prev) => {
      prev = { name: groundName, id: groundId };
      return prev;
    });
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setGroundToDelete((prev) => {
      prev = { name: '', id: '' };
      return prev;
    });
  };

  return (
    <div className="w-full px-[5%] flex flex-col gap-4 select-none">
      <div className="flex flex-col items-center w-full gap-2 rounded-lg md:flex-row md:justify-between">
        <div className="flex flex-col gap-1">
          <SectionLabel label="Stadiums" />
          <p className="text-sm">
            This is where you can view all registered and available stadiums in the league.
            <br />
            You can also add one by pressing the Add stadium button.
          </p>
        </div>

        <button
          onClick={handleOpen}
          className="flex w-full md:w-[fit-content] items-center justify-center gap-2 px-4 py-3 text-white transition-all rounded-lg hover:gap-4 bg-button hover:bg-buttonHovered"
        >
          <IoAddCircleOutline />
          <p className="text-sm">Add stadium</p>
        </button>
      </div>

      {(() => {
        if (isLoading) return <div className="flex items-center justify-center w-full p-4">loading ...</div>;
        if (isError) {
          return (
            <div className="flex items-center justify-center px-4 py-2 font-bold rounded-lg bg-border ">
              Something went wrong when getting info
            </div>
          );
        }
        return (
          <>
            <div className="flex flex-col w-full gap-3">
              {[...grounds]
                .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
                .map((ground, index) => (
                  <div key={index} className="flex flex-col w-full gap-2">
                    <div className="flex items-center justify-between w-full gap-2 px-4 py-2 rounded-lg bg-border">
                      <div className=" flex gap-3 font-bold w-[fit-content]">
                        <p className="h-[20px] text-sm aspect-square bg-white rounded-full flex items-center justify-center">
                          {index + 1}
                        </p>
                        <p>{ground.name}</p>
                      </div>
                      <div
                        onClick={() => handleOpenDeleteDialog(ground.name, ground._id)}
                        className="h-[40px] aspect-square transition-all hover:cursor-pointer hover:bg-white rounded-lg flex items-center justify-center"
                      >
                        <FaTrash className="text-sm" />
                      </div>
                    </div>
                    {ground.home_teams.length > 0 ? (
                      <div className="flex flex-wrap items-center w-full gap-2">
                        <p className="px-3 py-1 text-xs font-normal rounded-full bg-border">Home teams</p>
                        {ground.home_teams?.map((team, indexx) => {
                          return (
                            <p
                              key={indexx}
                              className="px-3 py-1 text-sm font-bold text-white rounded-full bg-buttonHDisabled"
                            >
                              {team.name}
                            </p>
                          );
                        })}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
            </div>
          </>
        );
      })()}

      <ModalPopUp
        open={open}
        handleClose={handleClose}
        height="fit-content"
        width="95%"
        children={<AddGroundPopUpForm handleClose={handleClose} handleRefresh={handleRefresh} />}
      />

      <ModalPopUp
        open={openDeleteDialog}
        handleClose={handleCloseDeleteDialog}
        height="fit-content"
        width="95%"
        children={
          <AreYouSureDelete
            handleClose={handleCloseDeleteDialog}
            handleRefresh={handleRefresh}
            ground={groundToDelete}
          />
        }
      />
    </div>
  );
}

export default ListGrounds;

const AreYouSureDelete = ({ handleClose, handleRefresh, ground }) => {
  const { TOKEN } = useSelector(GET_USER_INFO);
  const BoldSpan = ({ text }) => <span className="px-2 py-1 font-bold rounded-full bg-border text-[gray]">{text}</span>;
  const [loading, setLoading] = useState(false);
  const handleDelete = async () => {
    setLoading(true);
    try {
      await deleteStadium(TOKEN, ground.id);
      setLoading(false);
      handleClose();
      handleRefresh();
    } catch (error) {
      console.error('can not delete ground', error);
      toast.error('Could not delete ground');
      setLoading(false);
    }
  };
  return (
    <div className="flex flex-col w-full gap-2">
      <p className="text-xl font-bold">
        Are you sure you want to delete <BoldSpan text={ground.name} /> stadium?
      </p>
      <p className="text-sm text-[gray]">
        Confirming this action will delete this stadium and will no longer appear on matches that were assigned this
        stadium as their ground. Be advised that this action is not reversible.
      </p>
      <div className="flex justify-end w-full gap-2">
        <CustomButtonWithIcon Icon={<MdOutlineClear />} text="Abort deletion" onClick={handleClose} />
        <CustomButtonWithIcon
          Icon={<RiSendPlane2Fill />}
          text={loading ? 'Deleting stadium ...' : 'Delete this stadium'}
          plain
          onClick={handleDelete}
        />
      </div>
    </div>
  );
};
