import { useNavigate } from 'react-router-dom';
import routeStrings from 'routeStrings';
import { useEffect, useState } from 'react';
import API_URLS from 'apiUrls';
import axios from 'axios';
import moment from 'moment';
import { NEW_BASE_URL } from 'apiUrls';

function NewsViewing() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [articles, setArticles] = useState([]);

  const handleNewsArticlePreview = (newsId) => navigate(`${routeStrings.newsArticle}/${newsId}`);

  const apiCall = async () => {
    setLoading(true);
    try {
      const response = await axios.get(API_URLS.news).then((res) => {
        const result = res.data;
        setArticles((cur) => [...cur, ...result]);
        setLoading(false);
      });
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    apiCall();
  }, []);

  if (loading) return <p>Loading ...</p>;

  if (articles.length > 0) {
    return (
      <div className="flex flex-col items-center w-full">
        <div className="flex flex-col w-full gap-2 px-5 max-w-[1200px]">
          {((article) => {
            return (
              <div
                onClick={() => handleNewsArticlePreview(article._id)}
                className="flex flex-col w-full gap-2 mb-2 transition-all rounded-lg cursor-pointer hover:bg-border"
              >
                <div className="flex flex-col w-full gap-2 lg:flex-row lg:items-center">
                  <div className="flex flex-col w-full gap-2 lg:flex-row lg:items-center">
                    <img
                      src={`${NEW_BASE_URL}/${article.image_url}`}
                      alt=""
                      className="object-cover w-full h-[400px] md:h-[50vh] lg:h-[60vh] rounded-md"
                    />
                    <div className="flex flex-col w-[fit-content] gap-1 p-5">
                      <div className="flex items-center gap-2 text-sm">
                        <img src={`${NEW_BASE_URL}/${article.image_url}`} alt="" className="h-[30px] aspect-square" />
                        <div className="flex flex-col gap-0 text-xs font-semibold text-[gray]">
                          <p>{article.category}</p>
                          <p>{moment(article.createdAt).format('YYYY/MMM/DD')}</p>
                        </div>
                      </div>
                      <p style={{ lineHeight: 1 }} className="font-bold text-[40px]">
                        {article.head}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })(articles[0])}
          <div className="grid w-full grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {articles.slice(1).map((article, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleNewsArticlePreview(article._id)}
                  className="flex flex-row sm:flex-col items-center w-full h-[fit-content] gap-2 rounded-lg cursor-pointer hover:bg-border transition-all overflow-clip"
                >
                  <img
                    src={`${NEW_BASE_URL}/${article.image_url}`}
                    alt=""
                    className="object-cover h-[150px] aspect-square sm:h-[200px] sm:aspect-auto sm:w-full"
                  />
                  <div className="flex flex-col w-full gap-2 p-4">
                    {/*<div className="flex items-center gap-2 text-sm">
                      <img src={article.image_url} alt="" className="h-[30px] aspect-square" />
                        <div className="flex flex-col gap-0 text-xs font-semibold text-[gray]">
                        <p>{'souurce name'}</p>
                        <p>{'source date'}</p>
                      </div>
                
              </div>*/}
                    <p className="text-xl font-extrabold">{article.title}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default NewsViewing;
