import CreateMatchSchedules from 'features/create-match-schedules'

function Schedules() {
    return (
        <div>
            <CreateMatchSchedules />
        </div>
    )
}

export default Schedules