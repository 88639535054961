import { Tab, Tabs } from '@mui/material';
import { useSelector } from 'react-redux';
import { GET_MATCHES_INFO } from 'state/slices/matches';

const MUITabs = ({ value, handleChange }) => {
  const { MATCH_STATUS_FILTERS } = useSelector(GET_MATCHES_INFO);
  return (
    <div className="w-full">
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        {MATCH_STATUS_FILTERS.map((tab) => (
          <Tab key={tab.id} value={tab.value} label={tab.label} />
        ))}
      </Tabs>
    </div>
  );
};

export default MUITabs;
