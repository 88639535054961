import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
// routes
import { Toaster } from 'react-hot-toast';
import Router from './routes';
// theme
// components
import { GET_USER_INFO } from './state/slices/user-auth-info';
import routeStrings from './routeStrings';

// ----------------------------------------------------------------------
export default function App() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { TOKEN } = useSelector(GET_USER_INFO);
  useEffect(() => {
    if (TOKEN === '' || !TOKEN) {
      navigate(routeStrings.login, { replace: true });
    }
  }, [TOKEN, pathname]);

  return (
    <>
      <Toaster
        position='bottom-center' 
        toastOptions={{
          style: {
            backgroundColor: '#ffffffb3',
            backdropFilter: 'blur(4px)',
            border: '1px solid #CCD6E3',
          },
        }}
      />
      <Router />
    </>
  );
}
