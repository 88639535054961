import NewsArticlePreview from 'features/news-article-preview/NewsArticlePreview';
import React from 'react';

function NewsPreview() {
  return (
    <div>
      <NewsArticlePreview />
    </div>
  );
}

export default NewsPreview;
