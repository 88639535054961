import CustomButtonWithIcon from 'components/CustomButtonWithIcon';
import { useState } from 'react';
import { IoSend } from 'react-icons/io5';

function ChooseImage({ image, setImage, handleClosePopUp }) {
  const [_image, _setImage] = useState(image);

  const handleImagePicker = () => {
    setImage(_image);
    handleClosePopUp();
  };

  return (
    <div className="w-full flex flex-col space-y-2 items-center">
      <p className="text-left self-start capitalize font-bold text-2xl text-[gray]">Choose image</p>
      <div className=" flex-row ">
        <input
          type="file"
          name="myImage"
          onChange={(event) => {
            console.log('image object ', event.target.files[0]);
            _setImage(event.target.files[0]);
          }}
        />
      </div>

      <CustomButtonWithIcon onClick={handleImagePicker} text="Choose image" Icon={<IoSend />} />
    </div>
  );
}

export default ChooseImage;
