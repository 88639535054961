import { useState } from "react";
import toast from "react-hot-toast";
import { getPlayers } from "../../functions";

const useMatchCard = () => {
    const [players, setPlayers] = useState([]);

    const [editStatsPopUpLoading, setEditStatsPopUpLoading] = useState(false);
    const [liveStatsEditPopUpOpen, setLiveStatsEditPopUpOpen] = useState(false);

    const handleOpenStatsEditPopUpOpen = async () => {
        setLiveStatsEditPopUpOpen(true);
        setEditStatsPopUpLoading(true);
        try {
            const response = await getPlayers();
            setPlayers((prev) => {
                prev = response.data;
                return prev;
            });
            setEditStatsPopUpLoading(false);
        } catch (error) {
            console.log('error getting players in edit match popup', error);
            toast.error('Error getting players');
            setEditStatsPopUpLoading(false);
        }
    };
    const handleCloseStatsEditPopUpOpen = () => setLiveStatsEditPopUpOpen(false);

    return {
        liveStatsEditPopUpOpen,
        handleOpenStatsEditPopUpOpen,
        handleCloseStatsEditPopUpOpen,
        editStatsPopUpLoading,
        players,
    };
};

export default useMatchCard