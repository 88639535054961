import { useState, useEffect } from 'react'
import SectionLabel from 'components/SectionLabel'
import { IoAddCircleOutline } from 'react-icons/io5'
import { FaTrash, FaEdit } from 'react-icons/fa'
import { FiMoreHorizontal } from 'react-icons/fi'
import toast from 'react-hot-toast'
import axios from "axios"
import { useSelector } from 'react-redux'
import ModalPopUp from 'components/ModalPopUp'
import './ticket.css'
import CustomButtonWithIcon from 'components/CustomButtonWithIcon'
import { GET_USER_INFO } from 'state/slices/user-auth-info'
import { NEW_BASE_URL } from 'apiUrls'

const Tickets = () =>{
    const [ isLoading, setIsLoading ] = useState(false)
    const [ isMenuOpen, setIsMenuOpen ] = useState(false)
    const [ isError, setIsError ] = useState(false)
    const [ open, setOpen] = useState(false)
    const [ isEditDialogOpened, setIsEditDialogOpened ] = useState(false)
    const [ progress, setProgress] = useState(false)
    const [ editedProvider, setEditableProvider] = useState(null)
    const { TOKEN } = useSelector(GET_USER_INFO)
    const [ name, setName ] = useState("")
    const [ city, setCity] = useState("")
    const [ district, setDistrict] = useState("")
    const [ ward, setWard ] = useState("")
    const [ street, setStreet ] = useState("")
    const [ postalCode, setPostalCode ] = useState("")
    const [ ticketProviders, setTicketProviders ] = useState([])

    
  const getTicketProviders = async() => {
    try { 
      const response = await axios.get(`${NEW_BASE_URL}/ticket_provider`)
      setTicketProviders(response.data)
    } catch(err) {
        toast.error('Error while listing ticket providers')
    } 
  }
 
  const addNewTicketProvider = async() => {

    try { 
      const config = {
        headers: {
          Authorization: `Bearer ${ TOKEN }`,
      }
    }
    
    const body = {
      name:name,
      address:{
        city:city,
        district: district,
        ward:ward,
        street:street,
        postal_code:postalCode   
     }
    }
   
     const response  = await axios.post(`${NEW_BASE_URL}/ticket_provider/add`, body, config)
     if(response.status) {
        setOpen(false)
        getTicketProviders()
        toast.success("New ticket provider added")
     }

     if(!response.status) {
       toast.error("Failed to add ticket provider")
     }
    } catch(err) {}
  }

  const handleOpenDeleteDialog = async (ticketId) => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${ TOKEN }`,
        }
       }
        
        const { _id: ticketId }  = editedProvider
        const response = await axios.delete(`${NEW_BASE_URL}/ticket_provider/delete/${ticketId}`, config)
        if(response.status) {
           getTicketProviders()
           setIsMenuOpen(false)
           toast.success("Ticket deleted successfully")
        }

        if(!response.status) {
           toast.error("Error deleting the ticket")
        }

      } catch(err) {
            console.log(err)
           toast.error("Failed to delete ticket")
      }
  }

  const saveTicketProviderChanges = async() => {

    try { 
      const config = {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
      }
    }
    
    const body = {
      name:name,
      address:{
        city:city,
        district: district,
        ward:ward,
        street:street,
        postal_code:postalCode   
     }
    }

     const { _id } = editedProvider
     const response  = await axios.put(`${NEW_BASE_URL}/ticket_provider/update/${_id}`, body, config)
     if(response.status) {
        setIsEditDialogOpened(false)
        getTicketProviders()
        setIsMenuOpen(false)
        toast.success("Ticket provider saved")
     }

     if(!response.status) {
       toast.error("Failed to save ticket provider")
     }
    } catch(err) {}
  }

  const handleMenuDialog = (providerId) => {
    setEditableProvider(providerId)
    console.log(providerId)
    setIsMenuOpen(true)
    setName(providerId.name)
    setCity(providerId.address.city)
    setDistrict(providerId.address.district)
    setWard(providerId.address.ward)
    setStreet(providerId.address.street)
    setPostalCode(providerId.address.postal_code)
  }

  useEffect(() => {
    getTicketProviders()
  },[])

    return (
        <div className="w-full px-[5%] flex flex-col gap-4 select-none">
        <div className="flex flex-col items-center w-full gap-2 rounded-lg md:flex-row md:justify-between">
          <div className="flex flex-col gap-1">
            <SectionLabel label="Tickets" />
            <p className="text-sm">
              This is where you can view all registered and available ticket providers.
              <br />
              You can also add one by pressing the Add ticket provider button.
            </p>
          </div>
  
          <button onClick={() => setOpen(true)}
            className="flex w-full md:w-[fit-content] items-center justify-center gap-2 px-4 py-3 text-white transition-all rounded-lg hover:gap-4 bg-button hover:bg-buttonHovered">
            <IoAddCircleOutline />
            <p className="text-sm">Add ticket provider</p>
          </button>
        </div>
  
        {(() => {
          if (isLoading) return <div className="flex items-center justify-center w-full p-4">loading ...</div>;
          if (isError) {
            return (
              <div className="flex items-center justify-center px-4 py-2 font-bold rounded-lg bg-border ">
                Something went wrong when getting info
              </div>
            );
          }
          return (
            <>
            <div className="flex flex-col w-full gap-3">
              {[...ticketProviders]
                .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
                .map((provider, index) => (
                  <div key={index} className="flex flex-col w-full gap-2">
                    <div className="flex items-center justify-between w-full gap-2 px-4 py-2 rounded-lg bg-border">
                      <div className=" flex gap-3 font-bold w-[fit-content]">
                        <p className="h-[20px] text-sm aspect-square bg-white rounded-full flex items-center justify-center">
                          {index + 1}
                        </p>
                        <p>{provider.name}</p>
                      </div>
                      <div
                        //onClick={() => handleOpenDeleteDialog(provider._id)}
                         onClick={() => handleMenuDialog(provider)}
                        className="h-[40px] aspect-square transition-all hover:cursor-pointer hover:bg-white rounded-lg flex items-center justify-center">
                        <FiMoreHorizontal className="text-sm" />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            </>
          );
        })()}


    <ModalPopUp
        height="fit-content"
        width="90%"
        open={isMenuOpen}
        handleClose={() => setIsMenuOpen(false)}>
            <SectionLabel label={`Menu`} />
            <div className='menu-bar' onClick={() => setIsEditDialogOpened(true)}>
              <FaEdit  className="menu-bar-icon"/><p>Edit</p> 
            </div>
            <div className='menu-bar' onClick={() => handleOpenDeleteDialog() }>
              <FaTrash  className="menu-bar-icon"/><p>Delete</p> 
            </div>
          </ModalPopUp>  
  
    <ModalPopUp
        open={open}
        handleClose={() => setOpen(false)}
        height="fit-content"
        width="95%">

     <div className="flex flex-col w-full gap-2">
      <SectionLabel label={`Add  ticket provider`} />
      <p className="text-[gray] text-sm">
        Here you add new ticket provider. This information will be used when creating a match schedule denoting where the match
        tickets will be available
      </p>

    <input type="text"  onChange={(e) => setName(e.target.value)}className="input-text" placeholder="Name" />
    <input type="text"  onChange={(e) => setCity(e.target.value)} className="input-text" placeholder="City" />
    <input type="text"  onChange={(e) => setDistrict(e.target.value)}className="input-text" placeholder="District" />
    <input type="text"  onChange={(e) => setWard(e.target.value)}className="input-text" placeholder="Ward" />
    <input type="text"  onChange={(e) => setStreet(e.target.value)}className="input-text" placeholder="Street" />
    <input type="text"  onChange={(e) => setPostalCode(e.target.value)}className="input-text" placeholder="Postal code" />

    <CustomButtonWithIcon
          Icon={  <IoAddCircleOutline />}
          text={ progress ? 'Adding ticket provider ...' : 'Add  ticket provider'}
          plain={ false }
          onClick={()=> addNewTicketProvider()}
        />

    </div>
        </ModalPopUp>

          
    <ModalPopUp
        open={isEditDialogOpened}
        handleClose={() => setIsEditDialogOpened(false)}
        height="fit-content"
        width="95%">

     <div className="flex flex-col w-full gap-2">
      <SectionLabel label={`Edit  ticket provider`} />
      <p className="text-[gray] text-sm">
        Here you can edit ticket provider. This information will be used when creating a match schedule denoting where the match
        tickets will be available
      </p>

    <input type="text" value={name} onChange={(e) => setName(e.target.value)}className="input-text" placeholder="Name" />
    <input type="text" value={city} onChange={(e) => setCity(e.target.value)} className="input-text" placeholder="City" />
    <input type="text" value={district} onChange={(e) => setDistrict(e.target.value)}className="input-text" placeholder="District" />
    <input type="text" value={ward} onChange={(e) => setWard(e.target.value)}className="input-text" placeholder="Ward" />
    <input type="text" value={street} onChange={(e) => setStreet(e.target.value)}className="input-text" placeholder="Street" />
    <input type="text"  value={postalCode} onChange={(e) => setPostalCode(e.target.value)}className="input-text" placeholder="Postal code" />

    <CustomButtonWithIcon
          Icon={  <IoAddCircleOutline />}
          text={ progress ? 'saving ticket provider ...' : 'Save  ticket provider'}
          plain={ false }
          onClick={()=> saveTicketProviderChanges()}
        />

    </div>
        </ModalPopUp>
      </div>
    )
}

export default Tickets