const PopOverDetailRow = ({ JSX, label }) => {
  return (
    <div className="flex w-full items-center gap-2">
      <div className="w-[25px] aspect-square flex items-center justify-center">{JSX}</div>
      <p className="text-xs">{label}</p>
    </div>
  );
};

export default PopOverDetailRow;
