import { makeMatchLive } from 'features/view-all-matches/functions';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import CustomCircularLoader from 'components/CustomCircularLoader';
import MatchCardFulltimeButton from './components/MatchCardFulltimeButton';
import { BsCaretDownFill, BsCheck2Circle } from 'react-icons/bs';
import MatchCardUpcomingButton from './components/MatchCardUpcomingButton';
import MatchCardLiveButton from './components/MatchCardLiveButton';
import { GET_USER_INFO } from 'state/slices/user-auth-info';
import toast from 'react-hot-toast';
import ModalPopUp from 'components/ModalPopUp';
import AddMatchHighlight from './components/AddMatchHighlight';

const MatchCardButtons = ({ status, highlightsUrl, matchId, handleRefresh, statsModalHandleOpen, teams, facts }) => {
  const { TOKEN } = useSelector(GET_USER_INFO);
  const [loading, setLoading] = useState(false);

  // add match highlight
  const [showPopUp, setShowPopUp] = useState(false)
  const handleShow = () => setShowPopUp(true)
  const handleHide = () => setShowPopUp(false)

  const handler = async () => {
    switch (status) {
      case 'nor': {
        setLoading(true);
        try {
          const response = await makeMatchLive(matchId, TOKEN);
          setLoading(false);
          toast.success(`successful operation`);
          handleRefresh();
        } catch (error) {
          console.log(error);
          toast.error(`something went wrong`);
          setLoading(false);
        }
        break;
      }

      case 'fin': {
        handleShow()
        break;
      }

      case 'live': {
        statsModalHandleOpen();
        break;
      }

      default:
        break;
    }
  };

  if (showPopUp) {
    return (
      <ModalPopUp
        open={showPopUp} 
        handleClose={handleHide} 
        children={<AddMatchHighlight homeTeamName={teams.home.name} awayTeamName={teams.away.name} matchId={matchId} handleRefresh={handleRefresh} />} 
        height="fit-content"
        width="95%"
      />
    )
  }

  if (loading) {
    return (
      <div className="items-center justify-center w-full p-5 flec">
        <CustomCircularLoader message="Making match live" />
      </div>
    );
  }

  switch (status) {
    case 'fin':
      return (
        <MatchCardFulltimeButton
          onClick={handler}
          Icon={!highlightsUrl ? <BsCaretDownFill /> : <BsCheck2Circle />}
          label={highlightsUrl ? 'Change added Highlight' : 'Add official highlight'}
          highlightsUrl={highlightsUrl}
        />
      );

    case 'nor':
      return <MatchCardUpcomingButton onClick={handler} Icon={<BsCaretDownFill />} label={'Make match live'} />;

    case 'live':
      return <MatchCardLiveButton handleRefresh={handleRefresh} onClick={handler} Icon={<BsCaretDownFill />} label={'Edit stats'} matchId={matchId} teams={teams} facts={facts} />;

    default:
      return <></>;
  }
};

export default MatchCardButtons;
