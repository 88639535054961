import CustomCircularLoader from 'components/CustomCircularLoader';
import CustomSelect from 'components/CustomSelect';
import SectionLabel from 'components/SectionLabel';
import { IoReloadOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { GET_MATCHES_INFO, SET_SELECTED_ROUND, SET_SELECTED_SEASON } from 'state/slices/matches';
import CustomGrid from '../../components/CustomGrid';
import MatchCard from './components/MatchCard/MatchCard';
import MUITabs from './components/MUITabs';
import useMatches from './hooks/useViewAllMatches';
import useMuiTab from './hooks/useMuiTab';
import { useEffect } from 'react';
import { CustomSelect2 } from 'components/CustomSelect';

function ViewAllMatches() {
  // ------------------ component main hook -----------------------
  // const matchesHook = useMatches();

  const { MATCHES, MAPPED_MATCHES, SEASONS, SELECTED_SEASON, ROUNDS, SELECTED_ROUND } = useSelector(GET_MATCHES_INFO);
  const dispatch = useDispatch();

  // --------------- for the tab swction --------------------------
  const {
    value,
    handleChange,
    Season,
    setSeason,
    matches,
    setMatches,
    Loading,
    setLoading,
    Rounds,
    setRounds,
    ActiveRounds,
    setActiveRounds,
    ActiveSeason,
    setActiveSeason,
    handleRefresh,
    Loadmore,
    Loading2,
  } = useMuiTab();
  const handleSeasonSelectChange = (event) => setActiveSeason(event.target.value);
  const handleRoundSelectChange = (event) => setActiveRounds(event.target.value);

  let rounds = [];
  // let matches = [];
  // if (filteredMappedMatch[0]) {
  //   matches = filteredMappedMatch[0].MATCHES.filter(
  //     (match) => match.season === SELECTED_SEASON && match.round.num === SELECTED_ROUND
  //   );
  //   rounds = filteredMappedMatch[0].ROUNDS;
  // }

  // let round = filteredMappedMatch[0].ROUNDS[0];
  // const roundSetter = () => {
  //   if (!round) {
  //     dispatch(SET_SELECTED_ROUND({ SELECTED_ROUND: filteredMappedMatch[0].ROUNDS[1] }));
  //   }
  //   dispatch(SET_SELECTED_ROUND({ SELECTED_ROUND: filteredMappedMatch[0].ROUNDS[0] }));
  // };

  // useEffect(() => {
  //   roundSetter();
  // }, [value, round]);

  const BoldSpan = ({ text }) => (
    <span className="underline decoration-dotted font-bold lowercase text-[black]">{text}</span>
  );

  return (
    <div className="flex flex-col items-center gap-2 px-4 select-none">
      <div className="self-start">
        <SectionLabel label={`Match Statistics and Facts for ${ActiveSeason?.year || ""}`} />
      </div>
      <p className="text-sm text-[gray] self-start">
        This is where you can edit the stats of ongoning matches, make a match live and(or) set a match to full time.
        <br />
        Use the <BoldSpan text={'Live'} />, <BoldSpan text={'Fulltime'} /> and <BoldSpan text={'Upcoming'} /> tabs to
        navigate matches as specified in the <BoldSpan text={'Schedules'} /> tab, filtered using the select options (
        <BoldSpan text={'Season'} /> and <BoldSpan text={'Round'} />)
      </p>

      <div className="flex flex-col w-full gap-2 lg:flex-row lg:items-center lg:justify-between">
        <MUITabs value={value} handleChange={handleChange} />
        <div className="flex items-center gap-2">
          <CustomSelect2
            Point="year"
            label="Season"
            data={Season}
            value={ActiveSeason.year}
            onChange={handleSeasonSelectChange}
          />
          <CustomSelect2
            Point="num"
            label="Round"
            data={Rounds}
            value={ActiveRounds.num}
            onChange={handleRoundSelectChange}
          />
        </div>
      </div>

      {/* --------------- refresh matches ------------------ */}
      <button
        onClick={handleRefresh}
        style={{ display: !Loading ? 'flex' : 'none' }}
        className="w-full rounded-lg  p-2 bg-border transition-all hover:text-[black] hover:shadow-lg hover:bg-hightlightAddedHover shadow-sm flex items-center justify-center font-bold text-[gray] gap-2 mb-1"
      >
        <IoReloadOutline />
        <p className="text-sm">Tap to refresh</p>
      </button>

      {Loading ? (
        <div className="flex items-start w-full">
          <CustomCircularLoader message="loading matches" />
        </div>
      ) : (
        <>
          {/* ------------- matches grid view -------------- */}
          {
            <CustomGrid>
              {matches.length < 1 ? (
                <div className="flex items-start w-full">
                  <p className="text-sm font-bold">No matches found</p>
                </div>
              ) : (
                matches.map((match) => (
                  <MatchCard key={match._id} match={match} loading={Loading} handleRefresh={() => handleRefresh} />
                ))
              )}
            </CustomGrid>
          }
          <div class=" my-10">
            <button
              onClick={Loadmore}
              type="button"
              class="py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:outline-none focus:ring-black-700 focus:text-blue-700  inline-flex items-center"
            >
              {Loading2 && (
                <svg
                  aria-hidden="true"
                  role="status"
                  class="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#1C64F2"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="#1C64F2"
                  />
                </svg>
              )}
              Load more...
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default ViewAllMatches;
