import { SET_DATA } from 'pages/News/newsSlice';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import routeStrings from 'routeStrings';

export default function useAddNews() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  const [Name, setName] = useState('');
  const [SourceImageUrl, setSourceImageUrl] = useState('');
  const [HeadLine, setHeadLine] = useState('');
  const [HeadLineImageUrl, setHeadLineImageUrl] = useState('');
  const [category, setCategory] = useState('');

  const handleGoToContentEditor = () => {
    const body = {
      source: {
        name: Name,
        date,
        logo: SourceImageUrl,
      },
      head: HeadLine,
      category: category,
    };
    dispatch(SET_DATA({ ...body }));
    navigate(routeStrings.newsArticleContentCreate);
  };
  return {
    date,
    setDate,
    Name,
    setName,
    SourceImageUrl,
    setSourceImageUrl,
    HeadLine,
    setHeadLine,
    HeadLineImageUrl,
    setHeadLineImageUrl,
    handleGoToContentEditor,
    category,
    setCategory,
  };
}
