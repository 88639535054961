import PropTypes from 'prop-types';

function CustomLabelButton({ label, onClick, end = true, center, start }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`
            w-[fit-content] 
            ${end && 'self-end'} 
            ${center && 'self-center'} 
            ${start && 'self-start'} 
            my-2 text-[#1890FF] font-bold hover:underline text-sm`}
    >
      {label}
    </button>
  );
}

CustomLabelButton.prototype = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CustomLabelButton;
