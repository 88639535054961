import axios from "axios"
import getApiConfig from "helpers/getApiConfig"
import API_URLS from "../../apiUrls"

export const getAllMatches = () => {
    try {
        return axios.get(API_URLS.matches)
    } catch (error) {
        return Promise.resolve()
    }
}

export const getAllMatchesBySeason = (season, limitData) => {
    try {
        const url = `${API_URLS.matchesBySeason}/${season}?${limitData}`
        return axios.get( url )
    } catch (error) {
        return Promise.resolve()
    }
}


export const getMatchStats = (matchId) => {
    try {
        return axios.get(`${API_URLS.matchStats}/${matchId}`)
    } catch (error) {
        return Promise.resolve()
    }
}

export const getMatchFacts = (matchId) => {
    try {
        return axios.get(`${API_URLS.matchFacts}/${matchId}`)
    } catch (error) {
        return Promise.resolve()
    }
}

export const getAllTeams = () => {
    try {
        return axios.get(API_URLS.teams)
    } catch (error) {
        return Promise.resolve()
    }
}

export const getAllRounds = () => {
    try {
        return axios.get(API_URLS.rounds)
    } catch (error) {
        return Promise.resolve()
    }
}

export const getAllGrounds = () => {
    try {
        return axios.get(API_URLS.stadiums)
    } catch (error) {
        return Promise.resolve()
    }
}

export const getAllBroadcasters = () => {
    try {
        return axios.get(API_URLS.broadcasters)
    } catch (error) {
        return Promise.resolve()
    }
}
export const getMatchByStatus = (status) => {
    try {
        return axios.get(`${API_URLS.matches}/status/${status}`)
    } catch (error) {
        return Promise.resolve()
    }
}

export const addMatch = ({ token, body }) => {
    try {
        const config = getApiConfig(token)
        return axios.post(API_URLS.addMatch, body, config)
    } catch (error) {
        return Promise.resolve()
    }
}

export const editMatchSchedule = ({ token, body, matchId }) => {
    try {
        console.log(body)
        const config = getApiConfig(token)
        return axios.put(`${API_URLS.editMatch}/${matchId}`, body, config)
    } catch (error) {
        return Promise.resolve()
    }
}