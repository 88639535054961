import StatRow from './components/StatRow';

const MatchStats = ({ homeTeam, awayTeam, stats }) => {
  const homeStats = stats.find((stat) => stat.team === homeTeam._id);
  const awayStats = stats.find((stat) => stat.team === awayTeam._id);
  const homeColor = homeTeam.team_color;
  const awayColor = awayTeam.team_color;

  const statsList = [
    {
      label: 'possession',
      homeStat: homeStats?.passes,
      awayStat: awayStats?.passes,
      colors: { home: homeColor, away: awayColor },
    },
    {
      label: 'goals',
      homeStat: homeStats?.goals,
      awayStat: awayStats?.goals,
    },
    {
      label: 'shots',
      homeStat: homeStats?.shots,
      awayStat: awayStats?.shots,
    },
    {
      label: 'shots on target',
      homeStat: homeStats?.shots_on_target,
      awayStat: awayStats?.shots_on_target,
    },
    {
      label: 'passes',
      homeStat: homeStats?.passes,
      awayStat: awayStats?.passes,
    },
    {
      label: 'yellow cards',
      homeStat: homeStats?.yellow_cards,
      awayStat: awayStats?.yellow_cards,
    },
    {
      label: 'red cards',
      homeStat: homeStats?.red_cards,
      awayStat: awayStats?.red_cards,
    },
  ];

  return (
    <div className="w-full flex flex-col items-center p-2 rounded-lg bg-[whitesmoke]">
      <p className="uppercase text-[gray] font-bold text-sm">Stats</p>
      <div className="flex flex-col w-full gap-2">
        {statsList.map((statItem, index) => {
          return (
            <StatRow
              key={index}
              label={statItem.label}
              homeStat={statItem.homeStat}
              awayStat={statItem.awayStat}
              colors={statItem.colors ?? null}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MatchStats;
