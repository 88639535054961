import PropTypes from 'prop-types';

function CustomButton({ label, form, disabled = false, Icon, iconRight = false, mt = 0, onClick }) {
    return (
        <button
            disabled={ disabled }
            type={ form ? 'submit' : 'button' }
            onClick={ !form ? onClick : undefined }
            style={ { marginTop: mt } }
            className="flex items-center justify-center space-x-2 w-full bg-[#2F57A3] hover:bg-[#21409A] text-sm transition-all text-white rounded-lg h-[50px]"
        >
            {
                iconRight
                    ? (
                        <>
                            <p>{ label }</p>
                            { Icon }
                        </>)
                    : (
                        <>
                            { Icon }
                            <p>{ label }</p>
                        </>
                    )
            }
        </button>
    );
}

export default CustomButton;

CustomButton.prototype = {
    label: PropTypes.string.isRequired,
    form: PropTypes.bool,
    disabled: PropTypes.bool,
    Icon: PropTypes.node,
};
