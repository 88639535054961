import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import CustomButtonWithIcon from 'components/CustomButtonWithIcon';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { FaCrown } from 'react-icons/fa';
import useKingOfMatch from './useKingOfMatch';

function KingOfMatch({ match, handleClose }) {

const kingHook = useKingOfMatch( match, handleClose )

  if (kingHook.isLoading)return <p>loading...</p>
  console.log(Boolean(kingHook.king))
  console.log("king", kingHook.king)

  
  const isEmpty = Object.keys(kingHook.king).length === 0;
  if (!isEmpty) return <p>{kingHook.king.player.name}<span style={{ fontWeight: 'bolder', float: "right"}}> {kingHook.king.club.nick_name} </span> </p>

  return (
    <FormControl>
      <p className="text-3xl font-bold text-[gray]">Select King of match</p>
      <p className="text-sm text-[gray]">
        Here you are selecting the king of the match, a player who is voted to be the best player on the pitch on match
        day.
      </p>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={kingHook.selectKing}
        onChange={kingHook.handleChange}
        sx={{ display: 'flex', flexDirection: 'row', gap: 2, marginBottom: 2 }}
      >
        <div className="w-full mt-4">
          <p className="text-sm font-bold text-[gray]">Home team</p>
          <div className="grid grid-col-1 lg:grid-cols-2 space-y-2 text-sm w-full">
            {kingHook.homePlayers.map((player, index) => (
              <FormControlLabel
                key={index}
                value={player.name}
                control={<Radio />}
                label={
                  <div className="flex flex-col space-y-1 w-full">
                    <p className="text-sm">{player.name}</p>
                    <p className="px-2 py-0 rounded-full bg-buttonHovered text-white text-center text-xs w-[fit-content] font-bold">
                      {player.position ?? 'N/A'}
                    </p>
                  </div>
                }
              />
            ))}
          </div>
        </div>

        <div className="w-full">
          <p className="text-sm font-bold text-[gray]">Away team</p>
          <div className="grid grid-col-1 lg:grid-cols-2 space-y-2 text-sm w-full">
            {kingHook.awayPlayers.map((player, index) => (
              <FormControlLabel
                key={index}
                value={player.name}
                control={<Radio />}
                label={
                  <div className="flex flex-col space-y-1 w-full">
                    <p className="text-sm">{player.name}</p>
                    <p className="px-2 py-0 rounded-full bg-buttonHovered text-white text-center text-xs w-[fit-content] font-bold">
                      {player.position ?? 'N/A'}
                    </p>
                  </div>
                }
              />
            ))}
          </div>
        </div>
      </RadioGroup>
      <CustomButtonWithIcon onClick={kingHook.handleClick} text={'Set King of Match'} Icon={<FaCrown />} />
    </FormControl>
  );
}

export default KingOfMatch;
