import Popover from '@mui/material/Popover';
import API_URLS from 'apiUrls';
import axios from 'axios';
import CustomButtonWithIcon from 'components/CustomButtonWithIcon';
import CustomCircularLoader from 'components/CustomCircularLoader';
import { CustomDatePicker, CustomTimePicker } from 'components/CustomDateTimePicker';
import CustomLabelButton from 'components/CustomLabelButton';
import noImage from 'assets/noImage.jpg'
import { CustomSelectWithObjects } from 'components/CustomSelect';
import ModalPopUp from 'components/ModalPopUp';
import getApiConfig from 'helpers/getApiConfig';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { AiOutlineEdit } from 'react-icons/ai';
import { BiChevronDown } from 'react-icons/bi';
import { BsArrowRight, BsCalendarRange, BsCaretDownFill, BsCheck2Circle } from 'react-icons/bs';
import { FiArrowRight } from 'react-icons/fi';
import { GiGoalKeeper } from 'react-icons/gi';
import { IoMdFootball } from 'react-icons/io';
import { MdClear, MdOutlineClear, MdOutlineDeleteOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MATCHES, SET_ROUNDS, SET_SEASONS, SET_SELECTED_ROUND, SET_SELECTED_SEASON } from 'state/slices/matches';
import { GET_USER_INFO } from 'state/slices/user-auth-info';
import moment from 'moment';
import { editMatchSchedule, getAllBroadcasters, getAllGrounds } from '../functions';

function ScheduleMatchCard({ match, matchesHook }) {
    const { anchorEl, handleClick, handleClose, open, id, arrowDown } = usePopOver();
    const {
        home_team: homeTeam,
        away_team: awayTeam,
        playing_time: playingTime,
        playing_date: playingDate,
        round: { num },
        ground,
        broadcasters,
        groundInfo,
        ticket_providers,
        _id,
    } = match;

    const dateOptions = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
    const matchDate = new Date(moment(playingDate).format('YYYY/MM/DD')).toLocaleDateString(
        'en-us',
        dateOptions
    );

    // for deleting a match
    const [deleteMatchPopUp, setDeleteMatchPopUp] = useState(false);
    const handleOpenPopUp = () => setDeleteMatchPopUp(true);
    const handleClosePopUp = () => setDeleteMatchPopUp(false);

    const { TOKEN } = useSelector(GET_USER_INFO);
    const [deleting, setDeleting] = useState(false);
    const dispatch = useDispatch();

    const deleteMatch = async (matchId) => {
        setDeleting(true);
        try {
            await axios.delete(`${API_URLS.matches}/delete/${matchId}`, getApiConfig(TOKEN));
            toast.success('Match deleted successfully');
            setDeleting(false);

            // ----------------- clearing out matches, seasons and rounds store states -----------------
            dispatch(SET_MATCHES({ MATCHES: [] }));
            dispatch(SET_SEASONS({ SEASONS: [] }));
            dispatch(SET_SELECTED_SEASON({ SELECTED_SEASON: '' }));
            dispatch(SET_ROUNDS({ ROUNDS: [] }));
            dispatch(SET_SELECTED_ROUND({ SELECTED_ROUND: '' }));

            // trigger schedule data refetching
            matchesHook.setRefresh((prev) => prev + 1);
        } catch (error) {
            console.error('error deleting match');
            toast.error('Could not delete match');
            setDeleting(false);
        }
    };

    // for edit match
    const [showEditPopUp, setShowEditPopUp] = useState(false)
    const handleShowEdit = () => setShowEditPopUp(true)
    const handleHideEdit = () => setShowEditPopUp(false)

    return (
        <div className="min-h-[180px] select-none border border-border shadow-lg bg-[#FBFBFD] grow flex flex-col justify-center gap-2 rounded-lg relative">
            {deleting ? (
                <CustomCircularLoader message="Deleting match" />
            ) : (
                <>
                    {/* ------------ delete match ----------------- */}
                    <div
                        onClick={handleOpenPopUp}
                        className="flex items-center justify-center gap-2 px-3 py-1 cursor-pointer h-[30px] aspect-square rounded-lg absolute right-2 top-2 bg-border hover:bg-[tomato] hover:text-white transition-all"
                    >
                        <p className='text-sm font-bold'>Del</p>
                        <MdOutlineDeleteOutline className="text-xl" />
                    </div>

                    {/* ------------ update match ----------------- */}
                    <div
                        onClick={handleShowEdit}
                        className="flex items-center justify-center gap-2 px-3 py-1 group cursor-pointer h-[30px] aspect-square rounded-lg absolute left-2 top-2 bg-border hover:bg-buttonHovered hover:text-white transition-all"
                    >
                        <p className='text-sm font-bold'>Edit</p>
                        <AiOutlineEdit className="text-xl" />
                    </div>

                    {/* edit match popup */}
                    <ModalPopUp
                        open={showEditPopUp}
                        handleClose={handleHideEdit}
                        height="fit-content"
                        width="95%"
                        children={
                            <EditMatchSchedulePopUp
                                matchDate={new Date(matchDate)}
                                playingTime={playingTime}
                                ground={ground}
                                handleHideEdit={handleHideEdit}
                                match={match}
                                ticket_providers={ticket_providers}
                                broadcasters={broadcasters}
                                handleRefresh={matchesHook.handleRefresh} />
                        }
                    />

                    {/* ------------- are you sure you want to delete match popup ----------------- */}
                    <AreYouSure
                        open={deleteMatchPopUp}
                        handleClose={handleClosePopUp}
                        homeTeam={homeTeam?.name}
                        awayTeam={awayTeam?.name}
                        cancel={handleClosePopUp}
                        confirm={() => deleteMatch(_id)}
                    />

                    <p className="mt-10 text-sm font-bold text-center">{matchDate}</p>
                    <div className="flex items-center w-full p-2">
                        <TeamSideDisplay name={homeTeam?.nick_name ?? ''} logo={homeTeam.logo ?? noImage} home />
                        <p className="mx-1 font-bold">VS</p>
                        <TeamSideDisplay name={awayTeam?.nick_name ?? ''} logo={awayTeam.logo ?? noImage} />
                    </div>

                    <div className="flex items-center justify-between w-full p-2">
                        <p className="text-sm">
                            Kickoff at <span className="font-bold">{playingTime}</span>
                        </p>

                        <CustomLabelButton
                            label={
                                <div className="flex items-center gap-1">
                                    <p>Match details</p>
                                    <BiChevronDown className={`text-xl ${arrowDown ? 'rotate-0' : 'rotate-180'} transition-all`} />
                                </div>
                            }
                            onClick={handleClick}
                        />

                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        >
                            <div className="bg-white min-w-[250px] p-2 flex flex-col gap-2">
                                <PopOverDetailRow JSX={<BsCalendarRange className="h-[70%] w-[70%]" />} label={matchDate} />

                                <PopOverDetailRow
                                    JSX={
                                        <img
                                            src="https://tff.or.tz/wp-content/uploads/2021/10/NBC-Premier-League-Logo-pdf.jpg"
                                            alt="nbc-pl logo"
                                            className="object-contain w-full h-full"
                                        />
                                    }
                                    label={`NBC Premier League - Round ${num}`}
                                />

                                <PopOverDetailRow
                                    JSX={<GiGoalKeeper className="h-[70%] w-[70%]" />}
                                    label={ground || ground !== '' ? groundInfo?.name ?? 'n/a' : '...'}
                                />
                            </div>
                        </Popover>
                    </div>
                </>
            )}
        </div>
    );
}

export default ScheduleMatchCard;

// --------------------------- are you sure you want to delete match component ---------------------
const AreYouSure = ({ open, handleClose, homeTeam, awayTeam, cancel, confirm }) => {
    return (
        <div>
            <ModalPopUp
                open={open}
                handleClose={handleClose}
                height="fit-content"
                width="90%"
                children={<AreYouSurePopUpChildren homeTeam={homeTeam} awayTeam={awayTeam} cancel={cancel} confirm={confirm} />}
            />
        </div>
    );
};

const AreYouSurePopUpChildren = ({ homeTeam, awayTeam, cancel, confirm }) => {
    const Bold = ({ text, danger, team }) => (
        <span
            className={`font-bold ${team ? ' bg-border px-2 py-1 rounded-lg' : ''} ${danger ? 'text-[red] underline decoration-dotted' : 'text-[black]'
                }`}
        >
            {text}
        </span>
    );
    const Button = ({ Icon, label = '', onClick = () => { }, danger }) => {
        return (
            <button
                onClick={onClick}
                className={`${danger ? 'bg-button hover:bg-buttonHovered text-white' : 'border border-border hover:bg-border'
                    } hover:shadow-lg transition-all px-4 py-1 text-sm font-bold rounded-lg flex items-center justify-center gap-2`}
            >
                <p>{label}</p>
                {Icon}
            </button>
        );
    };
    return (
        <div className="flex flex-col w-full gap-3">
            <div className="flex flex-col w-full gap-2">
                <p className="text-lg font-bold">
                    Are you sure you want to delete this match between <Bold team text={homeTeam} /> and{' '}
                    <Bold team text={awayTeam} />?
                </p>
                <p className="text-sm">
                    Deleting a match will remove its entry in both <Bold text={'schedule'} /> and <Bold text={'match'} /> tabs.{' '}
                    <Bold text={'Please be advised'} /> that this action is <Bold danger text={'not a reversible'} /> one.
                </p>
            </div>
            <div className="flex flex-col md:flex-row md:justify-end gap-2 w-full lg:w-[70%] self-end">
                <Button danger label="Cancel" Icon={<MdOutlineClear />} onClick={cancel} />
                <Button label="Confirm match delete" Icon={<BsArrowRight />} onClick={confirm} />
            </div>
        </div>
    );
};

// ---------------- popover detail row --------------
const PopOverDetailRow = ({ JSX, label }) => {
    return (
        <div className="flex items-center w-full gap-2">
            <div className="w-[25px] aspect-square flex items-center justify-center">{JSX}</div>
            <p className="text-xs">{label}</p>
        </div>
    );
};

// ------------- popover handler ----------------
const usePopOver = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [arrowDown, setArrowDown] = React.useState(true)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setArrowDown(false)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setArrowDown(true)
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return { anchorEl, handleClick, handleClose, open, id, arrowDown };
};

// ------------team side display --------------
const TeamSideDisplay = ({ name, logo, home = false }) => (
    <div className="flex items-center w-full gap-2 p-2 rounded-lg">
        {home ? (
            <>
                <div className="flex items-center justify-end w-full gap-2">
                    <p className="text-sm capitalize text-right">{name.toLowerCase()}</p>
                    <img src={logo} alt={home ? 'home team' : 'away team'} onError={(error) => error.target.src = 'http://www.barbadosfa.com/images/team-placeholder-logo.e50b9536.png'} className="h-[50px] object-contain rounded-full aspect-square" />
                </div>
            </>
        ) : (
            <>
                <div className="flex items-center justify-start w-full gap-2">
                    <img src={logo} alt={home ? 'home team' : 'away team'} onError={(error) => error.target.src = 'http://www.barbadosfa.com/images/team-placeholder-logo.e50b9536.png'} className="h-[50px] object-contain rounded-full aspect-square" />
                    <p className="text-sm capitalize text-left">{name.toLowerCase()}</p>
                </div>
            </>
        )}
    </div>
);

// ------------ time heper function ---------------
const timeFormatter = (time) => {
    try {
        const splittedTime = time.split(':');
        const hours = splittedTime[0];
        const minutes = splittedTime[1];

        let formattedMinutes;
        if (minutes.length > 1) formattedMinutes = minutes;
        else formattedMinutes = `0${minutes}`;

        const formattedTime = [hours, formattedMinutes].join(':');
        return formattedTime;
    } catch (error) {
        console.log(error);
        return 'n/a';
    }
};

// ----------------- match card button component -----------------
const MatchCardButtons = ({ status, highlightsUrl }) => {
    switch (status) {
        case 'fin':
            return (
                <MatchCardFulltimeButton
                    Icon={!highlightsUrl ? <BsCaretDownFill /> : <BsCheck2Circle />}
                    label={highlightsUrl ? 'Change added Highlight' : 'Add official highlight'}
                    highlightsUrl={highlightsUrl}
                />
            );

        case 'nor':
            return <MatchCardUpcomingButton Icon={<BsCaretDownFill />} label={'Add Lineup'} />;

        default:
            return <></>;
    }
};

const MatchCardFulltimeButton = ({ label = '', onClick = () => { }, Icon = null, highlightsUrl }) => {
    return (
        <button
            onClick={onClick}
            className={` transition-all w-full rounded-b-lg ${!highlightsUrl ? 'bg-button' : 'bg-hightlightAdded text-[black] font-bold'
                } ${!highlightsUrl ? 'hover:bg-buttonHovered' : 'hover:bg-hightlightAddedHover'
                } disabled:bg-buttonHDisabled text-white text-sm flex items-center justify-center gap-2 h-[50px]`}
        >
            <p>{label}</p>
            {Icon}
        </button>
    );
};

const MatchCardUpcomingButton = ({ label = '', Icon = null, onClick = () => { } }) => {
    return (
        <button
            onClick={onClick}
            className={` transition-all w-full rounded-b-lg bg-button hover:bg-buttonHovered disabled:bg-buttonHDisabled text-white text-sm flex items-center justify-center gap-2 h-[50px]`}
        >
            <p>{label}</p>
            {Icon}
        </button>
    );
};

// ------------------ full time icon --------------------
const FullTime = () => {
    return (
        <div className="relative h-[40px] w-full py-2">
            <div className="h-[1px] absolute top-[50%] left-0 right-0 w-full translate-y-[-50%] bg-border" />
            <div className=" absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] h-[30px] aspect-square flex items-center justify-center rounded-full bg-[#828282] z-10 text-white text-xs font-extrabold">
                <p>FT</p>
            </div>
        </div>
    );
};

// ------------------- match facts ----------------------
const MatchFacts = ({ facts, homeTeam }) => {
    const list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    if (!facts) {
        return <p className="text-xs font-bold text-[gray] text-center">Facts not available</p>;
    }
    let sortedFacts;
    try {
        sortedFacts = [...facts]?.sort((a, b) => (a.time > b.time ? 1 : -1));
    } catch (error) {
        console.error('error', error);
        sortedFacts = [];
    }

    return (
        <div className="w-full flex flex-col p-2 rounded-lg h-[250px] bg-[whitesmoke] overflow-y-scroll scrollbar-thin scrollbar-track-rounded-md scrollbar-thumb-rounded scroll-smooth">
            <p className="uppercase text-[gray] font-bold text-sm">Facts</p>
            <div className="flex flex-col w-full gap-4 p-2 rounded-lg">
                {sortedFacts.map((fact, index) => (
                    <MatchFactItem key={index} fact={fact} isHome={homeTeam._id === fact.team} />
                ))}
            </div>
        </div>
    );
};

const MatchFactItem = ({ fact, isHome }) => {
    const { event, card_type, player, time } = fact;

    if (event) {
        return (
            <>
                {((e) => {
                    switch (e) {
                        case 'goal':
                            return (
                                <div className={`flex items-center gap-2 w-full ${isHome ? 'justify-start' : 'justify-end'}`}>
                                    {isHome ? (
                                        <>
                                            <p className="text-sm w-[30px] text-left">{time ?? '...'}</p>
                                            <div className="w-[30px] flex items-center justify-center">
                                                <IoMdFootball className="text-2xl" />
                                            </div>
                                            <div className="flex flex-col justify-center">
                                                <p className="text-sm">{player ? player?.name : '...'}</p>
                                                {/* <p className="text-xs text-[gray]">Assist by Olekala Mbuyu</p> */}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="flex flex-col justify-center">
                                                <p className="text-sm">{player ? player?.name : '...'}</p>
                                                {/* <p className="text-xs text-[gray]">Assist by Olekala Mbuyu</p> */}
                                            </div>
                                            <div className="w-[30px] flex items-center justify-center">
                                                <IoMdFootball className="text-2xl" />
                                            </div>
                                            <p className="text-sm w-[30px] text-right">{time ?? '...'}</p>
                                        </>
                                    )}
                                </div>
                            );

                        default:
                            return <></>;
                    }
                })(event)}
            </>
        );
    }

    if (card_type) {
        return (
            <>
                {((card) => {
                    switch (card) {
                        case 'red':
                            return (
                                <div className={`flex items-center gap-2 w-full ${isHome ? 'justify-start' : 'justify-end'}`}>
                                    {isHome ? (
                                        <>
                                            <p className="text-sm w-[30px] text-left">{time ?? '...'}</p>
                                            <div className="w-[30px] flex items-center justify-center">
                                                <Card red />
                                            </div>
                                            <div className="flex flex-col justify-center">
                                                <p className="text-sm">{player ? player?.name : 'n/a'}</p>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="flex flex-col justify-center">
                                                <p className="text-sm">{player ? player?.name : 'n/a'}</p>
                                            </div>
                                            <div className="w-[30px] flex items-center justify-center">
                                                <Card red />
                                            </div>
                                            <p className="text-sm w-[30px] text-right">{time ?? '...'}</p>
                                        </>
                                    )}
                                </div>
                            );

                        case 'yellow':
                            return (
                                <div className={`flex items-center gap-2 w-full ${isHome ? 'justify-start' : 'justify-end'}`}>
                                    {isHome ? (
                                        <>
                                            <p className="text-sm w-[30px] text-left">{time ?? '...'}</p>
                                            <div className="w-[30px] flex items-center justify-center">
                                                <Card />
                                            </div>
                                            <div className="flex flex-col justify-center">
                                                <p className="text-sm">{player ? player?.name : 'n/a'}</p>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="flex flex-col justify-center">
                                                <p className="text-sm">{player ? player?.name : 'n/a'}</p>
                                            </div>
                                            <div className="w-[30px] flex items-center justify-center">
                                                <Card />
                                            </div>
                                            <p className="text-sm w-[30px] text-right">{time ?? '...'}</p>
                                        </>
                                    )}
                                </div>
                            );

                        default:
                            return <></>;
                    }
                })(card_type)}
            </>
        );
    }

    return <></>;
};

// ----------------- card component --------------------
const Card = ({ red = false }) => {
    return <div className={`h-[20px] w-[15px] rounded-sm rotate-12 ${red ? 'bg-[tomato]' : 'bg-[orange]'}`} />;
};

// ----------------- match stats ------------------
const MatchStats = ({ homeTeam, awayTeam, stats }) => {
    const homeStats = stats.find((stat) => stat.team === homeTeam._id);
    const awayStats = stats.find((stat) => stat.team === awayTeam._id);
    const homeColor = homeTeam.team_color;
    const awayColor = awayTeam.team_color;

    const statsList = [
        {
            label: 'possession',
            homeStat: homeStats?.passes,
            awayStat: awayStats?.passes,
            colors: { home: homeColor, away: awayColor },
        },
        {
            label: 'goals',
            homeStat: homeStats?.goals,
            awayStat: awayStats?.goals,
        },
        {
            label: 'shots',
            homeStat: homeStats?.shots,
            awayStat: awayStats?.shots,
        },
        {
            label: 'shots on target',
            homeStat: homeStats?.shots_on_target,
            awayStat: awayStats?.shots_on_target,
        },
        {
            label: 'passes',
            homeStat: homeStats?.passes,
            awayStat: awayStats?.passes,
        },
        {
            label: 'yellow cards',
            homeStat: homeStats?.yellow_cards,
            awayStat: awayStats?.yellow_cards,
        },
        {
            label: 'red cards',
            homeStat: homeStats?.red_cards,
            awayStat: awayStats?.red_cards,
        },
    ];

    return (
        <div className="w-full flex flex-col p-2 rounded-lg h-[280px] bg-[whitesmoke] overflow-y-scroll scrollbar-thin scrollbar-track-rounded-md scrollbar-thumb-rounded scroll-smooth">
            <p className="uppercase text-[gray] font-bold text-sm">Stats</p>
            <div className="flex flex-col w-full gap-2">
                {statsList.map((statItem, index) => {
                    return (
                        <StatRow
                            key={index}
                            label={statItem.label}
                            homeStat={statItem.homeStat}
                            awayStat={statItem.awayStat}
                            colors={statItem.colors ?? null}
                        />
                    );
                })}
            </div>
        </div>
    );
};

const StatRow = ({ homeStat, awayStat, label, colors }) => {
    if (label.toLowerCase() === 'possession') {
        const totalPasses = parseInt(homeStat) + parseInt(awayStat);
        const homePossession = Math.round((parseInt(homeStat) / totalPasses) * 100);
        const awayPossession = 100 - homePossession;

        return (
            <div className="flex flex-col items-center w-full gap-2 p-2">
                <p className="font-bold text-xs text-[gray] uppercase">{label}</p>
                <div className="w-full h-[30px] flex gap-1">
                    <PossessionBar possessionStat={homePossession} color={colors.home} isHome />
                    <PossessionBar possessionStat={awayPossession} color={colors.away} />
                </div>
            </div>
        );
    }
    return (
        <div className="flex items-center justify-between w-full gap-3 px-2">
            <p className="text-sm font-bold w-[30px]">{homeStat}</p>
            <p className="font-bold text-xs text-[gray] uppercase">{label}</p>
            <p className="text-sm font-bold w-[30px] text-right">{awayStat}</p>
        </div>
    );
};

const PossessionBar = ({ possessionStat, color, isHome }) => {
    return (
        <div
            style={{
                width: possessionStat && possessionStat !== null ? `${possessionStat}%` : '50%',
                backgroundColor: color ?? 'tomato',
                justifyContent: isHome ? 'flex-start' : 'flex-end',
            }}
            className="flex items-center h-full px-2 rounded-md"
        >
            <p className="text-xs font-bold text-white">{possessionStat ? possessionStat : 50}%</p>
        </div>
    );
};
function EditMatchSchedulePopUp({ matchDate, playingTime, ground, handleHideEdit, match, handleRefresh, broadcasters: currentBroadcasters, ticket_providers }) {
    const Span = ({ text }) => <span className="px-2 py-1 font-bold underline rounded-lg decoration-dotted bg-border">{text}</span>
    const TextWithBG = ({ text }) => <p className='px-2 py-1 text-sm font-bold text-[gray] rounded-lg bg-border w-[fit-content] '>{text}</p>
     
    let newTicketProviders = ticket_providers.map(provider => {
        if(provider._id) {
          return {
              ...provider,
              isChecked:true
          }
        }
        return provider
   })

   let newBroadcasters = currentBroadcasters.map(broadcaster => {
        if(broadcaster._id) {
            return {
                ...broadcaster,
                isChecked:true
            }
        }
        return  broadcaster
   })

    const splitTime = playingTime?.split(":")
    const date = new Date(0, 0, 0, parseInt(splitTime[0]), parseInt(splitTime[1]), 0, 0)
    const [matchTime, setMatchTime] = useState(date)
    const [matchDateForUpdate, setMatchDateForUpdate] = useState(new Date(matchDate))

    const [loading, setLoading] = useState(false)
    const [selectedGround, setSelectedGround] = useState(ground)
    const [grounds, setGrounds] = useState([])

    const [selectedBroadcasters, setSelectedBroadcasters] = useState(newBroadcasters)
    const [broadcasters, setBroadcasters] = useState([])

    const [ selectedTicketProviders, setSelectedTicketProviders] = useState( newTicketProviders)
    const [ ticketProviders, setTicketProviders] = useState([])


    const [showPopUp, setShowPopUp] = useState(false)
    const handleOpen = () => setShowPopUp(true)
    const handleClose = () => setShowPopUp(false)

    const handleChangeBroadcaster = (Broadcaster) => {
        let newBroadcasters = selectedBroadcasters.map(broadcaster => {
          if(broadcaster._id === Broadcaster._id) {
            return {
              ...broadcaster,
              isChecked: broadcaster.isChecked === undefined ? false : !broadcaster.isChecked
            }
          }
          return broadcaster
        })
        setSelectedBroadcasters(newBroadcasters)
      } 

    const handleAddBroadcaster = (Broadcaster) => {
        let addedBroadcasters = broadcasters.map(broadcaster => {
          if(broadcaster._id === Broadcaster._id) {
            return {
              ...broadcaster,
              isChecked: broadcaster.isChecked === undefined ? true : !broadcaster.isChecked
            }
          }
          return broadcaster
        })
        setBroadcasters(addedBroadcasters)
      }   
    
    const getTicketProviders = async() => {
        try { 
          const response = await axios.get('https://api-v2.ligi.co.tz/ticket_provider')
          if(response.statusText === "OK") {
                setTicketProviders(response.data)
          }

          if(!response.statusText === "Ok") {

          }
        } catch(err) {
            toast.error('Error while listing ticket providers')
        } 
    }  

    const handleChangeTicketProvider = (Provider) => {
        let newProviders = selectedTicketProviders.map(provider => {
          if(provider._id === Provider._id) {
            return {
              ...provider,
              isChecked: provider.isChecked === undefined ? false : !provider.isChecked
            }
          }
          return provider
        })
        setSelectedTicketProviders(newProviders)
      } 

    const handleAddTicketProvider = (Provider) => {
        let addedProviders = ticketProviders.map(provider => {
          if(provider._id === Provider._id) {
            return {
              ...provider,
              isChecked: provider.isChecked === undefined ? true : !provider.isChecked
            }
          }
          return provider
        })
        setTicketProviders(addedProviders)
    }   

    const apiCall = async () => {
        setLoading(true)
        getTicketProviders()
        try {
            const promises = [getAllGrounds(), getAllBroadcasters(),]
            const results = await Promise.allSettled(promises)

            setGrounds(prev => { prev = results[0].value.data; return prev })
            setBroadcasters(prev => { prev = results[1].value.data; return prev })

            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    useEffect(() => {
        apiCall()
    }, [])

    const { TOKEN } = useSelector(GET_USER_INFO)

    if (loading) {
        return (
            <div className="flex items-center justify-center w-full">
                <CustomCircularLoader message="please wait" />
            </div>
        )
    }

    return (
        <div className='flex flex-col w-full gap-2'>
            <div className='flex flex-col w-full gap-1 mb-2'>
                <p className='font-bold text-[gray] text-2xl'>Match Schedule update</p>
                <p>Here you are updating the match schedule info.The allowed information to be changed are the <Span text="match date" />, <Span text="match time" />, <Span text="ground" /> and <Span text="broadcast channel" />.</p>
            </div>

            {/* date */}
            <div className='flex flex-col w-full gap-1'>
                <TextWithBG text="Match-date" />
                <CustomDatePicker value={matchDateForUpdate} setter={setMatchDateForUpdate} />
            </div>

            {/* time */}
            <div className='flex flex-col w-full gap-1'>
                <TextWithBG text="Match-time" />
                <CustomTimePicker value={matchTime} setter={setMatchTime} />
            </div>

            {/* grounds */}
            <CustomSelectWithObjects
                value={selectedGround}
                data={(() => {
                    return grounds.map(ground => ({ label: ground.name, value: ground._id }))
                })()}
                onChange={(e) => setSelectedGround(e.target.value)}
                label="Ground"
            />

            {/* broadcasters */}
            <p><strong>Broadcasters</strong></p>
            { selectedBroadcasters.length <= 0 ? null : selectedBroadcasters.map(broadcaster => (
           <div className="player-box" key={broadcaster._id}>
              <input type='checkbox' onChange={() => handleChangeBroadcaster(broadcaster)}
                  checked={ 
                     broadcaster.isChecked === undefined ? true:
                     broadcaster.isChecked ? true : false
                  } />
              <div className="player-title">
                   <h3 style={{ marginTop: 10 }}>{ broadcaster.name }</h3>
                </div>
          </div>
         ))}

        <p><strong> Add Broadcaster</strong></p>
            { broadcasters.length <= 0 ? null : broadcasters.map(broadcaster => (
           <div className="player-box" key={broadcaster._id}>
              <input type='checkbox' onChange={() => handleAddBroadcaster(broadcaster)}
                  checked={ 
                     broadcaster.isChecked === undefined ? false:
                     broadcaster.isChecked ? true : false
                  } />
              <div className="player-title">
                   <h3 style={{ marginTop: 10 }}>{ broadcaster.name }</h3>
                </div>
          </div>
       ))}

      <p><strong>Ticket providers</strong></p>
        { selectedTicketProviders.length <= 0 ? null : selectedTicketProviders.map(provider => (
           <div className="player-box" key={provider._id}>
              <input type='checkbox' onChange={() => handleChangeTicketProvider(provider)}
                  checked={ 
                     provider.isChecked === undefined ? true:
                     provider.isChecked ? true : false
                  } />
              <div className="player-title">
                   <h3>{ provider.name }</h3>
                   <p>{ provider.address.city }</p>
                </div>
          </div>
         ))}

         
      <p><strong>Add providers</strong></p>
        { ticketProviders.length <= 0 ? null : ticketProviders.map(provider => (
           <div className="player-box" key={provider._id}>
              <input type='checkbox' onChange={() => handleAddTicketProvider(provider)}
                  checked={ 
                     provider.isChecked === undefined ? false:
                     provider.isChecked ? true : false
                  } />
              <div className="player-title">
                   <h3>{ provider.name }</h3>
                   <p>{ provider.address.city }</p>
                </div>
          </div>
         ))}

            <CustomButtonWithIcon
                Icon={<FiArrowRight className="transition-all group-hover:mx-2" />}
                disabled={false}
                onClick={handleOpen}
                text="Update match schedule" />

            <ModalPopUp
                open={showPopUp}
                handleClose={handleClose}
                height="fit-content"
                width="fit-content"
                children={
                    <div className='flex flex-col w-full gap-3'>
                        <p className='font-bold'>Are you sure you want to update this match?</p>
                        <p className='text-sm text-[gray] '>The changes made here will be reflected in all areas where this match is referenced.</p>

                        <div className="flex flex-col md:flex-row md:justify-end gap-2 w-full lg:w-[70%] self-end">
                            <CustomButtonWithIcon
                                Icon={<MdClear className="transition-all group-hover:mx-2" />}
                                disabled={false}
                                onClick={handleClose}
                                text="Cancel" />

                            <CustomButtonWithIcon
                                Icon={<FiArrowRight className="transition-all group-hover:mx-2" />}
                                disabled={false}
                                onClick={async () => {
                                    handleClose();

                                    const sortedBroadcasters = []
                                    const sortedAddedBroadcasters = []

                                    selectedBroadcasters.map(broadcaster => {
                                        if(broadcaster.isChecked) {
                                            sortedBroadcasters.push(broadcaster._id)
                                        }
                                     })

                                    broadcasters.map(broadcaster => {
                                        if(broadcaster.isChecked) {
                                            sortedAddedBroadcasters.push(broadcaster._id)
                                        }
                                    }) 

                                    let combinedBroadcaster = sortedAddedBroadcasters.concat(sortedBroadcasters)
                                    let removedDuplicatedBroadcasters = [...new Set(combinedBroadcaster)]


                                    const sortedTicketProviders = []
                                    const sortedAddedTicketProviders = []

                                    selectedTicketProviders.map(provider => {
                                        if(provider.isChecked) {
                                             sortedTicketProviders.push(provider)
                                        }
                                    })

                                    ticketProviders.map(provider => {
                                        if(provider.isChecked) {
                                            sortedAddedTicketProviders.push(provider)
                                        }
                                    })

                                    let combinedTicketProviders = sortedTicketProviders.concat(sortedAddedTicketProviders)
                                    let removedDuplicatedTicketProviders =  [...new Set(combinedTicketProviders)]

                                    const body = {
                                        playing_time: `${new Date(matchTime).getHours()}:${((minute) => {
                                            if (parseInt(minute) < 10) return `0${minute}`;
                                            return minute;
                                        })(new Date(matchTime).getMinutes())}`,
                                        playing_date: matchDateForUpdate,
                                        ground: selectedGround,
                                        broadcasters:removedDuplicatedBroadcasters,
                                        ticket_providers:removedDuplicatedTicketProviders
                                    }

                                    setLoading(true)
                                    try {
                                        await editMatchSchedule({ token: TOKEN, body, matchId: match._id })
                                        handleHideEdit()
                                        setLoading(false)
                                        handleRefresh()
                                    } catch (error) {
                                        console.log("error", error)
                                        setLoading(false)
                                    }
                                    // ...

                                }}
                                text="Proceed to update schedule"
                                plain />
                        </div>
                    </div>
                }
            />
        </div>
    );
}

