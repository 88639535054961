import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    TEAMS: [],
}

export const teamsSlice = createSlice({
    name: 'TEAMS',
    initialState,
    reducers: {
        SET_TEAMS: (state, action) => {
            state.TEAMS = action.payload.TEAMS
        },
    },
})

// Action creators are generated for each case reducer function
export const { SET_TEAMS } = teamsSlice.actions

export const GET_TEAMS = (state) => {
    const { TEAMS } = state.teams
    return { TEAMS }
}

export default teamsSlice.reducer