import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    NAME: "",
    TOKEN: "",
    EMAIL: ""
}

export const userAuthInfoSlice = createSlice({
    name: 'USER-AUTH',
    initialState,
    reducers: {
        SET_USER_INFO: (state, action) => {
            state.NAME = action.payload.NAME
            state.TOKEN = action.payload.TOKEN
            state.EMAIL = action.payload.EMAIL
        },
        DELETE_USER_INFO: (state) => {
            state.NAME = ""
            state.TOKEN = ""
            state.EMAIL = ""
        }
    },
})

// Action creators are generated for each case reducer function
export const { SET_USER_INFO, DELETE_USER_INFO } = userAuthInfoSlice.actions

export const GET_USER_INFO = (state) => {
    const { NAME, TOKEN, EMAIL } = state.userInfo
    return { NAME, TOKEN, EMAIL }
}

export default userAuthInfoSlice.reducer