const CustomButtonWithIcon = ({ Icon, text, onClick, disabled, plain, fullWidth }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={` ${fullWidth && 'w-full flex items-center justify-center gap-2'} 
      ${
        plain
          ? 'text-[black] border-border hover:bg-border hover:shadow-md'
          : 'text-white bg-button hover:bg-buttonHovered'
      } 
      transition-all group flex items-center self-end gap-2 px-4 py-2 text-sm group rounded-lg whitespace-nowrap font-bold disabled:cursor-not-allowed`}
    >
      <p className="transition-all group-hover:mr-3">{text}</p>
      {Icon}
    </button>
  );
};

export default CustomButtonWithIcon;
