const FullTime = () => {
  return (
    <div className="relative h-[40px] w-full py-2">
      <div className="h-[1px] absolute top-[50%] left-0 right-0 w-full translate-y-[-50%] bg-border" />
      <div className=" absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] h-[30px] aspect-square flex items-center justify-center rounded-full bg-[#828282] z-10 text-white text-xs font-extrabold">
        <p>FT</p>
      </div>
    </div>
  );
};

export default FullTime;
