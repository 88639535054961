import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

CustomCircularLoader.prototype = {
    message: PropTypes.string,
    dark: PropTypes.bool,
    mb: PropTypes.number
}

function CustomCircularLoader({ message = "loading", dark, mb = 0 }) {
    return (
        <div style={ { marginBottom: mb } } className='h-[30px] bg-transparent flex items-center justify-center gap-2'>
            <CircularProgress
                size={ 20 }
                className={ `${ dark ? "text-white" : "text-gray-700" }` }
            />
            <p className='text-sm lowercase font-[800]'>{ message } ...</p>
        </div>
    )
}

export default CustomCircularLoader