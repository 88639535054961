import { getAllGrounds } from 'features/create-match-schedules/functions';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import axios from 'axios';
import getApiConfig from 'helpers/getApiConfig';
import API_URLS from '../../../apiUrls';
import { useDispatch } from 'react-redux';
import {
  SET_MATCHES,
  SET_MATCH_FACTS,
  SET_ROUNDS,
  SET_SEASONS,
  SET_SELECTED_ROUND,
  SET_SELECTED_SEASON,
  SET_MAPPED_MATCHES,
} from 'state/slices/matches';
import { getAllMatchesBySeason, getAllStats, getMatchByStatus, getMatchStats, getMatchFacts} from '../functions';

function useMatches() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingStats, setLoadingStats] = useState(false);
  const [loadingFacts, setLoadingFats] = useState(false);

  // getting all matches
  const apiCall = async () => {
    setLoading(true);
    try {
      const promises = [
        getMatchByStatus('nor'),
        getMatchByStatus('fin'),
        getAllGrounds(),
        getAllStats(),
        getMatchByStatus('live')
      ];
      const results = await Promise.allSettled(promises);

      const upcomingMatches = results[0].value.data;
      const upcomingRounds = getRounds(upcomingMatches);

      const previousMatches = results[1].value.data;
      const PreviousRounds = getRounds(previousMatches);

      const liveMatches = results[4].value.data
      const liveMatchesRound = getRounds(liveMatches);

      let someMatch = [];
      setLoadingStats(true)

      let someLiveMatch = []
      for (let i = 0; i < liveMatches.length; i++) {
        const stats = await getMatchStats(liveMatches[i]._id);
        const facts = await getMatchFacts(liveMatches[i]._id);
        liveMatches[i].stats = stats.data;
        liveMatches[i].facts = facts.data;
        someLiveMatch.push(liveMatches[i]);
      }

      for (let i = 0; i < previousMatches.length; i++) {
        const stats = await getMatchStats(previousMatches[i]._id);
        const facts = await getMatchFacts(previousMatches[i]._id);
        previousMatches[i].stats = stats.data;
        previousMatches[i].facts = facts.data;
        previousMatches[i].status = 'fin';
        someMatch.push(previousMatches[i]);
      }
      setLoadingStats(false)

      dispatch(SET_MAPPED_MATCHES({ status: 'nor', MATCHES: upcomingMatches, ROUNDS: upcomingRounds }));
      dispatch(SET_MAPPED_MATCHES({ status: 'fin', MATCHES: someMatch, ROUNDS: PreviousRounds }));
      dispatch(SET_MAPPED_MATCHES({ status: 'live', MATCHES: someLiveMatch, ROUNDS: liveMatchesRound }));
      const seasons = getSeasons(upcomingMatches);
      dispatch(SET_SEASONS({ SEASONS: seasons }));
      dispatch(SET_SELECTED_SEASON({ SELECTED_SEASON: seasons[0] }));
      const rounds = getRounds([...previousMatches, ...upcomingMatches]);
      dispatch(SET_ROUNDS({ ROUNDS: rounds.reverse() }));
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error('something went wrong');
      setLoading(false);
    }
  };

  const getSeasons = (matches) => {
    try {
      const seasonList = new Set();
      matches.forEach((match) => seasonList.add(match.season));
      return [...seasonList];
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const getRounds = (matches) => {
    try {
      const roundList = new Set();
      matches.forEach((match) => {
        const {
          round: { num },
        } = match;
        roundList.add(num);
      });
      return [...roundList];
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  // for refreshing the page contents
  const [refresh, setRefresh] = useState(0);
  const handleRefresh = () => {
    setRefresh(refresh + 1);
    dispatch(SET_MAPPED_MATCHES({ status: 'nor', MATCHES: [], ROUNDS: [] }));
    dispatch(SET_MAPPED_MATCHES({ status: 'fin', MATCHES: [], ROUNDS: [] }));
    dispatch(SET_SEASONS({ SEASONS: [] }));
    dispatch(SET_SELECTED_SEASON({ SELECTED_SEASON: '' }));
  };

  useEffect(() => {
    apiCall();
    return () => {};
  }, [refresh]);

  useEffect(() => {
    apiCall();
    return () => {};
  }, [refresh]);


  return { loading, handleRefresh, loadingStats };
}

export default useMatches;
