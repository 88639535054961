import { useState, useEffect } from 'react'
import SectionLabel from 'components/SectionLabel'
import { IoAddCircleOutline } from 'react-icons/io5'
import { AiOutlineEdit } from 'react-icons/ai'
import { MdOutlineDeleteOutline } from 'react-icons/md'
import toast from 'react-hot-toast'
import axios from "axios"
import { useSelector } from 'react-redux'
import CustomSelect from '../../components/CustomSelect'
import ModalPopUp from 'components/ModalPopUp'
import './rounds.css'
import CustomButtonWithIcon from 'components/CustomButtonWithIcon'
import { GET_USER_INFO } from 'state/slices/user-auth-info'
import { NEW_BASE_URL } from 'apiUrls'

const Rounds = () =>{
    const [ isLoading, setIsLoading ] = useState(false)
    const [ isError, setIsError ] = useState(false)
    const [ open, setOpen] = useState(false)
    const [ isEditDialogOpened, setIsEditDialogOpened ] = useState(false)
    const [ progress, setProgress] = useState(false)
    const [ editedRound, setEditableRound] = useState(null)
    const { TOKEN } = useSelector(GET_USER_INFO)
    const [ num, setNum ] = useState('')
    const [ season, setSeason ] = useState('')
    const [ activeSeason, setActiveSeason ] = useState()
    const [ seasons, setSeasons ] = useState([])
    const [ rounds, setRounds ] = useState([])

  useEffect(() => {
      getSeasons();
  }, []);

  useEffect(() => {
      getRounds();
  }, [activeSeason]);


  const getSeasons = async() => {
    try {
      const response = await axios.get(`${NEW_BASE_URL}/season`)
      setSeasons(response.data.map((e) => e.year))
      setActiveSeason(response.data.find((e) => !!e.current)?.year)
    } catch(err) {
        toast.error('Error while listing seasons')
    }
  }

  const getRounds = async() => {
    let pathname = ''
    if (activeSeason) {
      pathname += `/season/${activeSeason}`
    }
    try {
      const response = await axios.get(`${NEW_BASE_URL}/round` + pathname)
      setRounds(response.data)
    } catch(err) {
        toast.error('Error while listing rounds')
    }
  }

  const addNewRound = async() => {
    if (!/\d+/.test(num)) {
      return toast.error("Invalid round")
    }

    if (!season) {
      return toast.error("Season is required")
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${ TOKEN }`,
      }
    }

    const body = {
      num,
      season,
    }

     const response  = await axios.post(`${NEW_BASE_URL}/round/add`, body, config);
     if(response) {
        setOpen(false)
        getRounds()
        toast.success("New round added")
     }

    } catch(err) {
      if (err.response) {
        toast.error(err.response.data.message)
      }
    }
  }

  const deleteRound = async (roundId) => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${ TOKEN }`,
        }
       }

        const response = await axios.delete(`${NEW_BASE_URL}/round/delete/${roundId}`, config)
        if(response.status) {
           getRounds()
           toast.success("Round deleted successfully")
        }

        if(!response.status) {
           toast.error("Error deleting the round")
        }

      } catch(err) {
            console.log(err)
           toast.error("Failed to delete round")
      }
  }

  const saveRoundChanges = async() => {
    if (!/\d+/.test(num)) {
        return toast.error("Invalid round")
    }

    if (!season) {
      return toast.error("Season is required")
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
      }
    }

    const body = {
      num,
      season,
    }

     const { _id } = editedRound
     const response  = await axios.put(`${NEW_BASE_URL}/round/edit/${_id}`, body, config)
      if(response) {
        setIsEditDialogOpened(false)
        getRounds()
        toast.success("Round saved")
      }

    } catch(err) {
      if (err.response) {
        toast.error(err.response.data.message)
      }
    }
  }

  const handleOpenEditDialog = (round) => {
    setEditableRound(round)
    setIsEditDialogOpened(true)
    setNum(round.num)
    setSeason(round.season)
  }

    return (
        <div className="w-full px-[5%] flex flex-col gap-4 select-none">
        <div className="flex flex-col items-center w-full gap-2 rounded-lg md:flex-row md:justify-between">
          <div className="flex flex-col gap-1">
            <div className="self-start">
              <SectionLabel label={`Rounds for ${activeSeason || ""}`} />
            </div>
            <p className="text-sm">
              This is where you can view all registered and available season rounds.
              <br />
              You can also add one by pressing the Add round button.
            </p>
          </div>

          <button onClick={() => {
            setNum('')
            setSeason('')
            setOpen(true)
          }}
            className="flex w-full md:w-[fit-content] items-center justify-center gap-2 px-4 py-3 text-white transition-all rounded-lg hover:gap-4 bg-button hover:bg-buttonHovered">
            <IoAddCircleOutline />
            <p className="text-sm">Add round</p>
          </button>
        </div>

          <div className="flex flex-col w-full gap-2 lg:flex-row lg:items-center lg:justify-end">
            <div className="flex items-center gap-2 w-full md:max-w-[300px]">
              <CustomSelect
                label="Season"
                data={seasons}
                value={activeSeason || ""}
                onChange={(ev) => setActiveSeason(ev.target.value)}
              />
            </div>
          </div>

        {(() => {
          if (isLoading) return <div className="flex items-center justify-center w-full p-4">loading ...</div>;
          if (isError) {
            return (
              <div className="flex items-center justify-center px-4 py-2 font-bold rounded-lg bg-border ">
                Something went wrong when getting info
              </div>
            );
          }
          return (
            <>
            <div className="grid grid-cols-4 gap-4 w-full">
              {[...rounds]
                .map((round, index) => (
                  <div
                    key={index}
                    className="min-h-[180px] select-none border border-border shadow-lg bg-[#FBFBFD] grow flex flex-col justify-center gap-2 rounded-lg relative"
                  >
                    <div className="space-y-2">
                      <div className="flex flex-row items-center justify-between">
                        <div
                          onClick={() => deleteRound(round._id)}
                          className="flex items-center justify-center gap-2 px-3 py-1 cursor-pointer h-[30px] aspect-square rounded-lg absolute right-2 top-2 bg-border hover:bg-[tomato] hover:text-white transition-all"
                        >
                          <p className='text-sm font-bold'>Del</p>
                          <MdOutlineDeleteOutline className="text-xl" />
                        </div>
                        <div
                          onClick={() => handleOpenEditDialog(round)}
                          className="flex items-center justify-center gap-2 px-3 py-1 group cursor-pointer h-[30px] aspect-square rounded-lg absolute left-2 top-2 bg-border hover:bg-buttonHovered hover:text-white transition-all"
                        >
                          <p className='text-sm font-bold'>Edit</p>
                          <AiOutlineEdit className="text-xl" />
                        </div>
                      </div>
                      <p className="text-center text-lg font-bold">{round.num}</p>
                      <p className="text-center text-sm mt-1">({round.season})</p>
                    </div>
                  </div>
                ))}
            </div>
            </>
          );
        })()}

    <ModalPopUp
        open={open}
        handleClose={() => setOpen(false)}
        height="fit-content"
        width="95%">

     <div className="flex flex-col w-full gap-2">
      <SectionLabel label={`Add  round`} />
      <p className="text-[gray] text-sm">
        Here you add new round. This information will be used when creating a match schedule denoting when the match will be available
      </p>

    <input type="text"  onChange={(e) => setNum(e.target.value)} className="input-text" placeholder="Round" />

       <div className="w-[80%]">
         <CustomSelect
           label="Season"
           data={seasons}
           value={season || ""}
           onChange={(ev) => setSeason(ev.target.value)}
         />
       </div>

    <CustomButtonWithIcon
          Icon={  <IoAddCircleOutline />}
          text={ progress ? 'Adding round ...' : 'Add  round'}
          plain={ false }
          onClick={()=> addNewRound()}
        />

    </div>
        </ModalPopUp>


    <ModalPopUp
        open={isEditDialogOpened}
        handleClose={() => setIsEditDialogOpened(false)}
        height="fit-content"
        width="95%">

     <div className="flex flex-col w-full gap-2">
      <SectionLabel label={`Edit  round`} />
      <p className="text-[gray] text-sm">
        Here you can edit round. This information will be used when creating a match schedule denoting when the match will be available
      </p>

    <input type="text" value={num} onChange={(e) => setNum(e.target.value)} className="input-text" placeholder="Round" />

       <div className="w-[80%]">
         <CustomSelect
           label="Season"
           data={seasons}
           value={season || ""}
           onChange={(ev) => setSeason(ev.target.value)}
         />
       </div>

    <CustomButtonWithIcon
          Icon={  <IoAddCircleOutline />}
          text={ progress ? 'saving round ...' : 'Save round'}
          plain={ false }
          onClick={()=> saveRoundChanges()}
        />

    </div>
        </ModalPopUp>
      </div>
    )
}

export default Rounds
