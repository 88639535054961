import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';

const CustomMultipleSelect = ({ data, setter, value, label }) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setter(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <FormControl sx={{ m: 1, width: '100%' }}>
      <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        value={value}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected) => {
          const filtered = data.filter((dt) => selected.includes(dt.value));
          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {filtered.map(({ value, label }) => (
                <Chip key={value} label={label} />
              ))}
            </Box>
          );
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 48 * 4.5 + 8,
              width: 250,
            },
          },
        }}
      >
        {data.map((dt, index) => (
          <MenuItem key={index} value={dt.value}>
            {dt.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomMultipleSelect;
