import { useSelector } from 'react-redux';
import { Container, Grid } from '@mui/material';
import Page from '../components/Page';
import API_URLS from 'apiUrls';
import { GET_MATCHES_INFO } from 'state/slices/matches';
import { AppWidgetSummary } from '../sections/@dashboard/app';
import { GET_USER_INFO } from '../state/slices/user-auth-info';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { routeStrings } from '../routeStrings'
// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { NAME } = useSelector(GET_USER_INFO);
  const { MAPPED_MATCHES } = useSelector(GET_MATCHES_INFO);
  const  navigate = useNavigate()

  const dashHook = useDashboardHook();

  const check = (index) => {
    return MAPPED_MATCHES[index].MATCHES.length;
  };

  if (dashHook.loading) return <p>Loading ...</p>;
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <p className="text-2xl font-thin mb-5">
          Hello <span className="font-[800] capitalize underline text-[#1890FF]">{NAME},</span> welcome back.
        </p>

        <Grid container spacing={3} onClick={()=> navigate('/dashboard/matches')}>
          <Grid item xs={12} sm={6} md={3} lg={4}>
            <AppWidgetSummary 
            title="Live Matches" 
            total={dashHook.count.live} 
            icon={'ant-design:database-outlined'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={4} onClick={()=> navigate('/dashboard/matches')}>
            <AppWidgetSummary
              title="Fulltime Matches"
              total={dashHook.count.played}
              color="info"
              icon={'ant-design:database-outlined'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={4} onClick={()=> navigate('/dashboard/matches')}>
            <AppWidgetSummary
              title="Upcoming Matches"
              total={dashHook.count.upcoming}
              color="warning"
              icon={'ant-design:database-outlined'}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export function useDashboardHook() {
  const [count, setCount] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await axios.get(API_URLS.matchCount)
        if (response.status === 200){
          setCount(response.data);
          setLoading(false);
        }
        else {
          console.log('error', response.message);
          setLoading(false);
        }
       
      } catch (error) {
        console.log('error', error);
        setLoading(false);
      }
    })();
  }, []);


  return {
    count,
    loading
  };
}
