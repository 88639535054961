import { AiOutlinePlusCircle } from 'react-icons/ai'

function AddMatchButton({ label = "", onClick = () => { } }) {
    return (
        <button
            className="bg-button self-end w-full md:w-[fit-content] flex items-center justify-center gap-2 px-4 py-3 rounded-lg text-sm text-white hover:bg-buttonHovered hover:shadow-md transition-all"
            onClick={ onClick }>
            <AiOutlinePlusCircle />
            <p>{ label }</p>
        </button>
    )
}

export default AddMatchButton