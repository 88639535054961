import CustomCircularLoader from 'components/CustomCircularLoader';
import CustomSelect from 'components/CustomSelect';
import ModalPopUp from 'components/ModalPopUp';
import SectionLabel from 'components/SectionLabel';
import { useState } from 'react';
import { IoReloadOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { GET_MATCHES_INFO, SET_SELECTED_ROUND, SET_SELECTED_SEASON } from 'state/slices/matches';
import CustomGrid from '../../components/CustomGrid';
import AddMatchButton from './components/AddMatchButton';
import AddMatchForm from './components/AddMatchForm';
import ScheduleMatchCard from './components/ScheduleMatchCard';
import useMatches from './hooks/useMatches';
import moment from 'moment';

function CreateMatchSchedules() {
  // ------------------ component main hook -----------------------
  const matchesHook = useMatches();

  const { MATCHES, SEASONS, SELECTED_SEASON, ROUNDS, SELECTED_ROUND } = useSelector(GET_MATCHES_INFO);
  const dispatch = useDispatch();
  const handleSeasonSelectChange = (event) => dispatch(SET_SELECTED_SEASON({ SELECTED_SEASON: event.target.value }));
  const handleRoundSelectChange = (event) => dispatch(SET_SELECTED_ROUND({ SELECTED_ROUND: event.target.value }));

  // --------------- for the tab swction --------------------------
  const filteredMatches = MATCHES.filter(
    (match) => match.season === SELECTED_SEASON && match.round.num === SELECTED_ROUND
  );

  const { open, handleOpen, handleClose } = useModalPopUp();

  const buttonLabel = 'Create Match';

  return (
    <div className="flex flex-col items-center gap-2 px-3">
      <div className="flex flex-col items-center w-full gap-2 rounded-lg md:flex-row md:justify-between">
        <SectionLabel label={`Match Schedules for ${SELECTED_SEASON}`} />
        <AddMatchButton label={buttonLabel} onClick={handleOpen} />
      </div>

      <p className="text-sm text-[gray] self-start">
        This is where you view all match schedules.
        <br />
        You can filter the matches the matche's season and round..
        <br />
        You can also add a match schedule entry from the '{buttonLabel}' button'
      </p>

      {/* -------------- add match popup ----------------- */}
      <ModalPopUp
        height="fit-content"
        width="90%"
        open={open}
        handleClose={handleClose}
        children={<AddMatchForm handleClose={handleClose} setRefresh={matchesHook.setRefresh} />}
      />

      <div className="flex flex-col w-full gap-2 lg:flex-row lg:items-center lg:justify-end">
        <div className="flex items-center gap-2 w-full md:max-w-[300px]">
          <CustomSelect label="Season" data={SEASONS} value={SELECTED_SEASON} onChange={handleSeasonSelectChange} />
          <CustomSelect
            label="Round"
            data={[...ROUNDS].sort((a, b) => a - b)}
            value={SELECTED_ROUND}
            onChange={handleRoundSelectChange}
          />
        </div>
      </div>

      {/* --------------- refresh matches ------------------ */}
      <button
        onClick={matchesHook.handleRefresh}
        style={{ display: !matchesHook.loading ? 'flex' : 'none' }}
        className="w-full rounded-lg  p-2 bg-border transition-all hover:text-[black] hover:shadow-lg hover:bg-hightlightAddedHover shadow-sm flex items-center justify-center font-bold text-[gray] gap-2 mb-1"
      >
        <IoReloadOutline />
        <p className="text-sm">Tap to refresh</p>
      </button>

      {matchesHook.loading ? (
        <div className="flex items-start w-full">
          <CustomCircularLoader message="loading matches" />
        </div>
      ) : (
        <>
          {/* ------------- matches grid view -------------- */}
          {filteredMatches.length === 0 ? (
            <div className="flex items-start w-full">
              <p className="text-sm font-bold">No matches found</p>
            </div>
          ) : (
            <CustomGrid>
              {((filteredMatches) => {
                const _filteredResults = filteredMatches.sort((a, b) => {
                  const matchDateA = new Date( moment(a.playing_date).format('YYYY/MM/DD') );
                  const matchDateB = new Date( moment(b.playing_date).format('YYYY/MM/DD') );
                  return matchDateA - matchDateB;
                });
                return _filteredResults;
              })(filteredMatches).map((match) => (
                <ScheduleMatchCard key={match._id} match={match} matchesHook={matchesHook} />
              ))}
            </CustomGrid>
          )}
        </>
      )}
    </div>
  );
}

export default CreateMatchSchedules;

const useModalPopUp = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return { open, handleOpen, handleClose };
};
