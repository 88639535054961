import Popover from '@mui/material/Popover';
import CustomCircularLoader from 'components/CustomCircularLoader';
import CustomLabelButton from 'components/CustomLabelButton';
import ModalPopUp from 'components/ModalPopUp';
import { useState, useEffect } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { BsCalendarRange, BsFillCollectionPlayFill } from 'react-icons/bs';
import { FaCrown } from 'react-icons/fa';
import { GiGoalKeeper, GiSoccerField } from 'react-icons/gi';
import KingOfMatch from '../KingOfMatch';
import MatchCardButtons from './components/Buttons';
import EditStatsForm from './components/EditStatsForm';
import FullTime from './components/FullTime';
import MatchFacts from './components/MatchFacts';
import noImage from 'assets/noImage.jpg';
import MatchStats from './components/MatchStats';
import PopOverDetailRow from './components/PopOverDetailRow';
import TeamSideDisplay from './components/TeamSideDisplay';
import useMatchCard from './useMatchCard';
import usePopOver from './usePopOver';
import moment from 'moment';
import SectionLabel from 'components/SectionLabel';
import './matchcard.css';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { GET_USER_INFO } from 'state/slices/user-auth-info';
import { useNavigate } from 'react-router-dom';
const positions = [
  'GK',
  'LB',
  'LCB',
  'RCB',
  'RB',
  'CB',
  'LM',
  'RM',
  'CAM',
  'LCM',
  'RCM',
  'CDM',
  'CM',
  'LDM',
  'RDM',
  'LW',
  'RW',
  'LCF',
  'RCF',
  'CF',
];

function MatchCard({ match, loading, handleRefresh }) {
  const { anchorEl, handleClick, handleClose, open, id, arrowDown } = usePopOver();

  const {
    home_team: homeTeam,
    away_team: awayTeam,
    playing_time: playingTime,
    status,
    stats,
    playing_date: playingDate,
    round: { num },
    ground,
    groundInfo,
    highlights_url: highlightsUrl,
    _id,
  } = match;

  const [minuteA, setMinuteA] = useState(0);
  const [minuteB, setMinuteB] = useState(0);
  const [secondA, setSecondA] = useState(0);
  const [secondB, setSecondB] = useState(0);
  const [homeTeamPlayers, setHomeTeamPlayers] = useState([]);
  const [awayTeamPlayers, setAwayTeamPlayers] = useState([]);
  const [homeDefenders, setHomeDefenders] = useState(1);
  const [homeMiddlePlayers, setHomeMiddlePlayers] = useState(1);
  const [homeForwardPlayers, setHomeForwardPlayers] = useState(1);

  const [awayDefenders, setAwayDefenders] = useState(1);
  const [awayMiddlePlayers, setAwayMiddlePlayers] = useState(1);
  const [awayForwardPlayers, setAwayForwardPlayers] = useState(1);
  const navigate = useNavigate();

  const getHomeTeamPlayers = async () => {
    try {
      const { _id } = homeTeam || '';
      const response = await axios.get(`https://api-v2.ligi.co.tz/player?team=${_id}`);
      if (response.statusText === 'OK') {
        setHomeTeamPlayers(response.data);
      }

      if (!response.statusText) {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAwayTeamPlayers = async () => {
    try {
      const { _id } = awayTeam || '';
      const response = await axios.get(`https://api-v2.ligi.co.tz/player?team=${_id}`);
      if (response.statusText === 'OK') {
        setAwayTeamPlayers(response.data);
      }

      if (!response.statusText) {
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (homeTeam === undefined || homeTeam === null) {
    } else {
      getHomeTeamPlayers();
    }

    if (awayTeam === undefined || awayTeam === null) {
    } else {
      getAwayTeamPlayers();
    }
  }, [homeTeam]);

  const statuser = () => {
    if (status === 'live') {
      return `${minuteA}${minuteB}:${secondA}${secondB}`;
    } else if (status === 'fin') {
      return '90:00';
    } else {
      return '-';
    }
  };

  let homeGoals;
  let awayGoals;

  if (status !== 'nor') {
    try {
      homeGoals = stats.length > 0 ? stats.find((statObj) => statObj.team === homeTeam._id).goals : 0;
      awayGoals = stats.length > 0 ? stats.find((statObj) => statObj.team === awayTeam._id).goals : 0;
    } catch (error) {
      console.error('error ocurred while getting goals in upcoming matches', error);
    }
  }

  const dateOptions = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
  const matchDate = new Date(moment(playingDate).format('YYYY/MM/DD')).toLocaleDateString('en-us', dateOptions);

  // for live match popup
  const cardHook = useMatchCard();
  const liveStatsEditPopUpOpen = cardHook.liveStatsEditPopUpOpen;
  const handleOpenStatsEditPopUpOpen = cardHook.handleOpenStatsEditPopUpOpen;
  const handleCloseStatsEditPopUpOpen = cardHook.handleCloseStatsEditPopUpOpen;

  // for king of match popup
  const [openKing, setOpenKing] = useState(false);
  const handleOpenKing = () => setOpenKing(true);
  const handleCloseKing = () => setOpenKing(false);
  const [isLineupOpened, setIsLineupOpened] = useState(false);
  const [isPopUpPositionOpened, setIsPopUpPositionOpened] = useState(false);
  const [isPopUpAwayPositionOpened, setIsPopUpAwayPositionOpened] = useState(false);
  const [activePlayer, setActivePlayer] = useState(null);
  const { TOKEN } = useSelector(GET_USER_INFO);
  const config = {
    headers: {
      Authorization: `Bearer ${TOKEN}`,
    },
  };

  const selectHomePlayer = (player) => {
    setActivePlayer(player);
    setIsPopUpPositionOpened(true);
    const newHomePlayers = homeTeamPlayers.map((Player) => {
      if (Player._id === player._id) {
        return {
          ...Player,
          isChecked: Player.isChecked === undefined ? true : !Player.isChecked,
        };
      }
      return Player;
    });
    setHomeTeamPlayers(newHomePlayers);
  };

  const selectAwayPlayer = (player) => {
    setActivePlayer(player);
    setIsPopUpAwayPositionOpened(true);
    const newAwayPlayers = awayTeamPlayers.map((Player) => {
      if (Player._id === player._id) {
        return {
          ...Player,
          isChecked: Player.isChecked === undefined ? true : !Player.isChecked,
        };
      }
      return Player;
    });
    setAwayTeamPlayers(newAwayPlayers);
  };

  const selectPlayerPosition = (playerPosition) => {
    const newHomePlayers = homeTeamPlayers.map((Player) => {
      if (Player._id === activePlayer._id) {
        return {
          ...Player,
          fieldPosition: playerPosition,
        };
      }
      return Player;
    });
    setHomeTeamPlayers(newHomePlayers);
  };

  const selectAwayPlayerPosition = (playerPosition) => {
    const newAwayPlayers = awayTeamPlayers.map((Player) => {
      if (Player._id === activePlayer._id) {
        return {
          ...Player,
          fieldPosition: playerPosition,
        };
      }
      return Player;
    });
    setAwayTeamPlayers(newAwayPlayers);
  };

  const changePosition = (player) => {
    setActivePlayer(player);
    setIsPopUpPositionOpened(true);
  };

  const changeAwayPosition = (player) => {
    setActivePlayer(player);
    setIsPopUpAwayPositionOpened(true);
  };

  const confirmLineUp = () => {
    try {
      const homeTeamLineUp = [];
      const awayTeamLineUp = [];

      homeTeamPlayers.map((Player) => {
        if (Player.isChecked === true) {
          homeTeamLineUp.push({
            player: Player._id,
            position: Player.fieldPosition,
            kind: 'start',
          });
        }
      });

      awayTeamPlayers.map((Player) => {
        if (Player.isChecked === true) {
          awayTeamLineUp.push({
            player: Player._id,
            position: Player.fieldPosition,
            kind: 'start',
          });
        }
      });

      addHomeLineUp(homeTeamLineUp);
      addAwayLineUp(awayTeamLineUp);
    } catch (err) {
      console.log(err);
    }
  };

  const addHomeLineUp = async (homeTeamLineUp) => {
    try {
      //const { _id:match_id } = match
      const { _id: team_id } = homeTeam;
      const body = {
        match: _id,
        team: team_id,
        players: homeTeamLineUp,
        formation: `${homeDefenders}-${homeMiddlePlayers}-${homeForwardPlayers}`,
      };

      const response = await axios.post(`https://api-v2.ligi.co.tz/lineup/add`, body, config);
      if (response.statusText === 'Created') {
        setIsLineupOpened(false);
        toast.success('Home team Line-up added successfully');
      }

      if (response.statusText !== 'Created') {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.log(err);
      toast.error('Error while adding home line-up');
    }
  };

  const addAwayLineUp = async (awayTeamLineUp) => {
    try {
      const { _id: team_id } = awayTeam;
      const body = {
        match: _id,
        team: team_id,
        players: awayTeamLineUp,
        formation: `${awayDefenders}-${awayMiddlePlayers}-${awayForwardPlayers}`,
      };

      const response = await axios.post(`https://api-v2.ligi.co.tz/lineup/add`, body, config);
      if (response.statusText === 'Created') {
        setIsLineupOpened(false);
        toast.success(' Away team Line-up added successfully');
      }

      if (response.statusText !== 'Created') {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.log(err);
      toast.error('Error while adding home line-up');
    }
  };

  return (
    <div className="border border-border shadow-lg bg-[#FBFBFD] h-[fit-content] flex flex-col gap-2 rounded-lg relative">
      <p className="mt-2 text-sm font-bold text-center">{matchDate}</p>
      <div className="flex items-center w-full p-2">
        <TeamSideDisplay
          name={homeTeam?.nick_name ?? ''}
          logo={homeTeam.logo ?? noImage}
          score={homeGoals ?? ''}
          home
        />
        <p className="mx-2 text-xs ">{statuser()}</p>
        <TeamSideDisplay name={awayTeam?.nick_name ?? ''} logo={awayTeam.logo ?? noImage} score={awayGoals ?? ''} />
      </div>

      {/* ----------------- match hightlight icon -------------------- */}
      <div
        style={{ display: status === 'fin' && highlightsUrl ? 'flex' : 'none' }}
        className=" cursor-pointer absolute right-2 top-2 bg-primary rounded-full h-[25px] aspect-square flex items-center justify-center"
      >
        {highlightsUrl ? (
          <BsFillCollectionPlayFill
            onClick={() => window.open(highlightsUrl, '_blank').focus()}
            className="text-hightlightAdded"
          />
        ) : (
          ''
        )}
      </div>

      {/* ----------------- king of match icon -------------------- */}
      <div
        style={{ display: status === 'fin' && highlightsUrl ? 'flex' : 'none' }}
        className=" cursor-pointer absolute left-2 top-2 bg-border rounded-full h-[25px] aspect-square flex items-center justify-center"
      >
        {highlightsUrl ? <FaCrown onClick={handleOpenKing} className="text-hightlightAdded" /> : ''}
      </div>

      <ModalPopUp
        open={openKing}
        handleClose={handleCloseKing}
        height="fit-content"
        width="90%"
        children={<KingOfMatch match={match} homeTeam={homeTeam} awayTeam={awayTeam} handleClose={handleCloseKing} />}
      />

      <div className="flex items-center justify-between w-full p-2">
        <p className="text-sm">
          {/* Kickoff at <span className="font-bold">{timeFormatter(playingTime) ?? ''}</span> */}
          Kickoff at <span className="font-bold">{playingTime}</span>
        </p>

        <CustomLabelButton
          label={
            <div className="flex items-center gap-1">
              <p>Match details</p>
              <BiChevronDown className={`text-xl ${arrowDown ? 'rotate-0' : 'rotate-180'} transition-all`} />
            </div>
          }
          onClick={handleClick}
        />

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <div className="bg-white min-w-[250px] p-2 flex flex-col gap-2">
            <PopOverDetailRow JSX={<BsCalendarRange className="h-[70%] w-[70%]" />} label={matchDate} />

            <PopOverDetailRow
              JSX={
                <img
                  src="https://tff.or.tz/wp-content/uploads/2021/10/NBC-Premier-League-Logo-pdf.jpg"
                  alt="nbc-pl logo"
                  className="object-contain w-full h-full"
                />
              }
              label={`NBC Premier League - Round ${num}`}
            />

            <PopOverDetailRow
              JSX={<GiGoalKeeper className="h-[70%] w-[70%]" />}
              label={ground || ground !== '' ? groundInfo?.name ?? 'n/a' : '...'}
            />

            <div id="touchable" onClick={() => setIsLineupOpened(true)}>
              <PopOverDetailRow JSX={<AiOutlineUsergroupAdd className="h-[70%] w-[70%]" />} label={'Add line-up'} />
            </div>

            <div
              id="touchable"
              onClick={() =>
                navigate('/dashboard/lineup', {
                  state: {
                    match: match,
                    homeTeam: homeTeam,
                    awayTeam: awayTeam,
                  },
                })
              }
            >
              <PopOverDetailRow JSX={<GiSoccerField className="h-[70%] w-[70%]" />} label={'Line up'} />
            </div>
          </div>
        </Popover>
      </div>

      {/* ------------------ full time section ------------------- */}
      {status === 'fin' && <FullTime />}

      <div
        className={`w-full ${
          status === 'nor' ? 'h-[fit-content]' : 'h-[400px]'
        } flex flex-col gap-4 px-2 rounded-lg overflow-clip overflow-y-scroll scrollbar-thin scrollbar-track-rounded-md scrollbar-thumb-rounded scroll-smooth`}
      >
        {/* ------------------ match stats section ------------------- */}
        {status !== 'nor' ? (
          loading ? (
            <CustomCircularLoader mb={20} message="getting stats" />
          ) : stats && stats.length > 0 ? (
            <MatchStats homeTeam={homeTeam} awayTeam={awayTeam} stats={stats} />
          ) : (
            <p className="text-sm font-bold">No stats found</p>
          )
        ) : (
          <></>
        )}

        {/* ------------------ match facts section ------------------- */}
        {status !== 'nor' ? (
          loading ? (
            <CustomCircularLoader mb={20} message="getting facts" />
          ) : match.facts && match.facts.length > 0 ? (
            <MatchFacts facts={match.facts} homeTeam={homeTeam} />
          ) : (
            <p className="text-sm font-bold">No Facts found</p>
          )
        ) : (
          <></>
        )}
      </div>

      {/* ------------------ button section -------------------- */}
      <MatchCardButtons
        status={status}
        highlightsUrl={highlightsUrl}
        matchId={_id}
        handleRefresh={handleRefresh}
        statsModalHandleOpen={handleOpenStatsEditPopUpOpen}
        teams={{ home: homeTeam, away: awayTeam }}
        facts={match.facts && match.facts}
      />

      {/* --------------- live match stats update popup --------------- */}
      <ModalPopUp
        open={liveStatsEditPopUpOpen}
        handleClose={handleCloseStatsEditPopUpOpen}
        children={
          <EditStatsForm
            match={match}
            loading={cardHook.editStatsPopUpLoading}
            players={cardHook.players}
            handleRefresh={handleRefresh}
          />
        }
        height="fit-content"
        maxWidth="90%"
      />

      <ModalPopUp open={isLineupOpened} handleClose={() => setIsLineupOpened(false)} height="fit-content" width="100%">
        <SectionLabel label={`Line up`} />
        <p className="text-[gray] text-sm">Here you can add team line up</p>
        <div className="two-grid">
          <div className="left-grid">
            <h2>{homeTeam === undefined ? '' : homeTeam.name}</h2>

            {homeTeamPlayers.length <= 0
              ? null
              : homeTeamPlayers.map((player) => (
                  <div className="player-box" key={player._id}>
                    <input
                      type="checkbox"
                      onChange={() => selectHomePlayer(player)}
                      checked={player.isChecked === undefined ? false : player.isChecked ? true : false}
                    />
                    <div className="player-title">
                      <h3>{player.name}</h3>
                      <p>{player.country}</p>
                    </div>
                    <div className="player-box-far" onClick={() => changePosition(player)}>
                      <p>{player.fieldPosition === undefined ? null : player.fieldPosition}</p>
                    </div>
                  </div>
                ))}
            <h4>Formation</h4>
            <div className="team-formation">
              <select onChange={(e) => setHomeDefenders(e.target.value)}>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
              </select>

              <select onChange={(e) => setHomeMiddlePlayers(e.target.value)}>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
              </select>

              <select onChange={(e) => setHomeForwardPlayers(e.target.value)}>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
              </select>
            </div>
          </div>

          <div className="right-grid">
            <h2>{awayTeam === undefined ? '' : awayTeam.name}</h2>
            {awayTeamPlayers.length <= 0
              ? null
              : awayTeamPlayers.map((player) => (
                  <div className="player-box" key={player._id}>
                    <input
                      type="checkbox"
                      onChange={() => selectAwayPlayer(player)}
                      checked={player.isChecked === undefined ? false : player.isChecked ? true : false}
                    />
                    <div className="player-title">
                      <h3>{player.name}</h3>
                      <p>{player.country}</p>
                    </div>
                    <div className="player-box-far" onClick={() => changeAwayPosition(player)}>
                      <p>{player.fieldPosition === undefined ? null : player.fieldPosition}</p>
                    </div>
                  </div>
                ))}

            <h4>Formation</h4>
            <div className="team-formation">
              <select onChange={(e) => setAwayDefenders(e.target.value)}>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
              </select>

              <select onChange={(e) => setAwayMiddlePlayers(e.target.value)}>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
              </select>

              <select onChange={(e) => setAwayForwardPlayers(e.target.value)}>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
              </select>
            </div>
          </div>
        </div>
        <button className="confirm-position" onClick={() => confirmLineUp()}>
          Confirm line-ups
        </button>
      </ModalPopUp>

      <ModalPopUp
        open={isPopUpAwayPositionOpened}
        handleClose={() => setIsPopUpAwayPositionOpened(false)}
        height="fit-content"
        width="100%"
      >
        <SectionLabel label={`Select position`} />
        <select className="form-select-positions" onChange={(e) => selectAwayPlayerPosition(e.target.value)}>
          <option value="Position">Position</option>
          {positions.map((position, index) => (
            <option value={position} key={index}>
              {position}
            </option>
          ))}
        </select>
        <button className="confirm-position" onClick={() => setIsPopUpAwayPositionOpened(false)}>
          confirm-position
        </button>
      </ModalPopUp>

      <ModalPopUp
        open={isPopUpPositionOpened}
        handleClose={() => setIsPopUpPositionOpened(false)}
        height="fit-content"
        width="100%"
      >
        <SectionLabel label={`Select position`} />
        <select className="form-select-positions" onChange={(e) => selectPlayerPosition(e.target.value)}>
          <option value="Position">Position</option>
          {positions.map((position, index) => (
            <option value={position} key={index}>
              {position}
            </option>
          ))}
        </select>
        <button className="confirm-position" onClick={() => setIsPopUpPositionOpened(false)}>
          confirm-position
        </button>
      </ModalPopUp>
    </div>
  );
}

export default MatchCard;
