import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
import tff_logo from '../assets/images/tff-logo.png'

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {

  const LOGO_URL = tff_logo

  const logo = <Box component="img" src={ LOGO_URL } sx={ { height: 100, ...sx, alignSelf: 'center', margin: '0 auto' } } />

  if (disabledLink) {
    return <>{ logo }</>;
  }

  return <RouterLink to="/">{ logo }</RouterLink>;
}
