import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import {
  SET_MATCHES,
  SET_MATCH_FACTS,
  SET_ROUNDS,
  SET_SEASONS,
  SET_SELECTED_ROUND,
  SET_SELECTED_SEASON,
} from 'state/slices/matches';
import { getAllGrounds, getAllMatches, getMatchByStatus, getAllMatchesBySeason, getMatchFacts, getMatchStats } from '../functions';

function useMatches() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingStats, setLoadingStats] = useState(false);
  const [loadingFacts, setLoadingFacts] = useState(false);

  // getting all matches
  const apiCall = async () => {
    setLoading(true);
    try {
      const limitData = 2
      const promises = [getMatchByStatus('nor'), getAllGrounds()];
      const results = await Promise.allSettled(promises);
      const response = results[0].value;

      if (response === null) {
        toast.error('something went wrong');
        return;
      }

      // ---------------- matches that are in live or fulltime status ---------------------
      const matches = response.data;
      const matchesWithStats = matches.filter((match) => match.status !== 'nor');




      // matches stats
      let updatedMatchesWithStats = new Set();
      setLoadingStats(true);
      try {
        const matchStatsResults = await Promise.allSettled(matchesWithStats.map(({ _id }) => getMatchStats(_id)));
        // ------------------ updating the matchs results ready for display -----------------
        matches.forEach((match) => {
          const resultWithStats = matchStatsResults.find((result) => result.value.data[0].match === match._id);
          if (resultWithStats) {
            const {
              value: { data },
            } = resultWithStats;
            const obj = { ...match, stats: data };
            updatedMatchesWithStats.add(obj);
          } else updatedMatchesWithStats.add(match);
        });

        setLoadingStats(false);
      } catch (error) {
        console.log(error);
        setLoadingStats(false);
      }

      // set to array
      updatedMatchesWithStats = [...updatedMatchesWithStats];

      // match facts
      setLoadingFacts(true);
      try {
        const matchFactResults = await Promise.allSettled(matchesWithStats.map(({ _id }) => getMatchFacts(_id)));
        const facts = matchFactResults.map((result) => {
          if (result.value) {
            const {
              value: { data },
            } = result;
            return data;
          }
          return undefined;
        });

        facts.forEach((fact) => {
          try {
            const matchWithFact = updatedMatchesWithStats.find((match) => match._id === fact[0].match);
            const matchIndex = updatedMatchesWithStats.indexOf(matchWithFact);
            updatedMatchesWithStats[matchIndex] = { ...updatedMatchesWithStats[matchIndex], facts: fact };
          } catch (error) {
            console.log(error);
          }
        });

        // ------------------ setting the match facts for display ---------------------
        dispatch(SET_MATCH_FACTS({ FACTS: facts }));

        setLoadingFacts(false);
      } catch (error) {
        console.log(error);
        setLoadingFacts(false);
      }

      // grounds
      const grounds = results[1].value.data;
      updatedMatchesWithStats = [...updatedMatchesWithStats].map((match) => ({
        ...match,
        groundInfo: grounds.find((ground) => ground._id === match.ground),
      }));

      // -------------------- setting the matches info for display ---------------------------------
      dispatch(SET_MATCHES({ MATCHES: updatedMatchesWithStats }));
      const seasons = getSeasons(matches);
      dispatch(SET_SEASONS({ SEASONS: seasons }));
      dispatch(SET_SELECTED_SEASON({ SELECTED_SEASON: seasons[0] }));
      const rounds = getRounds(matches);
      dispatch(SET_ROUNDS({ ROUNDS: rounds }));
      dispatch(SET_SELECTED_ROUND({ SELECTED_ROUND: rounds[0] }));

      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error('something went wrong');
      setLoading(false);
    }
  };

  const getSeasons = (matches) => {
    try {
      const seasonList = new Set();
      matches.forEach((match) => seasonList.add(match.season));
      return [...seasonList];
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const getRounds = (matches) => {
    try {
      const roundList = new Set();
      matches.forEach((match) => {
        const {
          round: { num },
        } = match;
        roundList.add(num);
      });
      return [...roundList];
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  // for refreshing the page contents
  const [refresh, setRefresh] = useState(0);
  const handleRefresh = () => {
    // ----------------- clearing out matches, seasons and rounds store states -----------------
    dispatch(SET_MATCHES({ MATCHES: [] }));
    dispatch(SET_SEASONS({ SEASONS: [] }));
    dispatch(SET_SELECTED_SEASON({ SELECTED_SEASON: '' }));
    dispatch(SET_ROUNDS({ ROUNDS: [] }));
    dispatch(SET_SELECTED_ROUND({ SELECTED_ROUND: '' }));

    // refetching the schedule data
    setRefresh(refresh + 1);
  };

  useEffect(() => {
    apiCall();
    return () => {};
  }, [refresh]);

  return { loading, loadingStats, loadingFacts, handleRefresh, setRefresh };
}

export default useMatches;
