import { useState } from "react";

const usePopOver = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [arrowDown, setArrowDown] = useState(true)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setArrowDown(false)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setArrowDown(true)
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return { anchorEl, handleClick, handleClose, open, id, arrowDown };
};

export default usePopOver