import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Card, Container, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
// components
import Logo from '../components/Logo';
import Page from '../components/Page';
import API_URLS from 'apiUrls';

// sections
import CustomButtonWithIcon from 'components/CustomButtonWithIcon';
import CustomInput from 'components/CustomInput';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { AiOutlineLeft } from 'react-icons/ai';
import { MdSend } from 'react-icons/md';
import { RiMailSendLine } from 'react-icons/ri';
import { LoginForm } from '../sections/auth/login';
import toast from 'react-hot-toast';


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'absolute',
  [theme.breakpoints.up('md')]: {
    alignItems: 'center',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  // forgot password handler
  const [forgot, setForgot] = useState(false);
  const handleForgotPassword = () => setForgot(true);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');

  const handleQuitForgot = () => {
    setForgot(false);
    loginHook.setSendEmail(false);
  };

  const loginHook = useLoginHook();

  const handleforgetPassword = () => {
    const inputData = {email}
    loginHook.apiCall(inputData, "reset");
  };

  const handlePasswordRecovery = () => {
    const inputData = {
      token: code,
      password: password,
      email: email
    }
    loginHook.apiCall(inputData, "recover");
  };

  if (loginHook.loading){
    return <p> Loading...</p>
  }

  return (
    <Page title="Login">
      <RootStyle className="min-h-screen">
        <HeaderStyle className="bg-transparent justify-center md:justify-between px-10 mt-10">
          <Logo />
        </HeaderStyle>

        <SectionStyle className="hidden lg:flex flex-col items-center justify-center w-full ">
          {!loginHook.forgot ? (
            <p className="text-4xl font-[300] text-center">
              Hello there, <br /> <span className="font-[800]">Welcome Back</span>
            </p>
          ) : (
            <p className="text-4xl font-[300] text-center">
              Don't worry, <br /> <span className="font-[800]">Even the best of us forget our password sometimes.</span>
            </p>
          )}
        </SectionStyle>

        <Container className="bg-[#21409A] w-full min-h-screen flex flex-col items-center justify-center">
          <ContentStyle className="bg-white border border-gray-200 h-[fit-content] w-[90%] px-10 rounded-xl shadow-xl">
            {!loginHook.forgot ? (
              <>
                <p className="whitespace-nowrap text-lg lg:text-xl">
                  Sign in to <br /> <span className="font-[800] text-gray-400">NBC-PL TFF DASHBOARD</span>
                </p>
                <Typography sx={{ color: 'text.secondary', mb: 5 }}>Enter your details below.</Typography>
                <LoginForm handleForgotPassword={loginHook.handleForgotPassword} />
              </>
            ) : (
              <div className="w-full flex flex-col space-y-4">
                <button
                  onClick={loginHook.handleQuitForgot}
                  className="flex items-center justify-center gap-1 w-[fit-content] hover:bg-border hover:gap-2 px-2 py-1 rounded-full transition-all"
                >
                  <AiOutlineLeft />
                  <p className="font-bold">Login</p>
                </button>
                {!loginHook.sendEmail ? (
                  <>
                    <p className="text-[gray]">
                      Enter your email address and we'll send you a code to reset your password
                    </p>
                    <CustomInput
                      placeholder="example@mail.com"
                      label="Email"
                      value={email}
                      setter={setEmail}
                      type="email"
                    />
                    <CustomButtonWithIcon
                      onClick={() => handleforgetPassword()}
                      text="Send me the code"
                      Icon={<RiMailSendLine />}
                    />
                  </>
                ) : (
                  <>
                    <p className="text-[gray]">Enter the code we sent to your email and newPassword to reset your password</p>
                    <CustomInput
                      placeholder="*******"
                      label="Password"
                      value={password}
                      setter={setPassword}
                      type="password"
                    />
                      <CustomInput
                      placeholder="xy56g"
                      label="Code"
                      value={code}
                      setter={setCode}
                      type="text"
                    />
                    <CustomButtonWithIcon 
                    text="Reset Password" 
                    Icon={<MdSend />} 
                    onClick={() => handlePasswordRecovery()}

                    />
                  </>
                )}
              </div>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}

export function useLoginHook() {
  const [count, setCount] = useState({});
  const [loading, setLoading] = useState(false);
  const [forgot, setForgot] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);

  const apiCall = async (input, type) => {
    if(type === "reset"){
      const body = input;
      try {
        setLoading(true);
        const response = await axios.post(API_URLS.forgotPassword, body)
        if(response.status === 200){
          setSendEmail(true)
          setLoading(false);
        } else {
          toast.error('something went wrong');
        }
  
      } catch (error) {
  
        console.log('error', error.response.data.message);
        toast.error('something went wrong');
        toast.error(error.response.data.message);
  
        setLoading(false);
      }
    }

    if(type === "recover"){
      const body = input
      try {
        setLoading(true);
        const response = await axios.post(API_URLS.recoverPassword, body)
        if(response.status === 200){
          setForgot(false);

          setLoading(false);
        } else {
          toast.error('something went wrong');
        }
  
      } catch (error) {
        console.log(error)
  
        console.log('error', error.response);
        toast.error('something went wrong');
        toast.error(error.response.data.message);
  
        setLoading(false);
      }
    }
   
  };

  const handleQuitForgot = () => {
    setForgot(false);
    setSendEmail(false);
  };

  const handleForgotPassword = () => {
    setForgot(true);

  }

  return {
    count,
    loading,
    apiCall,
    sendEmail,
    setSendEmail,
    handleQuitForgot,
    forgot,
    setForgot,
    handleForgotPassword
  };
}
