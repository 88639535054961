import Popover from '@mui/material/Popover';
import { useState } from "react";
import { HiOutlineChevronDown } from "react-icons/hi";
import { FaHandPointRight } from "react-icons/fa";
import ModalPopUp from 'components/ModalPopUp';
import CustomInput from 'components/CustomInput';
import { BsChevronRight } from 'react-icons/bs'
import { useSelector } from 'react-redux';
import { GET_USER_INFO } from 'state/slices/user-auth-info';
import getApiConfig from 'helpers/getApiConfig';
import axios from 'axios';
import API_URLS from 'apiUrls';
import toast from 'react-hot-toast';
import CustomCircularLoader from 'components/CustomCircularLoader';
import { MdOutlineClear } from 'react-icons/md';

const MatchCardLiveButton = ({ label = '', Icon = null, onClick = () => { }, matchId, teams, handleRefresh, facts }) => {
  return (
    <div className="w-full p-2 flex gap-2">
      <button
        onClick={onClick}
        className={` transition-all w-full px-4 py-3 rounded-lg bg-button hover:bg-buttonHovered disabled:bg-buttonHDisabled text-white text-sm flex items-center justify-center gap-2`}
      >
        <p>{label}</p>
        {Icon}
      </button>
      <MatchActionsButton matchId={matchId} teams={teams} handleRefresh={handleRefresh} facts={facts} />
    </div>
  );
};

export default MatchCardLiveButton;

const MatchActionsButton = ({ matchId, teams, handleRefresh, facts }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [downArrow, setDownArrow] = useState(true)
  const eventsSet = new Set()
  try {
   if(facts && facts.length > 0){
    facts.forEach(fact => {
      if (fact.event) eventsSet.add(fact.event)
    })
   }
  } catch (error) {
    console.error("no facts", error)
  }
  const halfTimeSet = [...eventsSet].includes("ht")

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setDownArrow(false)
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDownArrow(true)
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const LabelButton = ({ label, onClick, disable }) => (
    <button
      disabled={disable}
      onClick={() => {
        onClick()
        handleClose()
      }}
      className={`hover:font-bold group text-sm flex items-center justify-between gap-2 transition-all hover:bg-border w-full rounded-lg px-4 py-1 ${disable ? 'cursor-not-allowed' : 'cursor-pointer'}`}
    >
      {/* {disable && '❌'} */}
      <p>{label}</p>
      {!disable ? <FaHandPointRight className='w-[15px] group-hover:text-[gray]' /> : <MdOutlineClear className='w-[15px] group-hover:text-[gray] stroke-2' />}
      {/* <FaHandPointRight className='w-[15px] group-hover:text-[gray]' /> */}
    </button>
  )

  // half time
  const [openModal, setOpenModal] = useState(false)
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  // full time
  const [openModalFullTime, setOpenModalFullTime] = useState(false)
  const handleOpenModalFullTime = () => setOpenModalFullTime(true)
  const handleCloseModalFullTime = () => setOpenModalFullTime(false)

  return (
    <>
      <button
        onClick={handleClick}
        className="whitespace-nowrap rounded-lg text-sm font-bold w-full">
        <div className="text-buttonHDisabled flex items-center justify-center gap-2">
          <p className="">Match actions</p>
          <HiOutlineChevronDown className={`${downArrow ? 'rotate-0' : 'rotate-180'} transition-all`} />
        </div>
      </button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="min-w-[250px] p-2 flex flex-col gap-2 ">
          <p className='text-sm font-bold text-[gray] text-center'>Time related</p>
          <div className='w-full p-2 rounded-lg flex flex-col gap-1 bg-[whitesmoke] border border-border'>
            <LabelButton disable={halfTimeSet} label={`Enter half time`.trim()} onClick={handleOpenModal} />
            <LabelButton label="Begin second half" onClick={() => { }} />
            <LabelButton label="End Match" onClick={handleOpenModalFullTime} />
          </div>
        </div>
      </Popover>

      {/* half time */}
      <ModalPopUp
        open={openModal}
        handleClose={handleCloseModal}
        height="fit-content"
        width="95%"
        children={<HalfTimeMinute handleCloseModal={handleCloseModal} matchId={matchId} teams={teams} handleRefresh={handleRefresh} />}
      />

      {/* full time */}
      <ModalPopUp
        open={openModalFullTime}
        handleClose={handleCloseModalFullTime}
        height="fit-content"
        width="95%"
        children={<FullTimeMinute handleCloseModal={handleCloseModal} matchId={matchId} teams={teams} handleRefresh={handleRefresh} />}
      />
    </>
  )
}

const HalfTimeMinute = ({ matchId, handleCloseModal, teams, handleRefresh }) => {
  const [loading, setLoading] = useState(false)

  const [minute, setMinute] = useState(45)
  const { TOKEN } = useSelector(GET_USER_INFO)
  const onClick = async () => {
    const config = getApiConfig(TOKEN)
    setLoading(true)
    try {
      const response = await axios.post(API_URLS.addMatchFact, { match: matchId, event: 'ht', time: minute }, config)
      setLoading(false)
      handleCloseModal()
      handleRefresh()
    } catch (error) {
      console.error("error setting half time fact")
      toast.error("Error setting half time")
      setLoading(false)
      handleCloseModal()
    }
  }

  if (loading) {
    return (
      <div className="w-full flex items-center justify-center">
        <CustomCircularLoader message="setting half time" />
      </div>
    )
  }

  const TeamSpan = ({ text }) => <span className='bg-white px-2 py-1 rounded-lg'>{text}</span>

  return (
    <div className='flex flex-col gap-2'>
      <div className='flex flex-col gap-2 p-2 bg-border rounded-lg'>
        <p className='text-lg font-bold text-[gray]'>Half time for match between <TeamSpan text={teams.home.name} /> and <TeamSpan text={teams.away.name} /></p>
        <p className='text-sm'>Please enter the minute that the game went to half time in the input section below to confirm this match fact setting.</p>
      </div>
      <p className='text-sm'>Half time minute</p>
      <CustomInput type='number' minimum={45} value={minute} setter={setMinute} placeholder="The minimum minute is 45" />
      <button
        onClick={onClick}
        className='group font-bold text-white rounded-lg py-2 px-5 self-end mt-2 w-[fit-content] bg-button hover:bg-buttonHovered flex gap-1 items-center justify-center'>
        <p>Confirm action</p>
        <BsChevronRight className='font-bold text-sm stroke-2 group-hover:ml-1 transition-all' />
      </button>
    </div>
  )
}

const FullTimeMinute = ({ matchId, handleCloseModal, teams, handleRefresh }) => {
  const [loading, setLoading] = useState(false)

  /**
   * if the match has gone to extra time, 
        - then minimum minutes is 120, 
        - otherwise 90
   */
  const [minute, setMinute] = useState(90)

  const { TOKEN } = useSelector(GET_USER_INFO)
  const onClick = async () => {
    const config = getApiConfig(TOKEN)
    setLoading(true)
    try {
      const response = await axios.put(`${API_URLS.endMatch}/${matchId}`, {  }, config)
      setLoading(false)
      handleCloseModal()
      handleRefresh()
    } catch (error) {
      console.error("error setting full time fact")
      toast.error("Error setting full time")
      setLoading(false)
      handleCloseModal()
    }
  }

  if (loading) {
    return (
      <div className="w-full flex items-center justify-center">
        <CustomCircularLoader message="setting half time" />
      </div>
    )
  }

  const TeamSpan = ({ text }) => <span className='bg-white px-2 py-1 rounded-lg'>{text}</span>

  return (
    <div className='flex flex-col gap-2'>
      <div className='flex flex-col gap-2 p-2 bg-border rounded-lg'>
        <p className='text-lg font-bold text-[gray]'>Full time for match between <TeamSpan text={teams.home.name} /> and <TeamSpan text={teams.away.name} /></p>
        <p className='text-sm'>Please enter the minute that the game went to full time in the input section below to confirm this match fact setting.</p>
      </div>
      <p className='text-sm'>Full time minute</p>

      {/* 
      if the match has gone to extra time, 
        - then minimum minutes is 120, 
        - otherwise 90
       */}
      <CustomInput type='number' minimum={90} value={minute} setter={setMinute} placeholder="The minimum minute is 90" />

      <button
        onClick={onClick}
        className='group font-bold text-white rounded-lg py-2 px-5 self-end mt-2 w-[fit-content] bg-button hover:bg-buttonHovered flex gap-1 items-center justify-center'>
        <p>Confirm action</p>
        <BsChevronRight className='font-bold text-sm stroke-2 group-hover:ml-1 transition-all' />
      </button>
    </div>
  )
}