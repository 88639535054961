const MatchCardFulltimeButton = ({ label = '', onClick = () => {}, Icon = null, highlightsUrl }) => {
  return (
    <div className="w-full p-2">
      <button
        onClick={onClick}
        className={` transition-all w-full px-4 py-3 rounded-lg ${
          !highlightsUrl ? 'bg-button' : 'bg-hightlightAdded text-[black] font-bold'
        } ${
          !highlightsUrl ? 'hover:bg-buttonHovered' : 'hover:bg-hightlightAddedHover'
        } disabled:bg-buttonHDisabled text-white text-sm flex items-center justify-center gap-2`}
      >
        <p>{label}</p>
        {Icon}
      </button>
    </div>
  );
};

export default MatchCardFulltimeButton;
