import CustomButtonWithIcon from 'components/CustomButtonWithIcon';
import { CustomNewsInput } from 'features/news-add/AddNews';
import { useState } from 'react';
import { IoSend } from 'react-icons/io5';

function UpdateHeaderInfoPopUp({ head, setHead, category, setCategory, handleClosePopUp }) {
  const [_head, _setHead] = useState(head);
  const [_category, _setCategory] = useState(category);
  // const [_source, _setSource] = useState((prev) => {
  //   prev = { ...source };
  //   return prev;
  // });

  const handleUpdateHeader = () => {
    // setSource((prev) => {
    //   prev = { ..._source, name: _source.name };
    //   return prev;
    // });
    setHead(_head);
    setCategory(_category);
    handleClosePopUp();
  };

  return (
    <div className="w-full flex flex-col space-y-2 items-center">
      <p className="text-left self-start capitalize font-bold text-2xl text-[gray]">Updating head info</p>
      <CustomNewsInput setter={_setCategory} label="Category" placeholder="Enter category name" value={_category} />
      <CustomNewsInput label=" Title" placeholder="Enter title" value={_head} setter={_setHead} />
      {/* <CustomNewsInput
        label="Head image url"
        placeholder="Enter head image url"
        value={_imageSource}
        setter={_setImageSource}
      /> */}
      <CustomButtonWithIcon onClick={handleUpdateHeader} text="Update header information" Icon={<IoSend />} />
    </div>
  );
}

export default UpdateHeaderInfoPopUp;
