const TeamSideDisplay = ({ name, logo, score, home = false }) => (
  <div className="flex items-center gap-2 w-full p-2 rounded-lg">
    {home ? (
      <>
        <div className="w-full flex items-center justify-end gap-2">
          <p className="text-right text-sm capitalize">{name.toLowerCase()}</p>
          <img src={logo} alt={home ? 'home team' : 'away team'} onError={(error) => error.target.src = 'http://www.barbadosfa.com/images/team-placeholder-logo.e50b9536.png'} className="h-[50px] object-contain rounded-full aspect-square" />
        </div>
        <p className="font-bold">{score}</p>
      </>
    ) : (
      <>
        <p className="font-bold">{score}</p>
        <div className="w-full flex items-center justify-start gap-2">
          <img src={logo} alt={home ? 'home team' : 'away team'} onError={(error) => error.target.src = 'http://www.barbadosfa.com/images/team-placeholder-logo.e50b9536.png'} className="h-[50px] object-contain rounded-full aspect-square" />
          <p className="text-left text-sm capitalize">{name.toLowerCase()}</p>
        </div>
      </>
    )}
  </div>
);

export default TeamSideDisplay;
