import CustomMUITabs from 'components/CustomMuiTabs';
import { useState } from 'react';
import { AiOutlineLeft, AiOutlineMinus } from 'react-icons/ai';
import { Link, useNavigate, useParams } from 'react-router-dom';
import routeStrings from 'routeStrings';
import noImage from 'assets/noImage.jpg';
import playerPlaceholder from 'assets/player-placeholder-full.svg';
import {
  useGetMatchesPlayedByTeamQuery,
  useGetMatchScheduleForTeamQuery,
  useGetPlayersQuery,
  useGetTeamQuery,
} from './team-profile-api';

const listBuilder = (list) => [...list].map((tab, index) => ({ label: tab, value: index }));

function TeamProfile() {
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (e, newValue) => setSelectedTab(newValue);
  const tabs = ['Team Info', 'Players', 'Match results', 'Match schedule'];

  const navigate = useNavigate();
  const handleGoBack = () => navigate(-1);
  return (
    <div className="flex flex-col items-center w-full min-h-screen gap-4 p-4 py-10 bg-border">
      <div className="flex flex-col gap-1 w-full max-w-[1000px]">
        <button
          onClick={handleGoBack}
          className="w-[fit-content] flex items-center transition-all group gap-1 hover:gap-2 text-sm px-0 hover:px-4 py-2 rounded-lg hover:bg-white hover:font-bold"
        >
          <AiOutlineLeft className="transition-all group-hover:text-xs" />
          <p>Back</p>
        </button>
        <p className="text-2xl font-bold text-[gray]">Team Profile</p>
        <p className="text-sm text-[gray]">
          <span>
            This page shows a team with along with other information such as players in the team, played matches and
            season team schedule.
          </span>
          <br />
          <span>Use the tab-bar below to navigate these options.</span>
        </p>
      </div>
      <div className="flex flex-col gap-1 w-full max-w-[1000px] rounded-lg overflow-clip">
        <CustomMUITabs value={selectedTab} handleChange={handleTabChange} list={listBuilder(tabs)} />
      </div>
      <div className="flex flex-col gap-1 w-full max-w-[1000px]">
        {((index) => {
          switch (index) {
            case 0:
              return <TeamInfo />;
            case 1:
              return <Players />;
            case 2:
              return <MatchesPlayed />;
            case 3:
              return <MatchesSchedule />;
            default:
              return <></>;
          }
        })(selectedTab)}
      </div>
    </div>
  );
}

export default TeamProfile;

const TeamInfo = () => {
  const { teamId } = useParams();
  const { data, isLoading, isError, isFetching } = useGetTeamQuery({ teamId }, { refetchOnMountOrArgChange: true });

  if (isLoading) return <div className="flex items-center justify-center w-full p-4">loading ...</div>;
  if (isFetching) return <div className="flex items-center justify-center w-full p-4">re-fetching ...</div>;
  if (isError) {
    return (
      <div className="flex items-center justify-center px-4 py-2 font-bold rounded-lg bg-border ">
        Something went wrong when getting info
      </div>
    );
  }

  const { team, manager } = data;
  const { name, logo, founded, no_trophy, team_color } = team;

  return (
    <div className="flex flex-col items-center w-full gap-2 py-3">
      <div
        style={{ borderColor: team_color ?? 'tomato' }}
        className="border w-[200px] aspect-square rounded-full flex items-center justify-center shadow-lg "
      >
        <img src={logo ? logo : noImage} alt="" className="w-[140px] aspect-square" />
      </div>
      <p className="font-bold">{name}</p>
      {founded && (
        <p className="text-sm">
          Founded on <span className="font-bold">{founded}</span>
        </p>
      )}
      {manager && (
        <p>
          Manager : <span className="font-bold">{manager.name}</span>
        </p>
      )}
      {typeof no_trophy === typeof 3 && (
        <p className="text-sm">
          <span className="font-bold">{no_trophy}</span> {no_trophy > 1 ? 'Trophies' : 'Trophy'} won
        </p>
      )}
    </div>
  );
};

const Players = () => {
  const { teamId } = useParams();
  const {
    data: players = {},
    isLoading,
    isError,
    isFetching,
  } = useGetPlayersQuery({ teamId }, { refetchOnMountOrArgChange: true });

  if (isLoading) return <div className="flex items-center justify-center w-full p-4">loading ...</div>;
  if (isFetching) return <div className="flex items-center justify-center w-full p-4">re-fetching ...</div>;
  if (isError) {
    return (
      <div className="flex items-center justify-center px-4 py-2 font-bold rounded-lg bg-border ">
        Something went wrong when getting info
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full gap-2 py-3">
      {players.length === 0 ? (
        <div className="flex items-center justify-center w-full font-bold bg-white rounded-lg mt-3 py-2 text-[gray]">
          No players found
        </div>
      ) : (
        <>
          {players.map((player, index) => {
            const { name, pic } = player;
            return (
              <div key={player._id} className="flex items-center w-full gap-2 px-4 py-2 bg-white rounded-lg">
                <p className="flex items-center justify-center w-5 text-sm font-bold rounded-full aspect-square bg-border">
                  {index + 1}
                </p>
                {/* <img src={pic? pic: playerPlaceholder} alt="" className="h-[50px] aspect-square rounded-full object-cover " /> */}
                <img src={playerPlaceholder} alt="" className="h-[50px] aspect-square rounded-full object-cover " />
                <p className="font-bold">{name}</p>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

const MatchesPlayed = () => {
  const { teamId } = useParams();
  const {
    data: matches = {},
    isLoading,
    isError,
    isFetching,
  } = useGetMatchesPlayedByTeamQuery({ teamId }, { refetchOnMountOrArgChange: true });

  if (isLoading) return <div className="flex items-center justify-center w-full p-4">loading ...</div>;
  if (isFetching) return <div className="flex items-center justify-center w-full p-4">re-fetching ...</div>;
  if (isError) {
    return (
      <div className="flex items-center justify-center px-4 py-2 font-bold rounded-lg bg-border ">
        Something went wrong when getting info
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full gap-2 py-3">
      {matches.length === 0 ? (
        <div className="flex items-center justify-center w-full font-bold bg-white rounded-lg mt-3 py-2 text-[gray]">
          No matches found
        </div>
      ) : (
        <>
          {matches.map(({ list, season }, index) => (
            <div key={index} className="flex flex-col w-full gap-1">
              <p className="px-4 py-1 text-sm font-bold bg-white rounded-lg w-[fit-content]">{season}</p>
              <div className="grid w-full grid-cols-1 gap-2 py-3 md:grid-cols-2 lg:grid-cols-3">
                {list.map((match) => {
                  const {
                    _id,
                    home_team: { nick_name: homeName, logo: homeLogo, _id: homeId },
                    away_team: { nick_name: awayName, logo: awayLogo, _id: awayId },
                    status,
                    playing_date: playingDate,
                    statistics,
                    playing_time,
                  } = match;

                  const homeStats = statistics.find((stat) => stat.team === homeId);
                  const { goals: homeGoals } = homeStats;

                  const awayStats = statistics.find((stat) => stat.team === awayId);
                  const { goals: awayGoals } = awayStats;

                  return (
                    <Match
                      key={_id}
                      homeTeamName={homeName}
                      awayTeamNamw={awayName}
                      status={status}
                      score={{ homeScore: homeGoals ?? 0, awayScore: awayGoals ?? 0 }}
                      homeTeamLogo={homeLogo ? homeLogo : noImage}
                      awayTeamLogo={awayLogo ? awayLogo : noImage}
                      matchDate={playingDate}
                      matchTime={playing_time}
                      completed
                    />
                  );
                })}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

const MatchesSchedule = () => {
  const { teamId } = useParams();
  const {
    data: matches,
    isLoading,
    isError,
    isFetching,
  } = useGetMatchScheduleForTeamQuery({ teamId }, { refetchOnMountOrArgChange: true })

  if (isLoading) return <div className="flex items-center justify-center w-full p-4">loading ...</div>;
  if (isFetching) return <div className="flex items-center justify-center w-full p-4">re-fetching ...</div>;
  if (isError) {
    return (
      <div className="flex items-center justify-center px-4 py-2 font-bold rounded-lg bg-border ">
        Something went wrong when getting info
      </div>
    );
  }

  return (
    <>
      <Link
        to={routeStrings.dashboard.schedules}
        className="font-bold text-sm underline text-buttonHDisabled w-[fit-content] mb-2 self-center"
      >
        Go to schedules
      </Link>
      {matches.length === 0 ? (
        <div className="flex items-center justify-center w-full font-bold bg-white rounded-lg mt-3 py-2 text-[gray]">
          No matches fit the criteria
        </div>
      ) : (
        <>
          {matches.map(({ list, season, upcomingMatch: upcoming }, index) => {
            return (
              <div key={index} className="flex flex-col w-full gap-1">
                <p className="px-4 py-1 text-sm font-bold bg-white rounded-lg w-[fit-content]">{season}</p>
                <div className="grid w-full grid-cols-1 gap-2 py-3 md:grid-cols-2 lg:grid-cols-3">
                  {list.map((match) => {
                    const {
                      _id,
                      home_team: { nick_name: homeName, logo: homeLogo },
                      away_team: { nick_name: awayName, logo: awayLogo },
                      status,
                      playing_date: playingDate,
                      playing_time,
                      currentDate,
                    } = match || ""
                    return (
                      <Match
                        key={_id }
                        homeTeamName={homeName}
                        awayTeamNamw={awayName}
                        status={status}
                        score={{ awayScore: 0, homeScore: 0 }}
                        homeTeamLogo={homeLogo}
                        awayTeamLogo={awayLogo}
                        matchDate={playingDate}
                        matchTime={playing_time}
                        schedule
                        currentDate={currentDate}
                      /**  isUpcoming={_id === undefined ? "ab" : upcoming._id === _id} */
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

const Match = ({
  homeTeamName,
  awayTeamNamw,
  score: { homeScore, awayScore },
  status,
  homeTeamLogo,
  awayTeamLogo,
  matchDate,
  matchTime,
  completed,
  schedule,
  currentDate,
  isUpcoming,
}) => {
  const TeamLogo = ({ image }) => <img src={image} alt="" className="w-[40px] aspect-square object-cover" />;
  const TeamName = ({ name }) => <p className="text-sm">{name}</p>;
  const Versus = () => <p className="font-bold">VS</p>;
  const Score = ({ score }) => <p className="text-sm font-bold">{score}</p>;
  const statusMap = {
    fin: 'Fulltime',
    nor: 'Not played',
    live: 'Live',
  };
  return (
    <div
      className={`flex flex-col items-center justify-center gap-2 p-4 bg-white rounded-lg ${
        isUpcoming ? 'border-4 border-buttonHDisabled' : ''
      }`}
    >
      <p className="text-sm font-bold text-center">{matchDate}</p>
      <p className="text-sm font-bold text-center">{matchTime}</p>
      <div className="flex items-center w-full gap-2">
        <div className="flex items-center justify-end w-full gap-2 text-right">
          <TeamName name={homeTeamName} />
          <TeamLogo image={homeTeamLogo ? homeTeamLogo : noImage} />
        </div>
        {completed ? (
          <>
            <Score score={homeScore} />
            <AiOutlineMinus />
            <Score score={awayScore} />
          </>
        ) : (
          <Versus />
        )}

        <div className="flex items-center w-full gap-2">
          <TeamLogo image={awayTeamLogo ? awayTeamLogo : noImage} />
          <TeamName name={awayTeamNamw} />
        </div>
      </div>
      {isUpcoming ? (
        <p className="font-bold text-sm text-[gray]">Next match</p>
      ) : schedule ? (
        <p className="font-bold text-sm text-[gray]">{statusMap[status]}</p>
      ) : (
        <></>
      )}
    </div>
  );
};
