import API_URLS from 'apiUrls';
import axios from 'axios';
import CustomLabelButton from 'components/CustomLabelButton';
import getApiConfig from 'helpers/getApiConfig';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GET_USER_INFO } from 'state/slices/user-auth-info';


const NextSeasonRoundSetting = () => {
  const [roundCount, setRoundCount] = useState(10);
  const onChange = (e) => setRoundCount(e.target.value);
  const [loading, setLoading] = useState(false);
  const [seasonObj, setSeasonObj] = useState({});
  const [serverRoundCount, setServerRoundCount] = useState(null);
  const [rounds, setRounds] = useState([]);

  const { TOKEN } = useSelector(GET_USER_INFO);


  const [reload, setReload] = useState(0);
  const handleReload = () => setReload((prev) => prev + 1);

  useEffect(() => {
    (async () => {
      const data = await apiCall(setLoading);

      console.log(data)
      setSeasonObj((prev) => {
        prev = data[0];
        return prev;
      });

      const reqRounds = () => {
        const _newSeasonYear = parseInt(data[0]?.name?.split('-')[0]);
        const _nextSeason = `${_newSeasonYear + 1}-${_newSeasonYear + 2}`;
        const requiredRounds = data[1].filter((dt) => dt.season === _nextSeason);
        setRounds((prev) => {
          prev = requiredRounds;
          return prev;
        });
        return requiredRounds.length;
      };

      setRoundCount((prev) => {
        prev = reqRounds();
        return prev;
      });

      setServerRoundCount((prev) => {
        prev = reqRounds();
        return prev;
      });
    })();
  }, [reload]);

  const newSeasonYear = parseInt(seasonObj?.name?.split('-')[0]);
  const nextSeason = `${newSeasonYear + 1}-${newSeasonYear + 2}`;

  return (
    <div className="w-full flex flex-col space-y-5">
      <p className="text-sm">
        Here you can change the number of rounds in the upcoming season. This setting is useful when planning match
        schedules, hence identitifying the total number of rounds and matches in an entire season.
      </p>
      {loading ? (
        <p>loading ...</p>
      ) : (
        <div className="w-[fit-content] self-center flex flex-col space-y-1 bg-[whitesmoke] p-2 border border-[#a3a3f4] rounded-xl ">
          <p className="text-sm">Rounds for season {nextSeason ?? 'n/a'}</p>
          <input
            value={roundCount}
            onChange={onChange}
            type="number"
            min={10}
            placeholder="number of rounds"
            className="bg-[transparent] text-sm border-2 border-[#a3a3f4] outline-none rounded-lg w-full px-2 py-1 mb-4"
          />
          <CustomLabelButton
            center
            label="Submit"
            onClick={() =>
              handleSubmit(setLoading, roundCount, nextSeason, TOKEN, serverRoundCount, rounds, handleReload)
            }
          />
        </div>
      )}
    </div>
  );
};

export default NextSeasonRoundSetting;

const getCurrentSeasonApiCall = () => {
  return axios.get(API_URLS.currentSeason);
  //   return response.data[0] ?? {};
};

const getAllRoundsApiCall = () => {
  return axios.get(API_URLS.allRounds);
};

const apiCall = async (loaderSetter) => {
  loaderSetter(true);
  const promises = [getCurrentSeasonApiCall(), getAllRoundsApiCall()];
  const res = await Promise.allSettled(promises);
  console.log(res)
  loaderSetter(false);
  return [res[0].value.data[0], res[1].value.data];
};

const handleSubmit = async (loaderSetter, roundCount, season, token, serverRoundCount, rounds, handleReload) => {
  if (roundCount === serverRoundCount) return;
  const config = getApiConfig(token);

  if (roundCount > serverRoundCount) {
    loaderSetter(true);
    let promises = [];
    for (let index = 0; index < roundCount; index++) {
      promises.push(axios.post(API_URLS.addRound, { num: index + 1, season }, config));
    }
    try {
      await Promise.allSettled(promises);
      loaderSetter(false);
      handleReload();
    } catch (error) {
      loaderSetter(false);
      console.error('error setting rounds', error);
    }
  } else {
    loaderSetter(true);
    let promises = [];
    let diff = serverRoundCount - roundCount;
    for (let index = 0; index < diff; index++) {
      promises.push(axios.delete(`${API_URLS.allRounds}/delete/${rounds[serverRoundCount - index - 1]._id}`, config));
    }
    try {
      await Promise.allSettled(promises);
      loaderSetter(false);
      handleReload();
    } catch (error) {
      loaderSetter(false);
      console.error('error setting rounds', error);
    }
  }
};
