import PropTypes from 'prop-types';
// material
import { AppBar, Box, IconButton, Stack, Toolbar } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

// components
import Iconify from '../../components/Iconify';
//
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_HEIGHT = 60;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  height: 60,
  display: 'flex',
  alighItems: 'center',
  justifyContent: 'flex-end',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${ DRAWER_WIDTH + 1 }px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_HEIGHT,
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {

  return (
    <RootStyle>
      <ToolbarStyle className='flex flex-row mt-10 items-center justify-end'>
        <IconButton onClick={ onOpenSidebar } sx={ { mr: 1, color: 'text.primary', display: { lg: 'none' } } }>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Box sx={ { flexGrow: 1 } } />

        <Stack direction="row" alignItems="center" spacing={ { xs: 0.5, sm: 1.5 } }>
          {/* <NotificationsPopover /> */}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
