import CustomButtonWithIcon from 'components/CustomButtonWithIcon';
import { CustomDatePicker } from 'components/CustomDateTimePicker';
import { BiArrowToRight } from 'react-icons/bi';
import useAddNews from './use-add-news';

function AddNews() {
  const addNewsHook = useAddNews();
  const CircleNumber = ({ number }) => (
    <div className="h-[20px] w-[20px] text-xs flex items-center justify-center rounded-full bg-primary text-white font-bold">
      {number}
    </div>
  );

  const Instruction = ({ number, text }) => {
    return (
      <div className="flex items-center w-full gap-1">
        <CircleNumber number={number} />
        <p className="text-sm text-[gray]">{text}</p>
      </div>
    );
  };
  return (
    <div className="flex flex-col w-full gap-2">
      <div className="flex flex-col gap-1">
        <p className="text-2xl font-bold text-[gray]">Add News</p>
        <p className="text-sm text-[gray]">There are three steps to follow when adding a news entry,</p>
        <Instruction number={1} text={'Enter the head details as shown in the form below'} />
        <Instruction number={2} text={'Supply the contents of the news article, and'} />
        <Instruction number={3} text={'Publish your news article'} />
      </div>

      {/* <div className="flex flex-col w-full gap-1">
        <p className="text-sm font-semibold text-[gray]">Source</p>
        <Section>
          <CustomNewsInput 
            label="Name"
            placeholder="Source name"
            value={addNewsHook.Name}
            setter={addNewsHook.setName}
          />
          <CustomNewsInput
            label="Logo"
            placeholder="Source logo url"
            value={addNewsHook.SourceImageUrl}
            setter={addNewsHook.setSourceImageUrl}
          />
        </Section>
      </div>*/}

      <div className="flex flex-col w-full gap-1">
        {/* <p className="text-sm font-semibold text-[gray]">Upload date</p> */}
        {/**<Section>
          <CustomDatePicker label="Date" value={addNewsHook.date} setter={addNewsHook.setDate} />
        </Section> */}
      </div>

      <div className="flex flex-col w-full gap-1">
        <p className="text-sm font-semibold text-[gray]">Head section</p>
        <Section>
          <CustomNewsInput
            label="Title"
            placeholder="add title"
            value={addNewsHook.HeadLine}
            setter={addNewsHook.setHeadLine}
          />
          <CustomNewsInput
            label="Category"
            placeholder="add category"
            value={addNewsHook.category}
            setter={addNewsHook.setCategory}
          />
        </Section>
      </div>

      <CustomButtonWithIcon
        onClick={addNewsHook.handleGoToContentEditor}
        text="Finish and go add contents"
        Icon={<BiArrowToRight className="text-xl" />}
      />
    </div>
  );
}

// <hr className="text-[#383838] w-full self-center" />

export const CustomNewsInput = ({ placeholder, label, value, setter, onInputChange }) => {
  const onChange = (e) => setter(e.target.value);
  return (
    <div className="flex items-center w-full gap-2 h-[30px]">
      <p className="w-[30%] max-w-[120px] text-sm text-right font-bold whitespace-nowrap">{label}</p>
      <input
        value={value}
        onChange={onInputChange ? onInputChange : onChange}
        placeholder={placeholder}
        className="w-[70%] h-full px-2 outline-none bg-[transparent]"
      />
    </div>
  );
};

const Section = ({ children }) => (
  <div className="flex flex-col w-full gap-2 p-4 rounded-xl bg-[#FBFCFD] border-2 border-border">{children}</div>
);

export default AddNews;

/**
 * {
        "id": 1, 
        "category": "Top news",
        "source": {
            "name" : "React Conference Editor",
            "date" : "4th October 2022",
            "logo": "https://image.shutterstock.com/image-vector/free-logo-simple-illustration-vector-600w-776460778.jpg"
        },
        "head": "Ligi ipi ina kikosi cha kwanza hatari", 
        "imageSource": "https://simbasc.co.tz/storage/2022/10/Head-Coach-1.jpg",
        "contents": [
            {
                "type": "paragraph",
                "text": "Uongozi wa klabu umefikia makualiano na Charles Ayeikoh Lukula raia wa Uganda kuwa Kocha Mkuu wa Timu ya Wanawake ya Simba Queens kwa mkataba wa miaka miwili. Kabla ya kujiunga na kikosi chetu Lukula alikuwa akikinoa kikosi cha SHE Corporates ambao ndiyo mabingwa wa Ligi Kuu nchini Uganda"
            },
            {
                "type": "image",
                "source": "https://images.unsplash.com/photo-1579952363873-27f3bade9f55?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8Zm9vdGJhbGx8ZW58MHx8MHx8&auto=format&fit=crop&w=1000&q=60"
            }
        ]
    }
 */
