import { TextField } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

export const CustomDatePicker = ({ value, setter, label = "" }) => {
    const handleChange = (newValue) => setter(newValue)
    return (
        <LocalizationProvider dateAdapter={ AdapterDayjs }>
            <DesktopDatePicker
                label={ label }
                inputFormat="MM/DD/YYYY"
                value={ value }
                onChange={ handleChange }
                renderInput={ (params) => <TextField { ...params } className="w-full" /> }
            />
        </LocalizationProvider>
    )
}

export const CustomTimePicker = ({ value, setter, label = "" }) => {
    const handleChange = (newValue) => setter(newValue)
    return (
        <LocalizationProvider dateAdapter={ AdapterDayjs }>
            <TimePicker
                label={ label }
                value={ value }
                onChange={ handleChange }
                renderInput={ (params) => <TextField { ...params } className="w-full" /> }
            />
        </LocalizationProvider>
    )
}