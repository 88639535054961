import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASEURL_HEROKU, NEW_BASE_URL  } from 'apiUrls';
import moment from 'moment';

const teamProfileApi = createApi({
  reducerPath: 'TEAM-PROFILE-API',
  baseQuery: fetchBaseQuery({ baseUrl: NEW_BASE_URL }),
  tagTypes: ['Team-profile'],
  endpoints: (build) => ({
    getTeam: build.query({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const { teamId } = _arg;
        const team = await fetchWithBQ(`team/${teamId}`);
        const manager = await fetchWithBQ(`manager/team/${teamId}`);
        return { data: { team: team.data, manager: manager.data } };
      },
    }),
    getPlayers: build.query({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const { teamId } = _arg;
        const allPlayers = await fetchWithBQ(`player`);
        const filteredPlayers = allPlayers.data.filter((player) => player.current_club._id === teamId);
        return { ...allPlayers, data: filteredPlayers };
      },
    }),
    getMatchesPlayedByTeam: build.query({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const { teamId } = _arg;
        const allMatches = await fetchWithBQ(`match/team/${teamId}`);

        const allMatchesPlayed = allMatches.data.filter((match) => match.status === 'fin');

        const matchFactsPromises = [...allMatchesPlayed].map((match) => fetchWithBQ(`moment/match/${match._id}`));
        const matchFactsResults = await Promise.allSettled(matchFactsPromises);
        const matchesWithTheirFacts = [...allMatchesPlayed].map((match, index) => {
          return {
            ...match,
            facts: matchFactsResults[index].value.data,
          };
        });

        const matchStatsPromises = [...matchesWithTheirFacts].map((match) => fetchWithBQ(`stat/match/${match._id}`));
        const matchStatsResults = await Promise.allSettled(matchStatsPromises);
        const matchesWithTheirStatsAndFacts = [...matchesWithTheirFacts].map((match, index) => {
          return {
            ...match,
            statistics: matchStatsResults[index].value.data,
          };
        });

        return {
          ...allMatches,
          data: [...seasons(matchesWithTheirStatsAndFacts)].map((season) => ({
            season,
            list: [...filterMatchesByDate(matchesWithTheirStatsAndFacts)].filter((match) => match.season === season),
          })),
        };
      },
    }),
    getMatchScheduleForTeam: build.query({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const { teamId } = _arg;
        const allMatches = await fetchWithBQ(`match/team/${teamId}`);

        const allMatchesSchedules = allMatches.data.filter((match) => match.status === 'nor');


        const _allMatches = [...allMatchesSchedules];


        const filteredMatchesByDate = filterMatchesByDate(_allMatches, true);
        return {
          ...allMatches,
          data: [...seasons(_allMatches)].map((season) => ({
            season,
            list: filteredMatchesByDate.filtered.filter((match) => match.season === season),
            upcomingMatch: filteredMatchesByDate.upcomingMatch,
          })),
        };
      },
    }),
  }),
});

export const { useGetTeamQuery, useGetPlayersQuery, useGetMatchesPlayedByTeamQuery, useGetMatchScheduleForTeamQuery } =
  teamProfileApi;

export default teamProfileApi;

const filterMatchesByDate = (matches, isSchedule = false) => {
  const dateOptions = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
  const matchDate = (playingDate) =>
    new Date(moment(playingDate).format('YYYY/MM/DD')).toLocaleDateString('en-us', dateOptions);

  const filteredMatchesWithDates = [...matches]
    .map((match) => ({
      ...match,
      playing_date: matchDate(match.playing_date),
    }))
    .sort((a, b) => new Date(a.playing_date) - new Date(b.playing_date));

  if (isSchedule) {
    const currentDate = new Date().toLocaleDateString('en-us', dateOptions);
    const upcomingMatch = [...filteredMatchesWithDates].find(
      (match) => new Date(match.playing_date) > new Date(currentDate)
    );
    return { filtered: filteredMatchesWithDates, upcomingMatch };
  } else return filteredMatchesWithDates;
};

const seasons = (matches) => {
  let _seasons = new Set();
  matches.forEach((match) => _seasons.add(match.season));
  return [..._seasons];
};
