import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import FormControlLabel from '@mui/material/FormControlLabel'
import SectionLabel from 'components/SectionLabel'
import { IoAddCircleOutline } from 'react-icons/io5'
import { AiOutlineEdit } from 'react-icons/ai'
import { MdOutlineDeleteOutline } from 'react-icons/md'
import { alpha } from '@mui/material/styles'
import toast from 'react-hot-toast'
import axios from "axios"
import { useSelector } from 'react-redux'
import ModalPopUp from 'components/ModalPopUp'
import './seasons.css'
import CustomButtonWithIcon from 'components/CustomButtonWithIcon'
import { GET_USER_INFO } from 'state/slices/user-auth-info'
import { NEW_BASE_URL } from 'apiUrls'

const Seasons = () =>{
    const [ isLoading, setIsLoading ] = useState(false)
    const [ isError, setIsError ] = useState(false)
    const [ open, setOpen] = useState(false)
    const [ isEditDialogOpened, setIsEditDialogOpened ] = useState(false)
    const [ progress, setProgress] = useState(false)
    const [ editedSeason, setEditableSeason] = useState(null)
    const { TOKEN } = useSelector(GET_USER_INFO)
    const [ year, setYear ] = useState('')
    const [ currentSeason, setCurrentSeason ] = useState(false)
    const [ seasons, setSeasons ] = useState([])


  const getSeasons = async() => {
    try {
      const response = await axios.get(`${NEW_BASE_URL}/season`)
      setSeasons(response.data)
    } catch(err) {
        toast.error('Error while listing seasons')
    }
  }

  const addNewSeason = async() => {
    if (!year || !/\d{4}-\d{4}/.test(year)) {
      return toast.error("Invalid year")
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${ TOKEN }`,
      }
    }

    const body = {
      year,
      current: currentSeason || undefined,
    }

     const response  = await axios.post(`${NEW_BASE_URL}/season/add`, body, config);
     if(response) {
        setOpen(false)
        getSeasons()
        toast.success("New season added")
     }

    } catch(err) {
      if (err.response) {
        toast.error(err.response.data.message)
      }
    }
  }

  const deleteSeason = async (seasonId) => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${ TOKEN }`,
        }
       }

        const response = await axios.delete(`${NEW_BASE_URL}/season/delete/${seasonId}`, config)
        if(response.status) {
           getSeasons()
           toast.success("Season deleted successfully")
        }

        if(!response.status) {
           toast.error("Error deleting the season")
        }

      } catch(err) {
            console.log(err)
           toast.error("Failed to delete season")
      }
  }

  const saveSeasonChanges = async() => {
    if (!year || !/\d{4}-\d{4}/.test(year)) {
        return toast.error("Invalid year")
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
      }
    }

    const body = {
      year,
      current: currentSeason,
    }

     const { _id } = editedSeason
     const response  = await axios.put(`${NEW_BASE_URL}/season/edit/${_id}`, body, config)
      if(response) {
        setIsEditDialogOpened(false)
        getSeasons()
        toast.success("Season saved")
      }

    } catch(err) {
      if (err.response) {
        toast.error(err.response.data.message)
      }
    }
  }

  const handleOpenEditDialog = (season) => {
    setEditableSeason(season)
    setIsEditDialogOpened(true)
    setYear(season.year)
    setCurrentSeason(season.current)
  }

  useEffect(() => {
    getSeasons()
  },[])

    return (
        <div className="w-full px-[5%] flex flex-col gap-4 select-none">
        <div className="flex flex-col items-center w-full gap-2 rounded-lg md:flex-row md:justify-between">
          <div className="flex flex-col gap-1">
            <SectionLabel label="Seasons" />
            <p className="text-sm">
              This is where you can view all registered and available seasons.
              <br />
              You can also add one by pressing the Add season button.
            </p>
          </div>

          <button onClick={() => {
            setYear('')
            setOpen(true)
          }}
            className="flex w-full md:w-[fit-content] items-center justify-center gap-2 px-4 py-3 text-white transition-all rounded-lg hover:gap-4 bg-button hover:bg-buttonHovered">
            <IoAddCircleOutline />
            <p className="text-sm">Add season</p>
          </button>
        </div>

        {(() => {
          if (isLoading) return <div className="flex items-center justify-center w-full p-4">loading ...</div>;
          if (isError) {
            return (
              <div className="flex items-center justify-center px-4 py-2 font-bold rounded-lg bg-border ">
                Something went wrong when getting info
              </div>
            );
          }
          return (
            <>
            <div className="grid grid-cols-4 gap-4 w-full">
              {[...seasons]
                .map((season, index) => (
                  <Box
                    key={index}
                    className="min-h-[180px] select-none border border-border shadow-lg grow flex flex-col justify-center gap-2 rounded-lg relative"
                    bgcolor={season.current ? (theme) => alpha(theme.palette.primary.main, 0.1) : "#FBFBFD" }
                  >
                    <div className="space-y-2">
                      <div className="flex flex-row items-center justify-between">
                        <div
                          onClick={() => deleteSeason(season._id)}
                          className="flex items-center justify-center gap-2 px-3 py-1 cursor-pointer h-[30px] aspect-square rounded-lg absolute right-2 top-2 bg-border hover:bg-[tomato] hover:text-white transition-all"
                        >
                          <p className='text-sm font-bold'>Del</p>
                          <MdOutlineDeleteOutline className="text-xl" />
                        </div>
                        <div
                          onClick={() => handleOpenEditDialog(season)}
                          className="flex items-center justify-center gap-2 px-3 py-1 group cursor-pointer h-[30px] aspect-square rounded-lg absolute left-2 top-2 bg-border hover:bg-buttonHovered hover:text-white transition-all"
                        >
                          <p className='text-sm font-bold'>Edit</p>
                          <AiOutlineEdit className="text-xl" />
                        </div>
                      </div>
                      <p className="text-center text-lg font-bold">{season.year}</p>
                      {season.current ?
                        <div className="flex flex-row justify-center">
                          <Chip
                            size="small"
                            label="Current Season"
                            color="primary"
                          />
                        </div>
                        : null
                      }
                    </div>
                  </Box>
                ))}
            </div>
            </>
          );
        })()}

    <ModalPopUp
        open={open}
        handleClose={() => setOpen(false)}
        height="fit-content"
        width="95%">

     <div className="flex flex-col w-full gap-2">
      <SectionLabel label={`Add  season`} />
      <p className="text-[gray] text-sm">
        Here you add new season. This information will be used when creating a match schedule denoting where the match
        seasons will be available
      </p>

    <input type="text"  onChange={(e) => setYear(e.target.value)} className="input-text" placeholder="Year" />

       <FormControlLabel
         control={
           <Checkbox
             checked={currentSeason}
             onChange={(event) => setCurrentSeason(event.target.checked)}
           />
         }
         label="Current Season"
       />

    <CustomButtonWithIcon
          Icon={  <IoAddCircleOutline />}
          text={ progress ? 'Adding season ...' : 'Add  season'}
          plain={ false }
          onClick={()=> addNewSeason()}
        />

    </div>
        </ModalPopUp>


    <ModalPopUp
        open={isEditDialogOpened}
        handleClose={() => setIsEditDialogOpened(false)}
        height="fit-content"
        width="95%">

     <div className="flex flex-col w-full gap-2">
      <SectionLabel label={`Edit  season`} />
      <p className="text-[gray] text-sm">
        Here you can edit season. This information will be used when creating a match schedule denoting where the match
        seasons will be available
      </p>

    <input type="text" value={year} onChange={(e) => setYear(e.target.value)} className="input-text" placeholder="Year" />

       <FormControlLabel
         control={
           <Checkbox
             checked={currentSeason}
             onChange={(event) => setCurrentSeason(event.target.checked)}
           />
         }
         label="Current Season"
       />

    <CustomButtonWithIcon
          Icon={  <IoAddCircleOutline />}
          text={ progress ? 'saving season ...' : 'Save season'}
          plain={ false }
          onClick={()=> saveSeasonChanges()}
        />

    </div>
        </ModalPopUp>
      </div>
    )
}

export default Seasons
