import data_not_found from 'assets/images/data_not_found.png';
import noImage from 'assets/noImage.jpg';
import { useEffect, useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import routeStrings from 'routeStrings';
import useTeams from './use-teams';
// import { useGetTeamsQuery } from "./team-api"

function TeamList() {
  const navigate = useNavigate();
  const teamsHook = useTeams();
  // const { data: teams = [], isLoading, isFetching, isError } = useGetTeamsQuery(
  //     {},
  //     { refetchOnMountOrArgChange: true }
  // )
  const [input, setInput] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [isError, seIisError] = useState(false);
  const handleInputChange = (e) => setInput(e.target.value);
  const [teams, setTeams] = useState([]);
  const [filteredTeams, setFilteredTeams] = useState([]);

  useEffect(()=>{
    setTeams(teamsHook.teams)
  }, [teamsHook])


  useEffect(() => {
      const teamsSetter = (prev) => {
          prev = teams
          return prev
      }
      setFilteredTeams(teamsSetter)
  }, [teams])

  useEffect(() => {
      if (input === "") {
          const teamsSetter = (prev) => {
              prev = teams
              return prev
          }
          setFilteredTeams(teamsSetter)
      } else {
          const teamsFilter = filteredTeams.filter(team => team.name.toLowerCase().includes(input.toLowerCase()))
          const teamsSetter = (prev) => {
              prev = teamsFilter
              return prev
          }
          setFilteredTeams(teamsSetter)
      }
  }, [input])

  if (teamsHook.loading) return <p>loading ...</p>;
  if (isFetching) return <p>re-fetching ...</p>
  if (isError) {
      return <div className="bg-border font-bold flex items-center justify-center py-2 px-4 rounded-lg ">
          Something went wrong when getting team info
      </div>
  }

  return (
    <>
      <div className="flex flex-col gap-1 w-full px-[5%]">
        <p className="text-2xl font-bold text-[gray]">Teams</p>
        <p className="text-sm text-[gray]">
          <span>This is a list of all available teams in the league sorted by their name.</span>
          <br />
          <span>
            To view a particular team's information just click on the team card and you will be taken to it's respective
            profile page.
          </span>
        </p>
      </div>
      <TeamSearch handleChange={handleInputChange} value={input} />

      {teamsHook && (
        <div className="flex flex-col gap-2 w-[90%] rounded-lg outline-none mt-2 ">
          {filteredTeams.length === 0 ? (
            <img src={data_not_found} alt="" className="w-full h-full object-contain" />
          ) : (
            filteredTeams.map((team, index) => (
              <div
                key={team._id}
                onClick={() => navigate(`${routeStrings.teamProfile}/${team._id}`)}
                className={`hover:bg-border border transition-all border-border group cursor-pointer font-bold w-full flex items-center justify-between gap-2 py-2 px-4 rounded-lg`}
              >
                <div className="w-full flex items-center gap-2">
                  <p className="w-5 bg-white aspect-square rounded-full border-border text-sm flex items-center justify-center">
                    {index + 1}
                  </p>
                  <img src={team.logo ? team.logo : noImage } alt="team logo" className="h-[40px] aspect-square object-contain" />
                  <p>{team.name}</p>
                </div>
                <AiOutlineArrowRight className="group-hover:mr-2 transition-all" />
              </div>
            ))
          )}
        </div>
      )}
    </>
  );
}

export default TeamList;

const TeamSearch = ({ value, handleChange }) => {
  return (
    <div className="px-[5%] py-3 flex flex-col gap-2 backdrop-blur-sm bg-[transparent] w-full rounded-lg">
      <input
        value={value}
        onChange={handleChange}
        placeholder="Search teams by name"
        className=" border border-border bg-[#ffffffb3] outline-none py-2 px-2"
      />
    </div>
  );
};
