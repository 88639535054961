import { BsArrowRepeat } from 'react-icons/bs';
import { IoMdFootball } from 'react-icons/io';
import Card from './Card';

const MatchFactItem = ({ fact, isHome }) => {
  const { event, card_type, player, time, in: playerIn, out: playerOut, assisted_by } = fact;
  const Time = ({ right = false }) => (
    <p className={`text-sm w-[15px] font-bold ${right ? 'text-right' : 'text-left'}`}>{time ?? '...'}</p>
  );

  const Name = ({ sub = false, name }) => (
    <p className={`font-bold ${sub ? 'text-xs font-normal' : 'text-sm'}`}>{name ? name : player ? player?.name : '...'}</p>
  );

  return (
    <div className="border border-[transparent] border-b-[#edede9] hover:bg-[#edede9] transition-all px-2 rounded-lg min-h-[60px] flex items-center">
      {(() => {
        if (event) {
          return (
            <>
              {((e) => {
                switch (e) {
                  case 'goal':
                    return (
                      <div className={`flex items-center gap-2 w-full ${isHome ? 'justify-start' : 'justify-end'}`}>
                        {isHome ? (
                          <>
                            <Time />
                            <div className="w-[30px] flex items-center justify-center">
                              <IoMdFootball className="text-2xl" />
                            </div>
                            <div className="flex flex-col justify-center">
                              <Name />
                              {assisted_by && <p className="text-xs text-[gray]">Assist by {assisted_by.name}</p>}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="flex flex-col justify-center">
                              <Name />
                             {assisted_by && <p className="text-xs text-[gray]">Assist by {assisted_by.name}</p>}
                            </div>
                            <div className="w-[30px] flex items-center justify-center">
                              <IoMdFootball className="text-2xl" />
                            </div>
                            <Time right />
                          </>
                        )}
                      </div>
                    );

                  case 'sub': {
                    const playerInName = playerIn.name ?? '...'
                    const playerOutName = playerOut.name ?? '...'


                    return (
                      <div className={`flex items-center gap-2 w-full ${isHome ? 'justify-start' : 'justify-end'}`}>
                        {isHome ? (
                          <>
                            <Time />
                            <div className="w-[30px] flex items-center justify-center">
                              <BsArrowRepeat className='text-2xl text-[gray]' />
                            </div>
                            <div className="flex flex-col justify-center">
                              <Name name={playerInName} />
                              <Name sub name={playerOutName} />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="flex flex-col justify-center text-right">
                              <Name name={playerInName} />
                              <Name sub name={playerOutName} />
                            </div>
                            <div className="w-[30px] flex items-center justify-center">
                              <BsArrowRepeat className='text-2xl text-[gray]' />
                            </div>
                            <Time />
                          </>
                        )}
                      </div>
                    )
                  }

                  case 'ht': {
                    return (
                      <div className='w-full flex items-center justify-center gap-2 text-sm'>
                        <div className='h-[35px] aspect-square rounded-full border-2 border-[tomato] flex items-center justify-center '>
                          <p className='font-bold'>HT</p>
                        </div>
                        <p className='text-xs text-buttonHDisabled font-bold'>{time}"</p>
                      </div>
                    )
                  }

                  case 'ft': {
                    return (
                      <div className='w-full flex items-center justify-center gap-2 text-sm'>
                        <div className='h-[35px] aspect-square rounded-full border-2 border-[tomato] flex items-center justify-center '>
                          <p className='font-bold'>FT</p>
                        </div>
                        <p className='text-xs text-buttonHDisabled font-bold'>{time}"</p>
                      </div>
                    )
                  }

                  default:
                    return <></>;
                }
              })(event)}
            </>
          );
        }

        if (card_type) {
          return (
            <>
              {((card) => {
                switch (card) {
                  case 'red':
                    return (
                      <div className={`flex items-center gap-2 w-full ${isHome ? 'justify-start' : 'justify-end'}`}>
                        {isHome ? (
                          <>
                            <Time />
                            <div className="w-[30px] flex items-center justify-center">
                              <Card red />
                            </div>
                            <div className="flex flex-col justify-center">
                              <Name />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="flex flex-col justify-center">
                              <Name />
                            </div>
                            <div className="w-[30px] flex items-center justify-center">
                              <Card red />
                            </div>
                            <Time right />
                          </>
                        )}
                      </div>
                    );

                  case 'yellow':
                    return (
                      <div className={`flex items-center gap-2 w-full ${isHome ? 'justify-start' : 'justify-end'}`}>
                        {isHome ? (
                          <>
                            <Time />
                            <div className="w-[30px] flex items-center justify-center">
                              <Card />
                            </div>
                            <div className="flex flex-col justify-center">
                              <Name />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="flex flex-col justify-center">
                              <Name />
                            </div>
                            <div className="w-[30px] flex items-center justify-center">
                              <Card />
                            </div>
                            <Time right />
                          </>
                        )}
                      </div>
                    );

                  default:
                    return <></>;
                }
              })(card_type)}
            </>
          );
        }
        return <></>;
      })()}
    </div>
  );
};

export default MatchFactItem;
