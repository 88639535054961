import { createSlice } from '@reduxjs/toolkit';

const newsSlice = createSlice({
  name: 'NEWS',
  initialState: {
    category: '',
    source: {
      name: '',
      date: null,
      logo: '',
    },
    head: '',
    imageSource: '',
  },
  reducers: {
    SET_DATA: (state, action) => {
      state.category = action.payload.category;
      state.source.name = action.payload.source.name;
      state.source.date = action.payload.source.date;
      state.source.logo = action.payload.source.logo;
      state.head = action.payload.head;
      state.imageSource = action.payload.imageSource;
    },
    CLEAR_DATA: (state) => {
      state.category = '';
      state.source.name = '';
      state.source.date = null;
      state.source.logo = '';
      state.head = '';
      state.imageSource = '';
    },
  },
});

export const GET_NEWS_HEAD_INFO = (state) => {
  const { category, source, head, imageSource } = state.newsHead;
  return { category, source, head, imageSource };
};

export const { SET_DATA, CLEAR_DATA } = newsSlice.actions;

export default newsSlice.reducer;

