import CustomButton from 'components/CustomButton';
import CustomCircularLoader from 'components/CustomCircularLoader';
import { CustomDatePicker, CustomTimePicker } from 'components/CustomDateTimePicker';
import { CustomSelectWithObjects } from 'components/CustomSelect';
import dayjs from 'dayjs';
import axios from "axios"
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { MdSend } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import {
  GET_MATCHES_INFO,
  SET_MATCHES,
  SET_ROUNDS,
  SET_SEASONS,
  SET_SELECTED_ROUND,
  SET_SELECTED_SEASON,
} from 'state/slices/matches';
import getSeason from 'helpers/get_season';
import { GET_USER_INFO } from 'state/slices/user-auth-info';
import { addMatch, getAllBroadcasters, getAllGrounds, getAllRounds, getAllTeams } from '../functions';

function AddMatchForm({ handleClose, setRefresh }) {
  const { SELECTED_SEASON } = useSelector(GET_MATCHES_INFO);
  const selected_season = getSeason(new Date());
  const addMatchHook = useAddMatchForm(SELECTED_SEASON ?? selected_season);
  const { ticketProviders, handleTicketChange, ticketProvider, broadcasters, handleChangeBroadcaster } = useAddMatchForm() 

  const onSubmit = (event) => {
    event.preventDefault();
    addMatchHook.handleAddMatch(handleClose, setRefresh);
  }

  const formOptions = [
    [
      {
        data: ((dt) => {
          return dt.map((_dt) => ({ value: _dt._id, label: _dt.nick_name }))
        })(addMatchHook.homeTeams),
        value: addMatchHook.homeTeam,
        onChange: addMatchHook.handleChangeHomeTeam,
        label: 'Home team',
        type: 'select',
      },
      {
        data: ((dt) => {
          return dt.map((_dt) => ({ value: _dt._id, label: _dt.nick_name }))
        })(addMatchHook.awayTeams),
        value: addMatchHook.awayTeam,
        onChange: addMatchHook.handleChangeAwayTeam,
        label: 'Away team',
        type: 'select',
      },
    ],
    [
      {
        data: ((dt) => {
          return dt.map((_dt) => ({ value: _dt._id, label: _dt.num }));
        })(addMatchHook.rounds),
        value: addMatchHook.round,
        onChange: addMatchHook.handleChangeRound,
        label: 'Round',
        type: 'select',
      },
      {
        data: ((dt) => {
          return dt.map((_dt) => ({ value: _dt._id, label: _dt.name }));
        })(addMatchHook.grounds),
        value: addMatchHook.ground,
        onChange: addMatchHook.handleChangeGround,
        label: 'Ground',
        type: 'select',
      },
    ],
    [
      { type: 'date', value: addMatchHook.date, setter: addMatchHook.setDate, label: 'Match date' },
      { type: 'time', value: addMatchHook.time, setter: addMatchHook.setTime, label: 'Matchday time' },
    ],
  ]

  if (addMatchHook.loading) {
    return (
      <div className="h-[50px] flex items-center justify-center w-full">
        <CustomCircularLoader message="please wait" />
      </div>
    );
  }

  return (
    <form onSubmit={onSubmit} className="flex flex-col items-start gap-2 bg-[transparent]">
      <div className="gap-1 flex flex-col">
        <p className="font-bold text-[gray] text-2xl capitalize">Create a match</p>
        <p className="text-sm text-[gray]">
          This is where you are able to create a match schedule entry and will be visible in the matches tab awaiting
          further updates.
        </p>
      </div>
      {formOptions.map((options, index) => {
        return (
          <div key={index} className="w-full flex flex-col md:flex-row items-center md:justify-between gap-2">
            {options.map((option, indexx) => {
              if (option.type === 'select') {
                return (
                  <CustomSelectWithObjects
                    key={indexx}
                    data={option.data}
                    value={option.value}
                    onChange={option.onChange}
                    label={option.label}
                  />
                );
              }
              if (option.type === 'date')
                return <CustomDatePicker value={option.value} setter={option.setter} label={option.label} />;
              if (option.type === 'time')
                return <CustomTimePicker value={option.value} setter={option.setter} label={option.label} />;
            })}
          </div>
        );
      })}

      <p><strong>Broadcasters</strong></p>
      { broadcasters.length <= 0 ? null : broadcasters.map(broadcaster => (
          <div className="player-box" key={broadcaster._id}>
              <input type='checkbox' onChange={() => handleChangeBroadcaster(broadcaster)}
                  checked={ 
                     broadcaster.isChecked === undefined ? false:
                     broadcaster.isChecked ? true : false
                  } />
              <div className="player-title">
                   <h3 style={{ marginTop: 10 }}>{ broadcaster.name }</h3>
                </div>
          </div>
      ))}

      <p><strong>Ticket providers</strong></p>
      { ticketProviders.length <= 0 ? null : ticketProviders.map(provider => (
          <div className="player-box" key={provider._id}>
              <input type='checkbox' onChange={() => handleTicketChange(provider)}
                  checked={ 
                     provider.isChecked === undefined ? false:
                    provider.isChecked ? true : false
                  } />
              <div className="player-title">
                   <h3>{ provider.name }</h3>
                     <p>{ provider.address.city }</p>
                    </div>
          </div>
      ))}
      <CustomButton mt={10} label="Confirm Create Match" form Icon={<MdSend />} />
    </form>
  );
}

export default AddMatchForm;

const useAddMatchForm = (SELECTED_SEASON) => {
  const dispatch = useDispatch();
  const { TOKEN } = useSelector(GET_USER_INFO);

  const [loading, setLoading] = useState(false);

  // form value holders
  const [teams, setTeams] = useState([]);
  const [homeTeam, setHomeTeam] = useState('');
  const [awayTeam, setAwayTeam] = useState('');
  const [homeTeams, setHomeTeams] = useState([]);
  const [awayTeams, setAwayTeams] = useState([]);

  const [rounds, setRounds] = useState([]);
  const [round, setRound] = useState('');

  const [ grounds, setGrounds] = useState([]);
  const [ ground, setGround] = useState('');
 
  const [ broadcasters, setBroadcasters] = useState([])
  const [ broadcaster, setBroadcaster] = useState([])
  const [ ticketProviders, setTicketProviders] = useState([])
  const [ ticketProvider, setTicketProvider] = useState([])

  const [date, setDate] = useState(dayjs());
  const [time, setTime] = useState(null);

  const handleChangeHomeTeam = (e) => setHomeTeam(e.target.value)
  const handleChangeAwayTeam = (e) => setAwayTeam(e.target.value)
  const handleChangeRound = (e) => setRound(e.target.value)
  const handleChangeGround = (e) => setGround(e.target.value)

  const handleChangeBroadcaster = (Broadcaster) => {
    let newBroadcasters = broadcasters.map(broadcaster => {
      if(broadcaster._id === Broadcaster._id) {
        return {
          ...broadcaster,
          isChecked: broadcaster.isChecked === undefined ? true : !broadcaster.isChecked
        }
      }
      return broadcaster
    })
    setBroadcasters(newBroadcasters)
  }

  const handleTicketChange = (Provider)=> {
     let newTicketProviders = ticketProviders.map(provider => {
       if(provider._id === Provider._id) {
          return {
            ...provider,
            isChecked:provider.isChecked === undefined ? true: !provider.isChecked
          }
       }

       return provider
     })
     setTicketProviders(newTicketProviders)
  }

  const apiCall = async () => {
    setLoading(true);
    try {
      const promises = [getAllTeams(), getAllRounds(), getAllGrounds(), getAllBroadcasters()];
      const results = await Promise.allSettled(promises);

      // teams
      const sortedTeams = results[0].value.data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
      setTeams((prev) => {
        prev = sortedTeams;
        return prev;
      });

      setHomeTeam(sortedTeams[0]._id);
      const _homeTeams = sortedTeams.filter((team) => team._id !== sortedTeams[1]._id);
      setHomeTeams((prev) => {
        prev = _homeTeams;
        return prev;
      });

      setAwayTeam(sortedTeams[1]._id);
      const _awayTeams = sortedTeams.filter((team) => team._id !== sortedTeams[0]._id);
      setAwayTeams((prev) => {
        prev = _awayTeams;
        return prev;
      });

      // rounds
      const _rounds = results[1].value.data;
      setRounds((prev) => {
        // prev = _rounds;
        prev = _rounds?.filter((round) => round.season === SELECTED_SEASON).sort((a, b) => a.num - b.num);
        return prev;
      });
      setRound((prev) => {
        prev = _rounds?.filter((round) => round.season === SELECTED_SEASON).sort((a, b) => a.num - b.num)[0]?._id ?? 0;
        return prev;
      });

      // stadiums
      const _stadiums = results[2].value.data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
      setGrounds((prev) => {
        prev = _stadiums;
        return prev;
      });
      setGround(_stadiums[0]._id ?? '');

      // broadcasters
      const _broadcasters = results[3].value.data
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
        .sort((a, b) => (a.name > b.name ? 1 : -1));
      setBroadcasters((prev) => {
        prev = _broadcasters;
        return prev;
      });
      //setBroadcaster(_broadcasters[0]._id ?? '')
      setBroadcaster(results[3].value.data)

      setLoading(false);
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  }

  const getTicketProviders = async() => {
    try { 
      const response = await axios.get('https://api-v2.ligi.co.tz/ticket_provider')
      setTicketProviders(response.data)
    } catch(err) {

    }
  }

  useEffect(() => {
    apiCall()
    getTicketProviders()
  }, [])

  useEffect(() => {
    const _homeTeams = teams.filter((team) => team._id !== awayTeam)
    setHomeTeams((prev) => {
      prev = _homeTeams
      return prev
    })

    const _awayTeams = teams.filter((team) => team._id !== homeTeam)
    setAwayTeams((prev) => {
      prev = _awayTeams
      return prev
    });
  }, [homeTeam, awayTeam])

  // confirm add match
  const handleAddMatch = async (handleClose, setRefresh) => {
    let sortedTicketProviders = []
    let sortedBroadcasters = []

    ticketProviders.map(provider => {
       sortedTicketProviders.push(provider._id)
    })

    broadcasters.map(broadcaster => {
      sortedBroadcasters.push(broadcaster._id)
    })

    let body = {
      home_team: homeTeam,
      away_team: awayTeam,
      playing_time: `${new Date(time).getHours()}:${((minute) => {
        if (parseInt(minute) < 10) return `0${minute}`;
        return minute;
      })(new Date(time).getMinutes())}`,
      season: SELECTED_SEASON,
      playing_date: date,
      ground: ground,
      round: round,
      broadcasters: sortedBroadcasters,
      ticket_providers:sortedTicketProviders
    }

    try {
      setLoading(true);
      const response = await addMatch({ token: TOKEN, body });
      setLoading(false);
      handleClose();
      toast.success('Match created successfully');

      // ----------------- clearing out matches, seasons and rounds store states -----------------
      dispatch(SET_MATCHES({ MATCHES: [] }));
      dispatch(SET_SEASONS({ SEASONS: [] }));
      dispatch(SET_SELECTED_SEASON({ SELECTED_SEASON: '' }));
      dispatch(SET_ROUNDS({ ROUNDS: [] }));
      dispatch(SET_SELECTED_ROUND({ SELECTED_ROUND: '' }));

      // trigger schedule data refetching
      setRefresh((prev) => prev + 1);
    } catch (error) {
      toast.error('something went wrong');
      setLoading(false);
      toast.error('Could not create a match schedule entry');
    }
  };

  return {
    loading,
    teams,
    round,
    rounds,
    handleChangeRound,
    homeTeam,
    handleChangeHomeTeam,
    awayTeam,
    handleChangeAwayTeam,
    homeTeams,
    awayTeams,
    grounds,
    ground,
    handleChangeGround,
    broadcasters,
    broadcaster,
    handleChangeBroadcaster,
    handleTicketChange,
    date,
    setDate,
    time,
    setTime,
    ticketProviders,
    ticketProvider,
    handleAddMatch,
  };
};
