import {
  updateMatchMoment,
  updateMatchFact,
  updateMatchStat,
  updateMatchTable,
} from 'features/view-all-matches/functions';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { GET_USER_INFO } from 'state/slices/user-auth-info';
import axios from 'axios';
import API_URLS from '../../../../../../apiUrls';

const useEditStatsForm = (match, handleRefresh, homeStats, awayStats) => {
  const { TOKEN } = useSelector(GET_USER_INFO);
  const [loading, setLoading] = useState(false);
  // ----------------- form values ------------------------
  // goals
  const goalTypes = ['Solo', 'Penalty', 'Freekick'];

  const [goalScorer, setGoalScorer] = useState({ home: null, away: null });
  const handleGoalScorerChange = (e, name) => {
    switch (name) {
      case 'home': {
        setGoalScorer((prev) => {
          prev = { ...prev, home: e.target.value };
          return prev;
        });
        break;
      }

      case 'away': {
        setGoalScorer((prev) => {
          prev = { ...prev, away: e.target.value };
          return prev;
        });
        break;
      }

      default:
        break;
    }
  };

  const [goalAssister, setGoalAssister] = useState({ home: null, away: null });
  const handleGoalAssisterChange = (e, name) => {
    switch (name) {
      case 'home': {
        setGoalAssister((prev) => {
          prev = { ...prev, home: e.target.value };
          return prev;
        });
        break;
      }

      case 'away': {
        setGoalAssister((prev) => {
          prev = { ...prev, away: e.target.value };
          return prev;
        });
        break;
      }

      default:
        break;
    }
  };

  const [goalMinute, setGoalMinute] = useState({ home: null, away: null });
  const handleGoalMinuteChange = (e, name) => {
    switch (name) {
      case 'home': {
        setGoalMinute((prev) => {
          prev = { ...prev, home: e.target.value };
          return prev;
        });
        break;
      }

      case 'away': {
        setGoalMinute((prev) => {
          prev = { ...prev, away: e.target.value };
          return prev;
        });
        break;
      }

      default:
        break;
    }
  };

  const [selectedGoalType, setSelectedGoalType] = useState({ home: '', away: '' });

  // shots
  const [shots, setShots] = useState({ home: homeStats.shots ?? 0, away: awayStats.shots ?? 0 });
  const handleShotsChange = (e, name) => {
    switch (name) {
      case 'home': {
        setShots((prev) => {
          prev = { ...prev, home: e.target.value };
          return prev;
        });
        break;
      }

      case 'away': {
        setShots((prev) => {
          prev = { ...prev, away: e.target.value };
          return prev;
        });
        break;
      }

      default:
        break;
    }
  };

  // shots_on_target
  const [shotsOnTarget, setShotsOnTarget] = useState({
    home: homeStats.shots_on_target ?? 0,
    away: awayStats.shots_on_target ?? 0,
  });
  const handleShotsOnTargetChange = (e, name) => {
    switch (name) {
      case 'home': {
        setShotsOnTarget((prev) => {
          prev = { ...prev, home: e.target.value };
          return prev;
        });
        break;
      }

      case 'away': {
        setShotsOnTarget((prev) => {
          prev = { ...prev, away: e.target.value };
          return prev;
        });
        break;
      }

      default:
        break;
    }
  };

  // passes
  const [passes, setPasses] = useState({
    home: homeStats.passes ?? 0,
    away: awayStats.passes ?? 0,
  });
  const handlePassesChange = (e, name) => {
    switch (name) {
      case 'home': {
        setPasses((prev) => {
          prev = { ...prev, home: e.target.value };
          return prev;
        });
        break;
      }

      case 'away': {
        setPasses((prev) => {
          prev = { ...prev, away: e.target.value };
          return prev;
        });
        break;
      }

      default:
        break;
    }
  };

  // player awarded a red card
  const [playerRedCard, setPlayerRedCard] = useState({ home: null, away: null });
  const handlePlayerRedCardChange = (e, name) => {
    switch (name) {
      case 'home': {
        setPlayerRedCard((prev) => {
          prev = { ...prev, home: e.target.value };
          return prev;
        });
        break;
      }

      case 'away': {
        setPlayerRedCard((prev) => {
          prev = { ...prev, away: e.target.value };
          return prev;
        });
        break;
      }

      default:
        break;
    }
  };

  // time for awarded a red card
  const [timeRedCard, setTimeRedCard] = useState({ home: 0, away: 0 });
  const handleTimeRedCardChange = (e, name) => {
    switch (name) {
      case 'home': {
        setTimeRedCard((prev) => {
          prev = { ...prev, home: e.target.value };
          return prev;
        });
        break;
      }

      case 'away': {
        setTimeRedCard((prev) => {
          prev = { ...prev, away: e.target.value };
          return prev;
        });
        break;
      }

      default:
        break;
    }
  };

  // player awarded a Yellow card
  const [playerYellowCard, setPlayerYellowCard] = useState({ home: null, away: null });
  const handlePlayerYellowCardChange = (e, name) => {
    switch (name) {
      case 'home': {
        setPlayerYellowCard((prev) => {
          prev = { ...prev, home: e.target.value };
          return prev;
        });
        break;
      }

      case 'away': {
        setPlayerYellowCard((prev) => {
          prev = { ...prev, away: e.target.value };
          return prev;
        });
        break;
      }

      default:
        break;
    }
  };

  // time for awarded a Yellow card
  const [timeYellowCard, setTimeYellowCard] = useState({ home: 0, away: 0 });
  const handleTimeYellowCardChange = (e, name) => {
    switch (name) {
      case 'home': {
        setTimeYellowCard((prev) => {
          prev = { ...prev, home: e.target.value };
          return prev;
        });
        break;
      }

      case 'away': {
        setTimeYellowCard((prev) => {
          prev = { ...prev, away: e.target.value };
          return prev;
        });
        break;
      }

      default:
        break;
    }
  };

  // home substitutions
  const [substitutions, setSubstitutions] = useState({ home: { in: null, out: null }, away: { in: null, out: null } });
  const handleSubstitutionsChange = (e, name, isPlayerIn) => {
    switch (name) {
      case 'home': {
        if (isPlayerIn) {
          setSubstitutions((prev) => {
            prev = { ...prev, home: { in: e.target.value, out: prev.home.out } };
            return prev;
          });
        } else {
          setSubstitutions((prev) => {
            prev = { ...prev, home: { in: prev.home.in, out: e.target.value } };
            return prev;
          });
        }
        break;
      }

      case 'away': {
        if (isPlayerIn) {
          setSubstitutions((prev) => {
            prev = { ...prev, away: { in: e.target.value, out: prev.away.out } };
            return prev;
          });
        } else {
          setSubstitutions((prev) => {
            prev = { ...prev, away: { in: prev.away.in, out: e.target.value } };
            return prev;
          });
        }
        break;
      }

      default:
        break;
    }
  };

  // time for substitution
  const [timeSubstitution, setTimeSubstitution] = useState({ home: 0, away: 0 });
  const handleTimeSubstitutionChange = (e, name) => {
    switch (name) {
      case 'home': {
        setTimeSubstitution((prev) => {
          prev = { ...prev, home: e.target.value };
          return prev;
        });
        break;
      }

      case 'away': {
        setTimeSubstitution((prev) => {
          prev = { ...prev, away: e.target.value };
          return prev;
        });
        break;
      }

      default:
        break;
    }
  };

  // text for matchFact
  const [matchFactText, setMatchFactText] = useState({ home: '', away: '' });
  const handleMatchFactText = (e, name) => {
    switch (name) {
      case 'home': {
        setMatchFactText((prev) => {
          prev = { ...prev, home: e.target.value };
          return prev;
        });
        break;
      }

      case 'away': {
        setMatchFactText((prev) => {
          prev = { ...prev, home: e.target.value };
          return prev;
        });
        break;
      }
      default:
        break;
    }
  };

  // time for matchFact
  const [matchFactTime, setMatchFactTime] = useState({ home: 0, away: 0 });
  const handleMatchFactTime = (e, name) => {
    switch (name) {
      case 'home': {
        setMatchFactTime((prev) => {
          prev = { ...prev, home: e.target.value };
          return prev;
        });
        break;
      }

      case 'away': {
        setMatchFactTime((prev) => {
          prev = { ...prev, home: e.target.value };
          return prev;
        });
        break;
      }
      default:
        break;
    }
  };

  // handlers
  const handleConfirmEdit = async () => {
    const { _id, home_team: homeTeam, away_team: awayTeam } = match;

    const homeGoalFactUpdateBody = {
      match: _id,
      team: homeTeam._id,
      player: goalScorer.home,
      assisted_by: goalAssister.home ?? null,
      goalScorer: goalScorer.home,
      event: 'goal',
      time: goalMinute.home,
    };

    const awayGoalFactUpdateBody = {
      match: _id,
      team: awayTeam._id,
      player: goalScorer.away,
      assisted_by: goalAssister.away ?? null,
      goalScorer: goalScorer.away,
      event: 'goal',
      time: goalMinute.away,
    };

    const homeRedCardBody = {
      match: _id,
      team: homeTeam._id,
      player: playerRedCard.home,
      card_type: 'red',
      time: timeRedCard.home,
    };

    const awayRedCardBody = {
      match: _id,
      team: awayTeam._id,
      player: playerRedCard.away,
      card_type: 'red',
      time: timeRedCard.away,
    };

    const homeYellowCardBody = {
      match: _id,
      team: homeTeam._id,
      player: playerYellowCard.home,
      card_type: 'yellow',
      time: timeYellowCard.home,
    };

    const awayYellowCardBody = {
      match: _id,
      team: awayTeam._id,
      player: playerYellowCard.away,
      card_type: 'yellow',
      time: timeYellowCard.away,
    };

    // substitutions
    const homeSubstitutionsBody = {
      match: _id,
      team: homeTeam._id,
      in: substitutions.home.in,
      out: substitutions.home.out,
      event: 'sub',
      time: timeSubstitution.home,
    };

    const awaySubstitutionsBody = {
      match: _id,
      team: awayTeam._id,
      in: substitutions.away.in,
      out: substitutions.away.out,
      event: 'sub',
      time: timeSubstitution.away,
    };

    const homeMatchFactBody = {
      match: _id,
      team: awayTeam._id,
      text: matchFactText.home,
      time: matchFactTime.home,
      event: 'fact',
    };

    const awayMatchFactBody = {
      match: _id,
      team: awayTeam._id,
      text: matchFactText.away,
      time: matchFactTime.home,
      event: 'fact',
    };

    const promises = [
      // goals
      {
        apiCall: () => updateMatchMoment(TOKEN, homeGoalFactUpdateBody),
        toNotProceed: Boolean(
          goalScorer.home === null ||
            goalMinute.home === null ||
            goalMinute.home === '' ||
            !parseInt(goalMinute.home) ||
            parseInt(goalMinute.home) === 0
        ),
      },
      {
        apiCall: () => updateMatchMoment(TOKEN, awayGoalFactUpdateBody),
        toNotProceed:
          Boolean(goalScorer.away === null || goalMinute.away === null || goalMinute.away === '') ||
          !parseInt(goalMinute.away) ||
          parseInt(goalMinute.away) === 0,
      },

      // red cards
      {
        apiCall: () => updateMatchMoment(TOKEN, homeRedCardBody),
        toNotProceed:
          Boolean(playerRedCard.home === null || timeRedCard.home === null || timeRedCard.home === '') ||
          !parseInt(timeRedCard.home) ||
          parseInt(timeRedCard.home) === 0,
      },
      {
        apiCall: () => updateMatchMoment(TOKEN, awayRedCardBody),
        toNotProceed:
          Boolean(playerRedCard.away === null || timeRedCard.away === null || timeRedCard.away === '') ||
          !parseInt(timeRedCard.away) ||
          parseInt(timeRedCard.away) === 0,
      },

      // yellow cards
      {
        apiCall: () => updateMatchMoment(TOKEN, homeYellowCardBody),
        toNotProceed:
          Boolean(playerYellowCard.home === null || timeYellowCard.home === null || timeYellowCard.home === '') ||
          !parseInt(timeYellowCard.home) ||
          parseInt(timeYellowCard.home) === 0,
      },
      {
        apiCall: () => updateMatchMoment(TOKEN, awayYellowCardBody),
        toNotProceed:
          Boolean(playerYellowCard.away === null || timeYellowCard.away === null || timeYellowCard.away === '') ||
          !parseInt(timeYellowCard.away) ||
          parseInt(timeYellowCard.away) === 0,
      },

      // substitutiojns
      {
        apiCall: () => updateMatchMoment(TOKEN, homeSubstitutionsBody),
        toNotProceed:
          Boolean(
            substitutions.home.in === null ||
              substitutions.home.out === null ||
              timeSubstitution.home === null ||
              timeSubstitution.home === ''
          ) ||
          !parseInt(timeSubstitution.home) ||
          parseInt(timeSubstitution.home) === 0,
      },
      {
        apiCall: () => updateMatchMoment(TOKEN, awaySubstitutionsBody),
        toNotProceed:
          Boolean(
            substitutions.away.in === null ||
              substitutions.away.out === null ||
              timeSubstitution.away === null ||
              timeSubstitution.away === ''
          ) ||
          !parseInt(timeSubstitution.away) ||
          parseInt(timeSubstitution.away) === 0,
      },
    ].map(({ toNotProceed, apiCall }) => (toNotProceed ? undefined : apiCall()));

    setLoading(true);

    try {
      const results = await Promise.allSettled(promises);
      const body = {
        home: {
          goals:
            results[0].value === undefined || results[0].status === 'rejected'
              ? homeStats.goals
              : parseInt(homeStats.goals) + 1,
          shots: shots.home ?? homeStats.goals,
          shots_on_target: shotsOnTarget.home ?? homeStats.shots_on_target,
          passes: passes.home ?? homeStats.passes,
          red_cards:
            results[2].value === undefined || results[2].status === 'rejected'
              ? homeStats.red_cards
              : parseInt(homeStats.red_cards) + 1,
          yellow_cards:
            results[4].value === undefined || results[4].status === 'rejected'
              ? homeStats.yellow_cards
              : parseInt(homeStats.yellow_cards) + 1,
        },
        away: {
          goals:
            results[1].value === undefined || results[1].status === 'rejected'
              ? awayStats.goals
              : parseInt(awayStats.goals) + 1,
          shots: shots.away ?? awayStats.goals,
          shots_on_target: shotsOnTarget.away ?? awayStats.shots_on_target,
          passes: passes.away ?? awayStats.passes,
          red_cards:
            results[3].value === undefined || results[3].status === 'rejected'
              ? awayStats.red_cards
              : parseInt(awayStats.red_cards) + 1,
          yellow_cards:
            results[5].value === undefined || results[5].status === 'rejected'
              ? awayStats.yellow_cards
              : parseInt(awayStats.yellow_cards) + 1,
        },
      };

      const statPromises = [
        updateMatchStat(TOKEN, body.home, homeStats._id),
        updateMatchStat(TOKEN, body.away, awayStats._id),
      ];

      try {
        const results2 = await Promise.allSettled(statPromises);
        toast.success('stats updated successfully');
      } catch (error) {
        console.log('error', error);
        toast.error('could not update stats');
      }

      const _body = {
        payload: { home: goalScorer.home, away: goalScorer.away },
        match: match,
      };

      if ((homeMatchFactBody.text && homeMatchFactBody.time) || (awayMatchFactBody.text && awayMatchFactBody.time)) {
        if (homeMatchFactBody.text && homeMatchFactBody.time) {
          const statPromises1 = [updateMatchFact(TOKEN, homeMatchFactBody)];
          try {
            const results3 = await Promise.allSettled(statPromises1);
            toast.success('stats updated successfully');
          } catch (error) {
            console.log('error', error);
            toast.error('could not update stats');
          }
        }
        if (awayMatchFactBody.text && awayMatchFactBody.time) {
          const statPromises1 = [updateMatchFact(TOKEN, awayMatchFactBody)];
          try {
            const results3 = await Promise.allSettled(statPromises1);
            toast.success('stats updated successfully');
          } catch (error) {
            console.log('error', error);
            toast.error('could not update stats');
          }
        }
      }

      if (Boolean(goalScorer.home || goalScorer.away)) {
        await updateMatchTable(TOKEN, _body);
      }

      setLoading(false);
      handleRefresh();
    } catch (error) {
      console.log('error updating match fact', error);
      toast.error('could not update stats');
      setLoading(false);
    }
  };

  return {
    goalScorer,
    handleGoalScorerChange,

    goalAssister,
    handleGoalAssisterChange,

    goalMinute,
    handleGoalMinuteChange,

    selectedGoalType,
    setSelectedGoalType,
    goalTypes,

    handleConfirmEdit,
    loading,

    shots,
    handleShotsChange,

    shotsOnTarget,
    handleShotsOnTargetChange,

    passes,
    handlePassesChange,

    playerRedCard,
    handlePlayerRedCardChange,

    timeRedCard,
    handleTimeRedCardChange,

    playerYellowCard,
    handlePlayerYellowCardChange,

    timeYellowCard,
    handleTimeYellowCardChange,

    substitutions,
    handleSubstitutionsChange,

    timeSubstitution,
    handleTimeSubstitutionChange,

    matchFactTime,
    handleMatchFactTime,

    matchFactText,
    handleMatchFactText,
  };
};

export default useEditStatsForm;
