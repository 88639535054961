import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASEURL_HEROKU } from 'apiUrls';

const listGroundsApi = createApi({
  reducerPath: 'LIST_GROUNDS',
  baseQuery: fetchBaseQuery({ baseUrl: BASEURL_HEROKU }),
  tagTypes: ['Grounds'],
  endpoints: (build) => {
    return {
      getAllGrounds: build.query({
        async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
          const grounds = await fetchWithBQ('stadiums');
          return grounds;
        },
      }),
    };
  },
});

export const { useGetAllGroundsQuery } = listGroundsApi;

export default listGroundsApi;
