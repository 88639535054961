const MatchCardUpcomingButton = ({ label = '', Icon = null, onClick = () => {} }) => {
  return (
    <div className="w-full p-2">
      <button
        onClick={onClick}
        className={` transition-all w-full px-4 py-3 rounded-lg bg-button hover:bg-buttonHovered disabled:bg-buttonHDisabled text-white text-sm flex items-center justify-center gap-2`}
      >
        <p>{label}</p>
        {Icon}
      </button>
    </div>
  );
};

export default MatchCardUpcomingButton;
