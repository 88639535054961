const nameInitialsTransformer = (name) => {
  const splittedName = name.split(' ');
  try {
    const firstName = splittedName[0];
    const secondName = splittedName[0];
    return `${firstName[0] ?? ''} ${secondName[1] ?? ''}`;
  } catch (error) {
    console.error(error);
    return ``;
  }
};

export default nameInitialsTransformer;
