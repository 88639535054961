import API_URLS from 'apiUrls';
import axios from 'axios';
import getApiConfig from 'helpers/getApiConfig';

export const getStadiums = () => {
  try {
    return axios.get(API_URLS.stadiums);
  } catch (error) {
    return Promise.resolve();
  }
};

export const addStadium = ({ token, body }) => {
  try {
    const config = getApiConfig(token);
    return axios.post(`${API_URLS.addStadium}`, body, config);
  } catch (error) {
    return Promise.resolve();
  }
};

export const deleteStadium = (token, stadiumId) => {
  try {
    const config = getApiConfig(token);
    return axios.delete(`${API_URLS.stadiums}/delete/${stadiumId}`, config);
  } catch (error) {
    return Promise.resolve();
  }
};
