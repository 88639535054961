import { useState } from 'react';
import toast from 'react-hot-toast';
import { MdSend } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SET_USER_INFO } from '../../../state/slices/user-auth-info';
// components
import CustomCircularLoader from 'components/CustomCircularLoader';
import CustomButton from '../../../components/CustomButton';
import CustomInput from '../../../components/CustomInput';
import CustomLabelButton from '../../../components/CustomLabelButton';
import { onLoginSubmit } from './loginFunctions';

// ----------------------------------------------------------------------

export default function LoginForm({ handleForgotPassword }) {
  const loginFormHook = useLoginForm();

  return (
    <>
      <form onSubmit={loginFormHook.handleFormSubmit} className="flex flex-col space-y-2 w-full">
        <CustomInput
          value={loginFormHook.emailOrUsername}
          setter={loginFormHook.setEmailOrUsername}
          placeholder="Email or Username"
        />
        <CustomInput
          type="password"
          value={loginFormHook.password}
          setter={loginFormHook.setPassword}
          placeholder="Password"
          suffix
        />
        <CustomLabelButton label="Forgot password?" onClick={handleForgotPassword} />
        <br />
        <CustomButton
          form
          disabled={loginFormHook.loading}
          label={loginFormHook.loading ? <CustomCircularLoader dark message="signing in" /> : 'Sign in'}
          Icon={!loginFormHook.loading && <MdSend />}
        />
      </form>
    </>
  );
}

// ----------------------------------------------------------------
const useLoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // loading state
  const [loading, setLoading] = useState(false);

  // -------------form field values ---------------
  const [emailOrUsername, setEmailOrUsername] = useState('');
  const [password, setPassword] = useState('');

  // form validations
  const isFormValid = () => {
    if (emailOrUsername === '') {
      toast.error('Email or Username field should not be empty');
      return false;
    }

    if (password === '') {
      toast.error('Password field should not be empty');
      return false;
    }

    return true;
  };

  // form submit handler
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // form values validations
    if (!isFormValid()) return;

    setLoading(true);
    await onLoginSubmit([navigate, dispatch, SET_USER_INFO, { emailOrUsername, password }]);
    setLoading(false);
  };

  return {
    emailOrUsername,
    setEmailOrUsername,
    password,
    setPassword,
    handleFormSubmit,
    loading,
  };
};
