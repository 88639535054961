import axios from 'axios'
import toast from "react-hot-toast";
import API_URLS from "../../../apiUrls";
import routeStrings from "../../../routeStrings";

const setUserInfo = (SET_USER_INFO, { name, token, email }, dispatch) => dispatch(SET_USER_INFO({ NAME: name, TOKEN: token, EMAIL: email }))

const navigateToHome = navigate => navigate(routeStrings.dashboard.app, { replace: true });

export const onLoginSubmit = async ([navigate, dispatch, SET_USER_INFO, { emailOrUsername, password }]) => {
    try {
        const response = await sendLoginInfo(emailOrUsername, password)
        const { data: { name, token, email } } = response
        setUserInfo(SET_USER_INFO, { name, token, email }, dispatch)
        navigateToHome(navigate)
        toast.success("Successfully signed in")
    } catch (error) {
        toast.error("Make sure you enter the correct credentials")
    }
}

export const sendLoginInfo = (emailOrUsername, password) => {
    const body = { username: emailOrUsername, password }
    return axios.post(API_URLS.login, body)
}