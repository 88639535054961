const Card = ({ red = false }) => {
  return (
    <div
      className={`h-[25px] w-[20px] border border-[gray] rounded-[4px] rotate-0 ${
        red ? 'bg-[#fc1b1b]' : 'bg-[#eded36]'
      }`}
    />
  );
};

export default Card;
